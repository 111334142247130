import { FC } from "react";
import { TutorialContentSectionProps } from "../types/TutorialSection.types";
import TutorialSubsection from "./TutorialSubsection"
import styles from "./tutorial.module.scss";

const TutorialSection: FC<TutorialContentSectionProps> = ({ name, index, subsections }) => {
    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader}>
                <span style={{ marginRight: "0.5rem" }}>{index}</span>
                <span>{name}</span>
            </div>
            <div className={styles.sectionsWrapper}>
                {subsections.map((x, i) => <TutorialSubsection key={i} id={x.id} name={x.name} index={x.index} content={x.content}></TutorialSubsection>)}
            </div>
        </div>);
};

export default TutorialSection;