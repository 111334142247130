import { Box } from "@mui/system";
import { FC } from "react";
import { CredibilityCircleIcon } from 'assets/images/icons';
import { Props } from './PaymentCredibility.types';

const colors: any = {
  A: {
    backgroundColor: '#00D32F',
  },
  B: {
    backgroundColor: '#77D300'
  },
  C: {
    backgroundColor: '#ACD100'
  },
  D: {
    backgroundColor: '#D8CD00'
  },
  E: {
    backgroundColor: '#FFC700'
  },
  F: {
    backgroundColor: '#FF9A00'
  },
  G: {
    backgroundColor: '#FF6800'
  },
  H: {
    backgroundColor: '#F91818'
  },
  X: {
    backgroundColor: '#8A8FA3'
  }
};

const PaymentCredibility: FC<Props> = ({ letter }) => {
  let value = '';

  switch (letter) {
    case 'A':
      value = 'Najwyższa ocena';
      break;
    case 'B':
      value = 'Bardzo wysoka ocena';
      break;
    case 'C':
      value = 'Wysoka ocena';
      break;
    case 'D':
      value = 'Średnia ocena';
      break;
    case 'E':
      value = 'Niska ocena';
      break;
    case 'F':
      value = 'Bardzo niska ocena';
      break;
    case 'G':
      value = 'Najniższa ocena';
      break;
    case 'H':
      value = 'Duże/istotne zadłużenie w bazach KRD';
      break;
    case 'X':
      value = 'Brak oceny ryzyka';
      break;
  };

  return (
    <Box sx={{ padding: '1rem 0 0 1.5rem', width: '100%', maxWidth: '44.3rem' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${colors[letter].backgroundColor}`,
            color: colors[letter].backgroundColor,
            marginTop: '1rem',
            padding: '0 0.8rem',
            borderRadius: '4px',
            fontWeight: '500',
            width: '100%',
            height: '4.1rem'
          }}>
          <CredibilityCircleIcon letter={letter} color={colors[letter].backgroundColor} remSize={2.6} />  
          <Box sx={{ margin: '0 0 0 .8rem' }}>{value}</Box>
        </Box>
    </Box>
  );
};

export default PaymentCredibility;