import axiosInstance from 'api/axiosInstance';
import { baseAPIUrl } from 'api/config';
import { ApiResponse } from 'api/models';
import { AxiosPromise } from 'axios';

export const getPeriodicReport = (token: string, id: string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/PeriodicReports/${id}/Pdf?token=${token}`,
    method: 'GET',
    responseType: 'arraybuffer'
  });
};

export const getXlsPeriodicReport = (id: string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/PeriodicReports/${id}/Xls`,
    method: 'GET',
    responseType: 'arraybuffer'
  });
};
