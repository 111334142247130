export const emailRegex = /^[A-Za-z0-9]+([-._][A-Za-z0-9]+)*@[A-Za-z0-9]+([-._][A-Za-z0-9]+)*\.[A-Za-z0-9]+([-._][A-Za-z0-9]+)*$/;
export const onlyDigitsRegex = /^[0-9]+$/;
export const postCodeRegex = /^\d{2}(?:[-\s]\d{3})$/;
export const polishTaxNumberRegex = /^(PL)?[0-9]{9,10}$|^(PL)?\d{3}-\d{3}-\d{2}-\d{2}$|^(PL)?\d{3}-\d{2}-\d{2}-\d{3}$/;
export const atTaxNumberRegex = /^AT[ ]?U\d{8}$|^at[ ]?u\d{8}$/;
export const beTaxNumberRegex = /^BE[ ]?(0?\d{9})$|^be[ ]?(0?\d{9})$/;
export const bgTaxNumberRegex = /^BG[ ]?\d{9,10}$|^bg[ ]?\d{9,10}$/;
export const cyTaxNumberRegex = /^CY[ ]?\d{8}[a-zA-z]{1}$|^cy[ ]?\d{8}[a-zA-z]{1}$/;
export const czTaxNumberRegex = /^CZ[ ]?\d{8,10}$|^cz[ ]?\d{8,10}$/;
export const dkTaxNumberRegex = /^DK[ ]?\d{8}$|^dk[ ]?\d{8}$/;
export const eeTaxNumberRegex = /^EE[ ]?\d{9}$|^ee[ ]?\d{9}$/;
export const fiTaxNumberRegex = /^FI[ ]?\d{8}$|^fi[ ]?\d{8}$/;
export const frTaxNumberRegex = /^FR[ ]?(([a-zA-Z]{1})|(\d{1}))(([a-zA-Z]{1})|(\d{1}))(\d{9})$|^fr[ ]?(([a-zA-Z]{1})|(\d{1}))(([a-zA-Z]{1})|(\d{1}))(\d{9})$/;
export const elTaxNumberRegex = /^EL[ ]?\d{9}$|^el[ ]?\d{9}$/;
export const esTaxNumberRegex = /^ES[ ]?((([a-zA-Z]{1})|(\d{1}))(\d{7})(([a-zA-Z]{1})|(\d{1})))$|^es[ ]?((([a-zA-Z]{1})|(\d{1}))(\d{7})(([a-zA-Z]{1})|(\d{1})))$/;
export const nlTaxNumberRegex = /^NL[ ]?\d{9}B{1}\d{2}$|^nl[ ]?\d{9}B{1}\d{2}$/;
export const ieTaxNumberRegex = /^IE[ ]?\d{1}(([a-zA-Z]{1})|(\d{1})|(\+{1})|(\*{1}))\d{5}[a-zA-Z]{1,2}$|^ie[ ]?\d{1}(([a-zA-Z]{1})|(\d{1})|(\+{1})|(\*{1}))\d{5}[a-zA-Z]{1,2}$/;
export const ltTaxNumberRegex = /^LT[ ]?((\d{9})|(\d{12}))$|^lt[ ]?((\d{9})|(\d{12}))$/;
export const luTaxNumberRegex = /^LU[ ]?\d{8}$|^lu[ ]?\d{8}$/;
export const lvTaxNumberRegex = /^LV[ ]?\d{11}$|^lv[ ]?\d{11}$/;
export const mtTaxNumberRegex = /^MT[ ]?\d{8}$|^mt[ ]?\d{8}$/;
export const deTaxNumberRegex = /^DE[ ]?\d{9}$|^de[ ]?\d{9}$/;
export const ptTaxNumberRegex = /^PT[ ]?\d{9}$|^pt[ ]?\d{9}$/;
export const roTaxNumberRegex = /^RO[ ]?\d{2,10}$|^ro[ ]?\d{2,10}$/;
export const skTaxNumberRegex = /^SK[ ]?\d{9,10}$|^sk[ ]?\d{9,10}$/;
export const siTaxNumberRegex = /^SI[ ]?\d{8}$|^si[ ]?\d{8}$/;
export const seTaxNumberRegex = /^SE[ ]?\d{12}$|^se[ ]?\d{12}$/;
export const huTaxNumberRegex = /^HU[ ]?\d{8}$|^hu[ ]?\d{8}$/;
export const gbTaxNumberRegex = /^GB[ ]?(((\d{2})|(G{1}D{1})|(H{1}A{1}))((\d{3})|(\d{7})|(\d{10})))$|^gb[ ]?(((\d{2})|(G{1}D{1})|(H{1}A{1}))((\d{3})|(\d{7})|(\d{10})))$/;
export const itTaxNumberRegex = /^IT[ ]?\d{11}$|^it[ ]?\d{11}$/;
export const chTaxNumberRegex = /^CH[ ]?\d{6}$|^ch[ ]?\d{6}$/;
export const hrTaxNumberRegex = /^HR[ ]?\d{11}$|^hr[ ]?\d{11}$/;

export const euTaxNumbersRegex = [
  atTaxNumberRegex, 
  beTaxNumberRegex, 
  bgTaxNumberRegex, 
  cyTaxNumberRegex, 
  czTaxNumberRegex, 
  dkTaxNumberRegex, 
  eeTaxNumberRegex,
  fiTaxNumberRegex,
  frTaxNumberRegex,
  elTaxNumberRegex,
  esTaxNumberRegex,
  nlTaxNumberRegex,
  ieTaxNumberRegex,
  ltTaxNumberRegex,
  luTaxNumberRegex,
  lvTaxNumberRegex,
  mtTaxNumberRegex,
  deTaxNumberRegex,
  ptTaxNumberRegex,
  roTaxNumberRegex,
  skTaxNumberRegex,
  siTaxNumberRegex,
  seTaxNumberRegex,
  huTaxNumberRegex,
  gbTaxNumberRegex,
  itTaxNumberRegex,
  chTaxNumberRegex,
  hrTaxNumberRegex
];

export const repeatedCharRegex = /^(?!(.)\1+$)/;
export const repeatedSpacesRegex = /^(?!(\s)\1+$)/;
export const onlyLettersRegex = /^[a-zA-Z]+$/;

export const euTaxRegexList = (value: string) => {
  switch(value) {
    case 'AT':
    case 'ATU':
    case 'at':
    case 'atu': 
      return atTaxNumberRegex;
    case 'BE':
    case 'be':
      return beTaxNumberRegex;
    case 'BG':
    case 'bg':
      return bgTaxNumberRegex;
    case 'CH':
    case 'ch':
      return chTaxNumberRegex;
    case 'CY':
    case 'cy':
      return cyTaxNumberRegex;
    case 'CZ':
    case 'cz':
      return czTaxNumberRegex;
    case 'DK':
    case 'dk':
      return dkTaxNumberRegex;
    case 'EE':
    case 'ee':
      return eeTaxNumberRegex;
    case 'FI':
    case 'fi':
      return fiTaxNumberRegex;
    case 'FR':
    case 'fr':
      return frTaxNumberRegex;
    case 'GB':
    case 'gb':
      return gbTaxNumberRegex;
    case 'HU':
    case 'hu':
      return huTaxNumberRegex;
    case 'HR':
    case 'hr':
      return hrTaxNumberRegex;
    case 'IT':
    case 'it':
      return itTaxNumberRegex;
    case 'EL':
    case 'el':
      return elTaxNumberRegex;
    case 'ES':
    case 'es':
      return esTaxNumberRegex;
    case 'NL':
    case 'nl':
      return nlTaxNumberRegex;
    case 'IE':
    case 'ie':
      return ieTaxNumberRegex;
    case 'LT':
    case 'lt':
      return ltTaxNumberRegex;
    case 'LU':
    case 'lu':
      return luTaxNumberRegex;
    case 'LV':
    case 'lv':
      return lvTaxNumberRegex;
    case 'MT':
    case 'mt':
      return mtTaxNumberRegex;
    case 'DE':
    case 'de':
      return deTaxNumberRegex;
    case 'PT':
    case 'pt':
      return ptTaxNumberRegex;
    case 'RO':
    case 'ro':
      return roTaxNumberRegex;
    case 'SK':
    case 'sk':
      return skTaxNumberRegex;
    case 'SI':
    case 'si':
      return siTaxNumberRegex;
    case 'SE':
    case 'se':
      return seTaxNumberRegex;
    default: 
      return atTaxNumberRegex;
  }
};

