import { FC } from 'react';

const ReportDownloadedTodayIcon: FC = () => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="35" height="35" rx="3.5" fill="#078A51" />
      <path
        d="M13.3246 28.1072H22.6748C24.4162 28.1072 25.8322 26.6911 25.8322 24.9505V12.0472C25.8322 10.3067 24.4162 8.89062 22.6748 8.89062H16.6274C15.6945 8.89062 14.8194 9.23567 14.1018 9.93254L11.2017 12.8327C10.4925 13.5612 10.168 14.5085 10.168 15.3501V24.9505C10.168 26.6911 11.584 28.1072 13.3246 28.1072ZM24.2309 12.0472V24.9505C24.2309 25.8079 23.5326 26.5058 22.6748 26.5058H13.3246C12.4668 26.5058 11.7693 25.8079 11.7693 24.9505V15.3501C11.7693 15.343 11.7709 15.3361 11.7711 15.329H14.7266C15.7994 15.329 16.672 14.4595 16.672 13.3906V10.492H22.6748C23.5326 10.492 24.2309 11.1899 24.2309 12.0472ZM12.5708 13.7276L15.0706 11.2285V13.3906C15.0706 13.5794 14.9197 13.7276 14.7266 13.7276H12.5708Z"
        fill="white"
      />
      <path
        d="M17.1981 21.7926L16.2418 20.8361C15.929 20.5233 15.4223 20.5233 15.1096 20.8361C14.7968 21.1485 14.7968 21.6556 15.1096 21.9683L17.4319 24.2911C17.7397 24.5991 18.248 24.609 18.5657 24.2911L20.8888 21.9683C21.2015 21.656 21.2015 21.1489 20.8888 20.8361C20.576 20.5233 20.0693 20.5233 19.7565 20.8361L18.7995 21.793V18.2616C18.7995 17.8194 18.4414 17.4609 17.9988 17.4609C17.5562 17.4609 17.1981 17.8194 17.1981 18.2616V21.7926Z"
        fill="white"
      />
      <rect x="0.5" y="1" width="35" height="35" rx="3.5" stroke="#29A745" />
    </svg>
  );
};

export default ReportDownloadedTodayIcon;
