import { FC } from 'react';
import { DownloadProps } from './Download.types';
import { STATUS } from '../dataExportList.types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import classes from './Download.module.scss';
import { downloadCsvFile } from 'lib/utils';
import { getExportFile } from 'api/services';

const Download: FC<DownloadProps> = ({ status, download, filename }) => {
  const downloadFile = () => downloadCsvFile(getExportFile(String(download)), filename, download);

  if (status !== STATUS.COMPLETED) {
    return null;
  }
  return (
    <button className={classes.button} onClick={downloadFile} type="button">
      <FileDownloadIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </button>
  );
};

export default Download;
