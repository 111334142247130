export enum SCORING_STATE {
  CREATED = 1,
  ENABLED = 10,
  ERROR_ENABLING = 500,
  ERROR_DISABLING = 501,
  ERROR_GETTING_DEBT = 502
}

export enum SCORING_ID {
  NO_DATA = 1,
  REMOVED_FROM_CEIDG = 2,
  REMOVED_FROM_KRS = 3,
  REGON_CEASED_OPERATIONS = 4,
  KRS_CEASED_OPERATIONS = 5,
  WRONG_NIP = 6,
  EXIST = 0,
  NO_NIP = 7
}

export const ScoringStatusCode = {
  1: 'Brak wystarczających danych',
  2: 'Status wykreślony w CEIDG',
  3: 'Status wykreślony w KRS',
  4: 'Status zakończona działalność w REGON',
  5: 'Status likwidacja w KRS',
  6: 'Błędny NIP',
  0: 'Jest wartość scoringu',
  7: 'Brak NIP-u w bazie'
};
