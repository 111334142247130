export type ChartRow = {
  label: string;
  count: number;
  nonEmptyCount: number;
  amount: number;
};

export interface CharRowWithColor extends ChartRow {
  color: string;
  colorLight: string;
}

export type ChartGroup = {
  chartRows: ChartRow[];
  totalAmount: number;
  totalCount: number;
  totalNonEmptyCount: number;
};

export type Chart = {
  counterpartiesDebtByRiskGroup: ChartGroup;
  counterpartiesGrossDebtByRiskGroup: ChartGroup;
  counterpartiesGrossSaleByRiskGroup: ChartGroup;
};

export enum CounterpartiesGrossType {
  SALE = 'counterpartiesGrossSaleByRiskGroup',
  DEBT = 'counterpartiesGrossDebtByRiskGroup'
}

export enum ChartTypes {
  SALE = 'counterpartiesGrossSaleByRiskGroup',
  DEBT = 'counterpartiesGrossDebtByRiskGroup',
  CUSTOMERS_LIST = 'customersList'
}

export type Colors = {
  color: string;
  colorLight: string;
};

export const colors: Colors[] = [
  {
    color: '#292C37',
    colorLight: '#d4d5d7'
  },
  {
    color: '#00D32F',
    colorLight: '#d9f7d9'
  },
  {
    color: '#77D300',
    colorLight: '#e5f7d6'
  },
  {
    color: '#ACD100',
    colorLight: '#eff6d6'
  },
  {
    color: '#D8CD00',
    colorLight: '#f7f5d6'
  },
  {
    color: '#FFC700',
    colorLight: '#fff4d7'
  },
  {
    color: '#FF9A00',
    colorLight: '#feead3'
  },
  {
    color: '#FF6800',
    colorLight: '#ffe2d3'
  },
  {
    color: '#F91818',
    colorLight: '#ffd5d4'
  },
  {
    color: '#8A8FA3',
    colorLight: '#e8e9ec'
  }
];

export enum ChartLabels {
  MonitoringDisabled = 'Monitorowanie wyłączone',
  NoCategory = 'Brak kategorii'
}
