import { SetStateAction } from 'react';
import { string as yupString, StringSchema } from 'yup';
import {
  onlyDigitsRegex,
  polishTaxNumberRegex,
  euTaxRegexList,
  repeatedCharRegex,
  repeatedSpacesRegex,
  onlyLettersRegex,
  euTaxNumbersRegex
} from 'regex';
import { ValidationError } from 'api/models';

export const clearPolishTaxNumber = (value?: string): string | undefined => {
  const removeDashes = value?.replaceAll('-', '');
  const removePrefix = removeDashes?.startsWith('PL') ? removeDashes.slice(2) : removeDashes;
  return value ? removePrefix : '';
};

export const phoneValidation = (): StringSchema =>
  yupString()
    .matches(onlyDigitsRegex, 'Numer telefonu powinien składać się jedynie z cyfr.')
    .test('len', 'Numer telefonu musi zawierać 9 cyfr.', (val) => {
      return val ? val.length === 9 : true;
    });

export const descriptionValidation = (): StringSchema => yupString().max(30, 'Maksymalna ilość znaków to 30');

export const polishTaxNumberValidation = (): StringSchema =>
  yupString()
    .required('Nieprawidłowa długość')
    .test('len', 'Nieprawidłowa długość', (val) => {
      const taxNumber = clearPolishTaxNumber(val);
      const taxNumberWithoutWhiteSpaces = taxNumber?.replace(/\s\s+/g, ' ');
      return taxNumberWithoutWhiteSpaces
        ? taxNumberWithoutWhiteSpaces.length === 0 || taxNumberWithoutWhiteSpaces.length === 10
        : true;
    })
    .test({
      name: 'extraLetters',
      message: 'Nieprawidłowe znaki',
      test(value?: string) {
        const taxNumber = clearPolishTaxNumber(value);
        return !taxNumber?.match(/^[0-9]+$/) ? false : true;
      }
    })
    .test({
      name: 'incorrectFormat',
      message: 'Nieprawidłowy format',
      test(value?: string) {
        const taxNumber = clearPolishTaxNumber(value);
        return !taxNumber?.match(polishTaxNumberRegex) ? false : true;
      }
    })
    .test({
      name: 'repeatedChar',
      message: 'Wszystkie znaki takie same',
      test(value?: string) {
        const taxNumber = value?.replace(/\D/g, '') ?? '';
        return !repeatedCharRegex.test(taxNumber) ? false : true;
      }
    })
    .matches(polishTaxNumberRegex, 'Nieprawidłowy format')
    .test({
      name: 'zeroAtPosition',
      message: 'Numer nie może zawierać "0" na pierwszej oraz trzeciej pozycji',
      test(value?: string) {
        const taxNumber = clearPolishTaxNumber(value);
        if (taxNumber?.length === 10 && (taxNumber?.charAt(0) === '0' || taxNumber?.charAt(2) === '0')) {
          return false;
        } else {
          return true;
        }
      }
    })
    .test({
      name: 'checksum',
      message: 'Nieprawidłowa suma kontrolna',
      test(value?: string) {
        const taxNumber = clearPolishTaxNumber(value);
        const digits = taxNumber ? taxNumber?.split('') : '';
        if (digits?.length === 10) {
          const result =
            (6 * parseInt(digits[0]) +
              5 * parseInt(digits[1]) +
              7 * parseInt(digits[2]) +
              2 * parseInt(digits[3]) +
              3 * parseInt(digits[4]) +
              4 * parseInt(digits[5]) +
              5 * parseInt(digits[6]) +
              6 * parseInt(digits[7]) +
              7 * parseInt(digits[8])) %
            11;
          return result === parseInt(digits[9]);
        } else {
          return true;
        }
      }
    });

export const euTaxNumberValidation = (value: string): StringSchema =>
  yupString()
    .required('Nieprawidłowa długość')
    .test({
      name: 'repeatedSpacesChar',
      message: 'Nieprawidłowa długość',
      test(value?: string) {
        return !repeatedSpacesRegex.test(value ?? '') ? false : true;
      }
    })
    .test({
      name: 'wrongChars',
      message: 'Nieprawidłowy format',
      test(value?: string) {
        return onlyLettersRegex.test(value ?? '') ? false : true;
      }
    })
    .test({
      name: 'wrongChars',
      message: 'Nieprawidłowe znaki',
      test(value?: string) {
        return euTaxNumbersRegex.some((val) => (val.test(value ?? '') ? false : true));
      }
    })
    .matches(euTaxRegexList(value), 'Nieprawidłowy format');

export const handleChange =
  (
    fieldName: string,
    onChangeHandler: (event: any) => void,
    errorsSetter: (value: SetStateAction<ValidationError[]>) => void
  ) =>
  (event: any) => {
    onChangeHandler(event);
    errorsSetter((prevState) => prevState.filter((err) => err.PropertyName !== fieldName));
  };

export const getBackendValidationError = (fieldName: string, validationErrors: ValidationError[]) => {
  const error = validationErrors.find((err) => err.PropertyName === fieldName);

  return error?.ErrorMessage;
};
