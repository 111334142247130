import { useState, useCallback } from 'react';
import { checkMonitoringEnableOrDisable } from 'api/services';
import moment from 'moment';

const useCheckMonitoring = () => {
  const [isOpen, setIsOpen] = useState(false);

  const canEnable = useCallback(async () => {
    const date = encodeURIComponent(moment().startOf('day').format());
    const response = await checkMonitoringEnableOrDisable(date);
    return {
      canEnableMonitoring: response?.data?.Result?.isDateAvailable,
      earliestDate: response?.data?.Result?.earliestDate
    };
  }, []);

  const canDisable = useCallback(async () => {
    const date = encodeURIComponent(moment().startOf('day').format());
    const response = await checkMonitoringEnableOrDisable(date);
    return {
      canDisableMonitoring: response?.data?.Result?.isDateAvailable,
      earliestDate: response?.data?.Result?.earliestDate
    };
  }, []);

  const cancelAction = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openModal = useCallback(() => setIsOpen(true), []);

  return {
    canEnable,
    canDisable,
    cancelAction,
    isOpen,
    setIsOpen,
    openModal
  };
};

export default useCheckMonitoring;
