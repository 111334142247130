import { object, string } from 'yup';
import { emailRegex } from 'regex';
import { phoneValidation } from 'lib/utils';
import { UserFormTypes } from '../types';

export const userSchema = object().shape({
  [UserFormTypes.FIELDS.FIRST_NAME]: string()
    .max(32, 'Maksymalna ilość znaków to 32.')
    .trim()
    .required('To pole jest obowiązkowe.'),
  [UserFormTypes.FIELDS.LAST_NAME]: string()
    .max(32, 'Maksymalna ilość znaków to 32.')
    .trim()
    .required('To pole jest obowiązkowe.'),
  [UserFormTypes.FIELDS.EMAIL]: string()
    .required('To pole jest obowiązkowe.')
    .max(128, 'Maksymalna ilość znaków to 128.')
    .matches(emailRegex, 'Podany adres e-mail jest niepoprawny.'),
  [UserFormTypes.FIELDS.PHONE]: phoneValidation().required('To pole jest obowiązkowe.'),
  [UserFormTypes.FIELDS.COMPANY_ID]: string().test('req', 'To pole jest obowiązkowe.', (val) =>
    val ? val.length > 0 : true
  )
});
