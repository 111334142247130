import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';

const ActivityDetector: FC = ({ children }) => {
  const [canRenderChildren, setCanRenderChildren] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(0);
  const [prevUrl, setPrevUrl] = useState<string>(sessionStorage.getItem('lastPage') || '');
  const auth = useAuth();
  const location = useLocation();
  const bc = new BroadcastChannel('refreshPage');

  const unloadHandler = useCallback(() => {
    sessionStorage.setItem('lastPage', prevUrl); // save last url in session storage before exiting the app
  }, [prevUrl]);

  useEffect(() => {
    auth.userManager.startSilentRenew();

    bc.onmessage = (event) => {
      if (event.data === 'refreshPage') {
        setPageRefresh((prev) => prev + 1);
      }
    };

    setCanRenderChildren(true);
  }, []);

  useEffect(() => {
    const silentRenewTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
      auth.userManager.stopSilentRenew();
    }, 1000 * 60 * 60 - 60000);

    return () => {
      clearInterval(silentRenewTimer);
    };
  }, [pageRefresh]);

  useEffect(() => {
    const url = window.location.href;

    if (!url.includes('sessionexpired') && !url.includes('?code=')) {
      setPrevUrl(url); // set previous url only if it's not sessionexpired or redirected from Identity Server
    }

    if (url.includes('sessionexpired')) {
      setCanRenderChildren(true);
    }

    bc.postMessage('refreshPage');
  }, [location]);

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler);
  }, [unloadHandler]);

  return canRenderChildren ? <>{children}</> : null;
};

export default ActivityDetector;
