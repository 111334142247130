import { BarChart, DonutChart, Tooltip } from 'components';
import styles from './styles.module.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FC, useEffect, useState } from 'react';
import { useSendRequest } from 'lib/hooks';
import { getChartData } from 'api/services';
import { Chart, ChartTypes } from './Charts.types';
import { Box, CircularProgress } from '@mui/material';
import { numberWithSpace } from 'lib/utils';

export type Props = {
  clearFilters: (onlyWithDebt: boolean) => void;
  setCategory: (label: string) => void;
  company: string;
  showFilterButton: boolean;
};

const tooltipSaleDebtText = 'Dane są aktualne na dzień ostatniego importu ​danych z pliku CSV.';

const Charts: FC<Props> = ({ clearFilters, setCategory, company, showFilterButton }) => {
  const [chartData, setChartData] = useState<Chart>();
  const [chartDataCustomersList, setChartDataCustomersList] = useState<Chart>();
  const [type, setType] = useState<ChartTypes>(ChartTypes.CUSTOMERS_LIST);
  const { result: resultRequest, isLoading, validationErrors, sendData } = useSendRequest();
  const [saleAmount, setSaleAmount] = useState<number | undefined>();
  const [debtAmout, setDebtAmout] = useState<number | undefined>();

  useEffect(() => {
    sendData(() => getChartData(company));
  }, [company]);

  useEffect(() => {
    if (resultRequest) {
      setChartData(resultRequest as Chart);

      const resultRequestCopy = JSON.stringify(resultRequest);
      const result = JSON.parse(resultRequestCopy);

      const customersList = (result as Chart).counterpartiesGrossSaleByRiskGroup?.chartRows.map((item) => {
        item.amount = item.count;
        return item;
      });

      const newCustomersList = {
        ...(result as Chart),
        counterpartiesGrossSaleByRiskGroup: {
          ...result.counterpartiesGrossSaleByRiskGroup,
          chartRows: customersList
        }
      };

      setChartDataCustomersList(newCustomersList as Chart);
    }
  }, [resultRequest]);

  useEffect(() => {
    if (chartData) {
      setSaleAmount(chartData?.counterpartiesGrossSaleByRiskGroup?.totalAmount);
      setDebtAmout(chartData?.counterpartiesGrossDebtByRiskGroup?.totalAmount);
    }
  }, [chartData]);

  return (
    <div className={styles.charts}>
      {!isLoading ? (
        <>
          <div className={styles.header}>
            <div className={styles.headerLeft}>Portfel klientów według kategorii wiarygodności płatniczej</div>
            <div className={styles.headerRight}>
              {type === ChartTypes.CUSTOMERS_LIST
                ? ''
                : type === ChartTypes.SALE
                ? `Wartość sprzedaży: ${saleAmount ? numberWithSpace(saleAmount.toFixed(2)) : 0} PLN`
                : `Wartość należności: ${debtAmout ? numberWithSpace(debtAmout.toFixed(2)) : 0} PLN`}
              {type !== ChartTypes.CUSTOMERS_LIST && <Tooltip text={tooltipSaleDebtText} />}
            </div>
            <div className={styles.switchButtons}>
              <div
                className={`${styles.switchButton} ${styles.switchButtonLeft} ${
                  type === ChartTypes.CUSTOMERS_LIST && styles.switchButtonLeftActive
                }`}
                onClick={() => setType(ChartTypes.CUSTOMERS_LIST)}>
                Liczba klientów
              </div>
              <div
                className={`${styles.switchButton} ${styles.switchButtonCenter} ${
                  type === ChartTypes.SALE && styles.switchButtonCenterActive
                }`}
                onClick={() => setType(ChartTypes.SALE)}>
                Sprzedaż
              </div>
              <div
                className={`${styles.switchButton} ${styles.switchButtonRight} ${
                  type === ChartTypes.DEBT && styles.switchButtonRightActive
                }`}
                onClick={() => setType(ChartTypes.DEBT)}>
                Należności
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.contentLeft}>
              <DonutChart props={chartData?.counterpartiesDebtByRiskGroup} />
              <div className={styles.krdData}>
                <div className={styles.krdDataTop}>
                  <div className={styles.krdDataTopLeft}>
                    Klienci monitorowani <span>wpisani do KRD</span>
                  </div>
                  {showFilterButton && (
                    <div className={styles.krdDataTopRight} onClick={() => clearFilters(true)}>
                      <div className={styles.krdDataSeeMore}>
                        <ArrowForwardIosIcon fontSize="small" />
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.krdDataBottom}>
                  <div className={styles.data}>
                    <div className={styles.dataTop}>Klienci</div>
                    <div className={styles.dataBottom}>
                      {chartData?.counterpartiesDebtByRiskGroup?.totalNonEmptyCount}
                    </div>
                  </div>
                  <div className={styles.data}>
                    <div className={styles.dataTop}>Zadłużenie w KRD</div>
                    <div className={styles.dataBottom}>
                      {numberWithSpace(chartData?.counterpartiesDebtByRiskGroup?.totalAmount?.toFixed(2))} PLN
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.contentRight}>
              {(chartData?.counterpartiesGrossSaleByRiskGroup?.chartRows &&
                chartData?.counterpartiesGrossSaleByRiskGroup?.chartRows.length > 0) ||
              (chartData?.counterpartiesGrossDebtByRiskGroup?.chartRows &&
                chartData?.counterpartiesGrossDebtByRiskGroup?.chartRows.length > 0) ? (
                <>
                  {type === ChartTypes.CUSTOMERS_LIST &&
                    chartDataCustomersList?.counterpartiesGrossSaleByRiskGroup?.chartRows && (
                      <BarChart
                        type={ChartTypes.CUSTOMERS_LIST}
                        setCategory={setCategory}
                        props={chartDataCustomersList?.counterpartiesGrossSaleByRiskGroup?.chartRows}
                      />
                    )}
                  {type === ChartTypes.SALE && chartData?.counterpartiesGrossSaleByRiskGroup?.chartRows && (
                    <BarChart
                      type={ChartTypes.SALE}
                      setCategory={setCategory}
                      props={chartData?.counterpartiesGrossSaleByRiskGroup?.chartRows}
                    />
                  )}
                  {type === ChartTypes.DEBT && chartData?.counterpartiesGrossDebtByRiskGroup?.chartRows && (
                    <BarChart
                      type={ChartTypes.DEBT}
                      setCategory={setCategory}
                      props={chartData?.counterpartiesGrossDebtByRiskGroup?.chartRows}
                    />
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                  }}>
                  Brak danych
                </Box>
              )}
            </div>
          </div>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15rem 0' }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Charts;
