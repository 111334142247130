import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { HistoryDataResultDto } from '../models';
import { AxiosPromise } from 'axios';
import { ApiResponse } from '../models';

export const getHistoryData = (queryString?: string): AxiosPromise<ApiResponse<HistoryDataResultDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ChangeHistory`.concat(queryString ? `?${queryString}` : '')
  });
};

export const getHistorySummaryData = (queryString?: string): AxiosPromise<ApiResponse<HistoryDataResultDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ChangeHistory/summary`.concat(queryString ? `?${queryString}` : '')
  });
};
