import { useState, MouseEvent, useEffect } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import tableClasses from '../../../styles/table.module.scss';
import { PATH } from 'router/routes';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Breadcrumbs, Header, Pagination, Paper, Scrollbar, StyledButton } from 'components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FIELDS } from './csvErrors.types';
import { createRows } from './utils';
import { TableHead } from './components';
import { CellType, HeadCell, mapSortDirection, Order } from 'types';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { useSendRequest, useRowsPerPage, useGoToFirstPage } from 'lib/hooks';
import { getImportErrors } from 'api/services';
import { CsvErrorDto, CsvErrorsDto } from 'api/models';
import { SelectChangeEvent } from '@mui/material';
import { IMPORT_STATUS } from '../csvList/csvList.types';

const CsvErrors = () => {
  const rowsPerPage = useRowsPerPage();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<FIELDS>(FIELDS.ROW_NUMBER);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [csvErrors, setCsvErrors] = useState<CsvErrorDto[]>();
  const [isFileError, setIsFileError] = useState<boolean>(true);
  const [structureErrorMessage, setStructureErrorMessage] = useState<string>('');
  const [csvResponse, setCsvResponse] = useState<CsvErrorsDto>();
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get('page');
  const { id } = useParams();

  const { goToFirstPage } = useGoToFirstPage();

  const breadcrumbs = [{ to: PATH.CSV_LIST, label: 'Import CSV' }, { label: 'Podgląd zaimportowanych danych' }];

  const fieldsWithLongNames = [FIELDS.ERROR_MESSAGE];

  const { result: csvResults, isLoading: isLoadingCsvErrors, sendData } = useSendRequest();

  const createBaseQueryString = () => {
    return `PageNumber=${pageNumber}&PageSize=${pageSize}&OrderByColumn=Line&OrderByAscending=${mapSortDirection(
      order
    )}`;
  };

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const prepareQuery = () => {
    let queryString = createBaseQueryString();
    setQuery(queryString);
  };

  const getErrorList = async (query: string) => {
    sendData(() => getImportErrors(Number(id), query));
  };

  useEffect(() => {
    prepareQuery();
  }, [pageNumber, pageSize]);

  useEffect(() => {
    getErrorList(query);
  }, [query]);

  useEffect(() => {
    if ((csvResults as CsvErrorsDto)?.results) {
      setCsvErrors((csvResults as CsvErrorsDto).results);
    }
    if (csvResults as CsvErrorsDto) {
      setCsvResponse(csvResults as CsvErrorsDto);
      setIsFileError((csvResults as CsvErrorsDto).status === IMPORT_STATUS.FILE_ERROR ? true : false);
      setStructureErrorMessage((csvResults as CsvErrorsDto).structureErrorMessage);
    }
  }, [csvResults]);

  const createCells = (cell: CellType<FIELDS>) => {
    if (fieldsWithLongNames.includes(cell.name)) {
      return (
        <Tooltip title={cell.value}>
          <Typography
            variant="body2"
            sx={{ maxWidth: '40.0rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {cell.value}
          </Typography>
        </Tooltip>
      );
    }
    return (
      <Typography variant="body2" sx={{ maxWidth: '15.5rem' }}>
        {cell.value}
      </Typography>
    );
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: FIELDS) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(`${PATH.CSV_ERRORS}/${id}`);
  };

  const rows = createRows(csvErrors);

  const headCells: HeadCell<FIELDS>[] = [
    {
      id: FIELDS.ROW_NUMBER,
      numeric: false,
      disablePadding: true,
      label: 'Numer linii',
      sortable: false
    },
    {
      id: FIELDS.COLUMN_NAME,
      numeric: false,
      disablePadding: true,
      label: 'Nazwa pola',
      sortable: false
    },
    {
      id: FIELDS.ERROR_MESSAGE,
      numeric: false,
      disablePadding: true,
      label: 'Nazwa błędu',
      sortable: false
    }
  ];

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {!isFileError && (csvResults as CsvErrorsDto)?.status !== IMPORT_STATUS.COMPLETED_WITH_ERRORS ? (
            <Box
              sx={{
                color: '#F51B42',
                display: 'flex',
                alignItems: 'center',
                padding: '1rem',
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
              }}>
              <ErrorOutlineIcon sx={{ margin: '0 0.5rem 0 0' }} />
              <Box>
                Import został przerwany i dane nie zostały wgrane do aplikacji. Niektóre pola zawierają błędy. Popraw je
                w pliku CSV i ponów import.
              </Box>
            </Box>
          ) : null}
          <Header title="Podgląd błędów w pliku CSV">
            <StyledButton
              //@ts-ignore //@todo
              component={Link}
              to={`${PATH.CSV_LIST}`}
              variant="outlined"
              sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0', margin: '0 1rem 0 0' }}
              startIcon={<ArrowBackIcon />}>
              Wróć do listy importów
            </StyledButton>
          </Header>
          {isLoadingCsvErrors ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15rem 0' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead
                      headCells={headCells}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    {isFileError ? (
                      <TableBody>
                        <tr>
                          <td colSpan={3}>
                            <Box
                              sx={{
                                width: '100%',
                                background: 'rgba(243, 248, 251, 0.5)',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '150px 20px'
                              }}>
                              <Box sx={{ color: '#F51B42', fontSize: '1.2rem', width: '100%', textAlign: 'center' }}>
                                Plik jest niepoprawny
                              </Box>
                              <Box
                                sx={{
                                  color: 'rgba(0, 0, 0, 0.6)',
                                  padding: '1rem 0 .5rem 0',
                                  textAlign: 'center',
                                  width: '25rem',
                                  lineHeight: '1.7rem'
                                }}>
                                {structureErrorMessage ? `${structureErrorMessage}.` : ''} Import został przerwany i
                                dane nie zostały wgrane do aplikacji. Popraw plik CSV i ponów import.{' '}
                              </Box>
                              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <StyledButton
                                  //@ts-ignore //@todo
                                  component={Link}
                                  to={`${PATH.CSV_LIST}`}
                                  variant="outlined"
                                  sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0', margin: '0 1rem 0 0' }}>
                                  Wróć do listy importów
                                </StyledButton>
                              </Box>
                            </Box>
                          </td>
                        </tr>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {rows &&
                          rows.map((row) => {
                            const cells: CellType<FIELDS>[] = [
                              { value: row[FIELDS.ROW_NUMBER]?.toString(), name: FIELDS.ROW_NUMBER },
                              { value: row[FIELDS.COLUMN_NAME], name: FIELDS.COLUMN_NAME },
                              { value: row[FIELDS.ERROR_MESSAGE], name: FIELDS.ERROR_MESSAGE }
                            ];

                            return (
                              <TableRow hover tabIndex={-1} key={row.id}>
                                {cells.map((cell) => {
                                  return (
                                    <TableCell
                                      key={cell.name}
                                      sx={{
                                        padding: '0.5rem 1.5rem'
                                      }}>
                                      {createCells(cell)}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    )}
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                extraData={`Liczba rekordów: ${
                  (csvResults as CsvErrorsDto)?.totalSize ? (csvResults as CsvErrorsDto).totalSize : 0
                }`}
                total={csvResponse?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default CsvErrors;
