import { FC } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { Props } from './Indebtedness.types';
import { ROLE } from 'config/oidc';
import { useAuth } from 'oidc-react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Icon, SvgIconTypeMap, Tooltip } from '@mui/material';
import { MONITORING_STATE } from 'api/models';
import { numberWithSpace } from 'lib/utils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import moment from 'moment';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const Root = styled('div')`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  height: 30px;
  background: #078a51;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
`;

const state500message = 'Nie udało się uruchomić monitorowania. Skontaktuj się z Administratorem KG.';
const state501message = 'Nie udało się wyłączyć monitorowania. Skontaktuj się z Administratorem KG.';
const state502message = 'Pobranie informacji nie powiodło się. Skontaktuj się z Administratorem KG.';
const state503message = 'Nie udało się pobrać informacji o saldzie zadłużenia. Skontaktuj się z Administratorem KG.';
const messageAddedInPro = 'Ponawianie próby pobrania informacji.';
const disablingMessage =
  'Trwa wyłączanie monitorowania. Monitorowanie zostanie zatrzymane z końcem doby, w której zlecono jego wyłączenie.';
const tooltipEnablingMessage =
  'Trwa pobieranie informacji. Informacja pojawi się do 2 godzin od zlecenia włączenia monitorowania.';

const tooltipEnablingRetryMessage = 'Ponawianie próby pobrania informacji.';

const Indebtedness: FC<Props> = ({ data, role, enableMonitoring }) => {
  const auth = useAuth();
  const hasMonitoringPermission = auth?.userData?.profile?.HasClientMonitoringPermission;
  let result;

  const renderTooltipFieldWithIcon = (
    title: string,
    tooltipText: string,
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null,
    iconColor: string
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexShrink: '0',
          alignItems: 'center'
        }}>
        <Tooltip title={tooltipText}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {Icon !== null && <Icon fontSize="medium" sx={{ color: iconColor, margin: '0 .5rem 0 0' }} />}
            <Box sx={{ maxWidth: '7.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {title}
            </Box>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  switch (data.monitoringState) {
    case null:
      result =
        [ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR].includes(role) && hasMonitoringPermission === 'True' ? (
          <ButtonUnstyled component={Root} variant="contained" onClick={enableMonitoring}>
            Włącz monitorowanie
          </ButtonUnstyled>
        ) : null;
      break;
    case MONITORING_STATE.CREATED:
      result =
        data?.isMonitoringRetry === true
          ? renderTooltipFieldWithIcon(
              tooltipEnablingRetryMessage,
              tooltipEnablingRetryMessage,
              HourglassEmptyIcon,
              '#ec9514'
            )
          : renderTooltipFieldWithIcon(tooltipEnablingMessage, tooltipEnablingMessage, HourglassEmptyIcon, '#ec9514');
      break;
    case MONITORING_STATE.ADDED_IN_MPRO:
      result = data?.lastDebtSynchronizationDate
        ? renderTooltipFieldWithIcon(messageAddedInPro, messageAddedInPro, HourglassEmptyIcon, '#ec9514')
        : renderTooltipFieldWithIcon(tooltipEnablingMessage, tooltipEnablingMessage, HourglassEmptyIcon, '#ec9514');
      break;
    case MONITORING_STATE.ENABLED:
      const dateIsSameOrAfter = moment(data?.monitoringEndDate).isSameOrAfter(moment());
      const amount =
        Number(data?.debtAmountPln) > 0 ? `${numberWithSpace(data?.debtAmountPln?.toFixed(2))}` : 'Brak zadłużenia';

      result =
        Number(data?.debtAmountPln) >= 0
          ? data?.monitoringEndDate && dateIsSameOrAfter
            ? renderTooltipFieldWithIcon(
                Number(data?.debtAmountPln) > 0
                  ? `${numberWithSpace(data?.debtAmountPln?.toFixed(2))}`
                  : 'Brak zadłużenia',
                `Wyłączenie monitorowania nastąpi ${moment(data.monitoringEndDate).format('DD-MM-YYYY')}.`,
                data?.monitoringEndDate && dateIsSameOrAfter ? WarningAmberIcon : null,
                '#EA9617'
              )
            : amount
          : null;
      break;
    case MONITORING_STATE.DISABLING:
    case MONITORING_STATE.DISABLED:
      result = renderTooltipFieldWithIcon(disablingMessage, disablingMessage, HourglassEmptyIcon, '#ec9514');
      break;
    case MONITORING_STATE.ERROR_ENABLING:
      result =
        ([ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR, ROLE.ADMIN_KG].includes(role) &&
          hasMonitoringPermission === 'True') ||
        [ROLE.ADMIN_KG].includes(role)
          ? renderTooltipFieldWithIcon(state500message, state500message, WarningAmberIcon, '#ec9514')
          : null;
      break;
    case MONITORING_STATE.ERROR_DISABLING:
      result =
        ([ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR, ROLE.ADMIN_KG].includes(role) &&
          hasMonitoringPermission === 'True') ||
        [ROLE.ADMIN_KG].includes(role)
          ? renderTooltipFieldWithIcon(state501message, state501message, WarningAmberIcon, '#ec9514')
          : null;
      break;
    case MONITORING_STATE.ERROR_GETTING_DEBT:
      result =
        ([ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR, ROLE.ADMIN_KG].includes(role) &&
          hasMonitoringPermission === 'True') ||
        [ROLE.ADMIN_KG].includes(role)
          ? renderTooltipFieldWithIcon(state502message, state502message, WarningAmberIcon, '#ec9514')
          : null;
      break;
    case MONITORING_STATE.ERROR_GETTING_DEBT_FINAL:
      result =
        ([ROLE.CLIENT_USER, ROLE.CLIENT_ADMIN, ROLE.CURATOR].includes(role) && hasMonitoringPermission === 'True') ||
        [ROLE.ADMIN_KG].includes(role) ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={state503message}>
              <Box sx={{ maxWidth: '7.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {state503message}
              </Box>
            </Tooltip>
          </Box>
        ) : null;
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '2.5rem',
        whiteSpace: 'nowrap'
      }}>
      {result}
    </Box>
  );
};

export default Indebtedness;
