import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { HubConnection } from '@microsoft/signalr';

interface InitialState {
  connection: HubConnection | null;
}

const initialState: InitialState = {
  connection: null
};

const selectState = ({ connection }: RootState) => connection;

export const connectionSelector = createSelector(selectState, (state) => state.connection);

export const connectionSlice = createSlice({
  name: 'connectionDate',
  initialState,
  reducers: {
    setConnection: (state, action) => {
      state.connection = action.payload;
    }
  }
});

export const { setConnection } = connectionSlice.actions;

export default connectionSlice.reducer;
