import { useState, MouseEvent, useEffect } from 'react';
import tableClasses from '../../../styles/table.module.scss';
import { CircularProgress, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import { Breadcrumbs, Dialog, Header, Pagination, Paper, Scrollbar, StyledButton, UploadButton } from 'components';
import { ROLE } from 'config/oidc';
import { useAuth } from 'oidc-react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FIELDS } from './csvList.types';
import { CsvAction, CsvStatus, TableHead } from './components';
import { createRows } from './utils';
import moment from 'moment';
import { CellType, HeadCell, mapSortDirection, Order } from 'types';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { getImports, uploadCsv } from 'api/services';
import { CsvDto, CsvResultsDto } from 'api/models';
import { useSendRequest, useGoToFirstPage, useRowsPerPage } from 'lib/hooks';
import { PATH } from 'router/routes';
import { Link } from './CsvList.styled';
import { SelectChangeEvent } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';
import { PushNotification, NotificationType } from '../../../App';

const tooltipReportText = 'Import służy do sprawnego dodawania większej liczby klientów.';
const tooltipReportLink = { linkName: 'Samouczek, punkt 7.3.', linkUrl: '/tutorial#punkt-7-3' };

const CsvList = () => {
  const rowsPerPage = useRowsPerPage();
  const [csvList, setCsvList] = useState<CsvDto[]>();
  const [csvResponse, setCsvResponse] = useState<CsvResultsDto>();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<FIELDS>(FIELDS.UPLOAD_TIME);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [csvFile, setCsvFile] = useState<Blob>();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [searchParams] = useSearchParams();

  const getPageQueryParam = () => {
    return Number(searchParams.get('page') ?? 1);
  };

  const [pageNumber, setPageNumber] = useState<number>(getPageQueryParam());

  const { goToFirstPage } = useGoToFirstPage();

  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const hasCsvPermission = auth?.userData?.profile?.HasCsvPermission;
  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  const breadcrumbs = [{ label: 'Import danych - CSV' }];

  const fieldsWithLongNames = [FIELDS.FILENAME];

  const formData = new FormData();

  const { isLoading: isLoadingCsv, sendData: sendCsv } = useSendRequest();
  const { result: csvResults, isLoading: isLoadingCsvList, sendData } = useSendRequest();

  const connection = useSelector(connectionSelector);

  useEffect(() => {
    connection?.on('SendNotification', (data: PushNotification) => {
      if (data?.type === NotificationType.UserImportResultUpdated && window.location.pathname === PATH.CSV_LIST) {
        reloadResults(query);
      }
    });
  }, [connection]);

  useEffect(() => {
    setPageNumber(getPageQueryParam());
  }, [searchParams]);

  const createBaseQueryString = () => {
    return `CompanyId=${companyId}&PageNumber=${pageNumber}&PageSize=${pageSize}&OrderByColumn=uploadTime&OrderByAscending=${mapSortDirection(
      order
    )}`;
  };

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const prepareQuery = () => {
    let queryString = createBaseQueryString();
    setQuery(queryString);
  };

  useEffect(() => {
    prepareQuery();
  }, [pageNumber, pageSize]);

  const reloadResults = (query: string) => {
    getCsvList(query);
    setShowInfoModal(false);
  };

  const uploadFile = async (companyId: string, csvFile: any) => {
    sendCsv(
      () => uploadCsv(companyId, csvFile),
      () => fileUploaded()
    );
  };

  const fileUploaded = () => {
    setCsvFile(undefined);
    setShowInfoModal(true);
  };

  const getCsvList = async (query: string) => {
    sendData(() => getImports(query));
  };

  useEffect(() => {
    if (csvFile) {
      formData.append('file', csvFile);
      uploadFile(companyId, formData);
    }
  }, [csvFile]);

  useEffect(() => {
    if (pageNumber) {
      getCsvList(query);
    }
  }, [query]);

  useEffect(() => {
    if ((csvResults as CsvResultsDto)?.results) {
      setCsvResponse(csvResults as CsvResultsDto);
      setCsvList((csvResults as CsvResultsDto).results);
    }
  }, [csvResults]);

  const createCells = (cell: CellType<FIELDS>) => {
    if (fieldsWithLongNames.includes(cell.name)) {
      return (
        <Tooltip title={cell.value}>
          <Typography
            variant="body2"
            sx={{ maxWidth: '15.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {cell.value}
          </Typography>
        </Tooltip>
      );
    }
    return (
      <Typography variant="body2" sx={{ maxWidth: '15.5rem' }}>
        {cell.value}
      </Typography>
    );
  };

  const handleClick = (event: any) => {
    event.target.value = '';
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: FIELDS) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(PATH.CSV_LIST);
  };

  const rows = createRows(csvList);

  const headCells: HeadCell<FIELDS>[] = [
    {
      id: FIELDS.UPLOAD_TIME,
      numeric: false,
      disablePadding: true,
      label: 'Data importu',
      sortable: false
    },
    {
      id: FIELDS.STATUS,
      numeric: false,
      disablePadding: true,
      label: 'Status',
      sortable: false
    },
    {
      id: FIELDS.RECORDS,
      numeric: false,
      disablePadding: true,
      label: 'Liczba zaimportowanych rekordów',
      sortable: false
    },
    {
      id: FIELDS.USER,
      numeric: false,
      disablePadding: true,
      label: 'Wykonawca importu',
      sortable: false
    },
    {
      id: FIELDS.FILENAME,
      numeric: false,
      disablePadding: true,
      label: 'Nazwa pliku',
      sortable: false
    },
    {
      id: FIELDS.ACTIONS,
      numeric: false,
      disablePadding: true,
      label: 'Akcje',
      sortable: false
    }
  ];

  const handleUploadFile = ({ target: { files } }: any) => {
    const loadedFile = files[0];
    setCsvFile(loadedFile);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Header
            title={
              <span id="csv-list-title" className={tableClasses.header}>
                Import danych - CSV
              </span>
            }
            tooltipLink={tooltipReportLink}
            tooltipText={tooltipReportText}
            hidePagination={true}
            justifyContent="space-between">
            {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR].includes(userRole) && (
              <Box sx={{ display: 'flex', paddingRight: '16px' }}>
                <StyledButton
                  variant="outlined"
                  disabled={hasCsvPermission === 'False'}
                  id="csv-list-download-button"
                  sx={{
                    border: '1px solid rgba(7, 138, 81, 0.5)',
                    color: '#078A51',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexShrink: '0',
                    margin: '0 1rem 0 0'
                  }}
                  startIcon={<FileDownloadIcon />}>
                  <a href={process.env.PUBLIC_URL + '/ImportCSV.csv'} download={'ImportCSV.csv'}>
                    Pobierz szablon pliku CSV
                  </a>
                </StyledButton>
                <UploadButton
                  disabled={hasCsvPermission === 'False'}
                  onInput={handleUploadFile}
                  onClick={handleClick}
                  id="csv-list-upload-button"
                  accept=".csv"
                  text={'WGRAJ NOWY PLIK CSV'}></UploadButton>
              </Box>
            )}
          </Header>
          {isLoadingCsvList ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15rem 0' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead
                      headCells={headCells}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {rows &&
                        rows.map((row, id) => {
                          const cells: CellType<FIELDS>[] = [
                            { value: row[FIELDS.RECORDS].toString(), name: FIELDS.RECORDS },
                            { value: row[FIELDS.USER], name: FIELDS.USER },
                            { value: row[FIELDS.FILENAME], name: FIELDS.FILENAME }
                          ];

                          return (
                            <TableRow key={`${row.importId}`} hover tabIndex={-1}>
                              <TableCell
                                component="th"
                                id={`${row.importId}`}
                                scope="row"
                                sx={{ padding: '1.5rem', whiteSpace: 'nowrap' }}>
                                {row[FIELDS.UPLOAD_TIME] &&
                                  moment(row[FIELDS.UPLOAD_TIME]).format('DD-MM-YYYY HH:mm:ss')}
                              </TableCell>
                              <TableCell component="th" id={`${row.importId}`} scope="row" sx={{ padding: '1.5rem' }}>
                                <CsvStatus status={row.status} id={id} />
                              </TableCell>
                              {cells.map((cell) => {
                                return (
                                  <TableCell
                                    key={cell.name}
                                    sx={{
                                      padding: '0.5rem 1.5rem'
                                    }}>
                                    {createCells(cell)}
                                  </TableCell>
                                );
                              })}
                              <TableCell sx={{ padding: '1rem' }}>
                                <CsvAction
                                  disabled={hasCsvPermission === 'False'}
                                  fileName={row.fileName}
                                  status={row.status}
                                  id={row.importId}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                extraData={`Liczba rekordów: ${
                  (csvResults as CsvResultsDto)?.totalSize ? (csvResults as CsvResultsDto).totalSize : 0
                }`}
                total={csvResponse?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Box>

      <Dialog
        isOpen={showInfoModal}
        isPending={isLoadingCsv}
        closeModal={() => setShowInfoModal(false)}
        accept={() => setShowInfoModal(false)}
        acceptLabel=""
        closeLabel="">
        <Box>
          <Box
            sx={{
              display: 'flex',
              color: '#000',
              fontSize: '1.2rem',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '0 0 1rem 0',
              fontWeight: 'bold',
              width: '100%'
            }}>
            Plik został wgrany i oczekuje na import
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '0 0 1.2rem 0',
              width: '100%'
            }}>
            Status importu możesz sprawdzić na liście importów
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Link to={`${PATH.CSV_LIST}`} onClick={() => reloadResults(query)}>
              Wróć do listy importów
            </Link>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CsvList;
