import { FC } from 'react';
import Box from '@mui/material/Box';
import { Props } from './FiltersTop.types';
import { StyledButton, Tooltip } from 'components';

const FiltersTop: FC<Props> = ({ clearFilters, text, link, disabled }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        padding: '1rem 1.5rem',
        width: '100%',
        justifyContent: 'space-between'
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', textTransform: 'uppercase', fontWeight: '500' }}>
        Filtrowanie
        {text && <Tooltip text={text} link={link} />}
      </Box>
      <StyledButton
        disabled={disabled}
        sx={{
          color: '#fff',
          fontSize: '0.7rem',
          padding: '9px 13px',
          whiteSpace: 'nowrap'
        }}
        variant="contained"
        onClick={clearFilters}>
        Wyczyść filtry
      </StyledButton>
    </Box>
  );
};

export default FiltersTop;
