import { ApiResponse } from "api/models";
import { AxiosPromise } from "axios";

export const downloadCsvFile = async (callback: AxiosPromise<ApiResponse<string>>, fileName: string, importId: number) => {
  const getData = await callback;
  const blob = new Blob([getData.data as unknown as BlobPart], {type: 'text/csv'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}`;
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};