import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiTooltip from '@mui/material/Tooltip';
import { Tooltip } from 'components';
import { SelectOption } from 'types';
import { Props } from './Filter.types';
import { FormControl, InputLabel } from '@mui/material';

const createSelectOption = (option: SelectOption) => {
  return (
    <MuiTooltip title={option.value} placement={'right'}>
      <Typography sx={{ maxWidth: '20rem', textOverflow: 'ellipsis', overflow: 'hidden' }}>{option.value}</Typography>
    </MuiTooltip>
  );
};

const Filter: FC<Props> = ({
  title,
  tooltipText,
  name,
  value,
  handleChange,
  error,
  options,
  defaultOption,
  isLast,
  isTableFilter,
  disabled,
  haveSelectedOption = true,
  hideHeader,
  noDefaultValue = false
}) => {
  const [showInputLabel, setShowInputLabel] = useState(noDefaultValue);
  
  const filterHeader = hideHeader ? (
    ''
  ) : (
    <Box
      sx={{
        display: 'flex',
        padding: '0 0 0.5rem 0'
      }}>
      <Typography variant="subtitle2">{title}</Typography>
      <Tooltip text={tooltipText} />
    </Box>
  );

  useEffect(() => {
    if (noDefaultValue) {
      if (value === '') {
        setShowInputLabel(true);
      } else {
        setShowInputLabel(false)
      }
    }
  }, [value]);

  return (
    <Box sx={{ 
        borderBottom: isLast ? 'none' : '1px solid #E0E0E0', 
        padding: isTableFilter ? '' : '1rem 1.5rem',
        flexShrink: '0',
        minWidth: '9rem'
      }}>
      { hideHeader ? '': filterHeader }
      <FormControl fullWidth sx={{ justifyContent: 'center' }}>
        {showInputLabel ? (
          <InputLabel disableAnimation shrink={false} focused={false} 
            sx={{display: 'flex', transform: 'translate(14px, 0) scale(1)', top: 'unset'}}> 
            Wybierz akcję
          </InputLabel>
        ) : null}
        <Select 
          name={name} 
          value={value} 
          onChange={handleChange} 
          error={error}
          defaultValue={defaultOption?.id}
          displayEmpty
          disabled={disabled}
          sx={{ 
            width: '100%', 
            height: '40px',
            color: haveSelectedOption ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.30)'
          }}>
          {defaultOption ? <MenuItem value={defaultOption.id}>{createSelectOption(defaultOption)}</MenuItem> : ''}
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {createSelectOption(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filter;
