export enum FIELDS {
  IMPORT_ID = 'importId',
  FILENAME = 'fileName',
  UPLOAD_TIME = 'uploadTime',
  STATUS = 'status',
  RECORDS = 'recordCount',
  USER = 'importOwner',
  ACTIONS = 'actions'
}

export enum IMPORT_STATUS {
  WAITING = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  ERROR = 3,
  COMPLETED_WITH_ERRORS = 4,
  FILE_ERROR = 5
}

export type FormikValues = {
  [FIELDS.FILENAME]: string;
  [FIELDS.STATUS]: number;
  [FIELDS.UPLOAD_TIME]: string;
  [FIELDS.USER]: string;
};

export enum IMPORT_TYPE {
  Manual = 1,
  Import_CSV = 2
}
