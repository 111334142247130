import { FC } from 'react';
import { IconProps } from '../Icons.types';

const ReportIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M1.2 0C0.54212 0 0 0.542122 0 1.2V16.8C0 17.4579 0.54212 18 1.2 18H12.8C13.4579 18 14 17.4579 14 16.8V1.2C14 0.542122 13.4579 0 12.8 0H1.2ZM1.2 0.8H12.8C13.0285 0.8 13.2 0.971488 13.2 1.2V16.8C13.2 17.0285 13.0285 17.2 12.8 17.2H1.2C0.97148 17.2 0.8 17.0285 0.8 16.8V1.2C0.8 0.971488 0.97148 0.8 1.2 0.8ZM9.1625 2.19375C9.06184 2.20417 8.96588 2.25444 8.9 2.33125L6.34376 5.25625L5.0375 4.275C4.86918 4.14771 4.5996 4.18676 4.4743 4.35657C4.34902 4.52638 4.39122 4.79547 4.5625 4.91875L6.1625 6.11875C6.32146 6.23644 6.56886 6.21055 6.7 6.0625L9.5 2.8625C9.6095 2.74366 9.63574 2.55547 9.56292 2.41121C9.49012 2.26694 9.32314 2.17626 9.1625 2.19375ZM2.8 8.2C2.57908 8.2 2.4 8.37909 2.4 8.6C2.4 8.82092 2.57908 9 2.8 9H11.2C11.4209 9 11.6 8.82092 11.6 8.6C11.6 8.37909 11.4209 8.2 11.2 8.2H2.8ZM2.8 11.4C2.57908 11.4 2.4 11.5791 2.4 11.8C2.4 12.0209 2.57908 12.2 2.8 12.2H11.2C11.4209 12.2 11.6 12.0209 11.6 11.8C11.6 11.5791 11.4209 11.4 11.2 11.4H2.8ZM2.8 14.6C2.57908 14.6 2.4 14.7791 2.4 15C2.4 15.2209 2.57908 15.4 2.8 15.4H11.2C11.4209 15.4 11.6 15.2209 11.6 15C11.6 14.7791 11.4209 14.6 11.2 14.6H2.8Z" 
        fill={color}/>
    </svg>
  );
};

export default ReportIcon;