import { ChangeEvent, FC, memo, useState } from 'react';
import MuiAutoComplete from '@mui/material/Autocomplete';
import { Props } from './Autocomplete.types';
import { Box } from '@mui/system';
import { StyledTextField } from 'components';
import classes from './Autocomplete.module.scss';

const Autocomplete: FC<Props> = memo(
  ({ onChangeHandler, label, onOptionSelected, autofocus, companies, outerBoxSx, inputValue, setInputValue }) => {
    const noDataText: string = 'Nie znaleziono klientów pasujących do wpisanej frazy.';
    const searchPhraseTooShortText: string = 'Wprowadź minimum 3 znaki.';

    const [noOptionsText, setNoOptionsText] = useState<string>(searchPhraseTooShortText);

    return (
      <Box sx={outerBoxSx}>
        <MuiAutoComplete
          options={companies ?? []}
          getOptionLabel={(company) => `${company.name}`}
          ListboxProps={{ className: classes.autoCompleteListbox }}
          noOptionsText={noOptionsText}
          isOptionEqualToValue={() => false}
          disableClearable={false}
          clearIcon={null}
          filterOptions={(options) => options}
          fullWidth
          value={inputValue || null}
          onChange={(_, value) => {
            setInputValue(value);
            onOptionSelected?.(value);
          }}
          renderOption={(props, company) => {
            return (
              <li
                {...props}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0.25rem 1rem'
                }}
                key={`${company.name}${company.taxIdentificationNumber}`}>
                <p className={classes.companyName}>{company.name}</p>
                <div style={{ display: 'flex' }}>
                  <p className={classes.taxIdentificationNumberHeader}>NIP:</p>
                  <p className={classes.taxIdentificationNumber}>{company.taxIdentificationNumber}</p>
                </div>
              </li>
            );
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                setNoOptionsText(value.length < 3 ? searchPhraseTooShortText : noDataText);
                onChangeHandler(e);
              }}
              margin="normal"
              label={label}
              autoFocus={autofocus}
            />
          )}
        />
      </Box>
    );
  }
);

export default Autocomplete;
