import { memo, FC } from 'react';
import { ReportUserDto } from 'api/models';
import { FIELDS, RecipientsProps, FormControlProps } from '../types';
import Box from '@mui/material/Box';
import { createTheme, FormControlLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyledCheckbox } from 'components';

export const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
});

const FormControl: FC<FormControlProps> = memo(({ reportUser, multiCheckboxHandler, recipientsValue, disabled }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          sx={{ whiteSpace: 'nowrap', marginRight: '20px', maxWidth: '100%' }}
          onChange={(event) => multiCheckboxHandler(event, FIELDS.RECIPIENTS, recipientsValue)}
          value={reportUser.id}
          name={'user'}
          key={reportUser.id}
          control={
            <StyledCheckbox
              sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
              checked={recipientsValue.includes(reportUser.id)}
              disabled={disabled}
              id={`report-recipient-checkbox-${reportUser.id}`}
            />
          }
          label={
            <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {reportUser.firstName} {reportUser.lastName}
            </Box>
          }
        />
      </ThemeProvider>
    </Box>
  );
});

const Recipents: FC<RecipientsProps> = memo(
  ({ users, multiCheckboxHandler, recipientsValue, searchValue, disabled }) => {
    const visibleElementsInColumn = 10;
    const elementHeight = 30.375;
    const reciepientsLength = users?.results?.length;
    const halfOfReciepients = reciepientsLength ? Math.ceil(reciepientsLength / 2) : 0;
    const sortedList = users?.results.sort((a: ReportUserDto, b: ReportUserDto) =>
      a.firstName.localeCompare(b.firstName)
    );

    const recipientsList = (start: number, end: number = 0) => {
      return (
        <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column', padding: '0 1rem' }}>
          {sortedList
            ?.filter(
              (reportUser: ReportUserDto) =>
                `${reportUser.firstName.toLocaleLowerCase()} ${reportUser.lastName.toLocaleLowerCase()}`.includes(
                  searchValue.length > 2 ? searchValue.toLocaleLowerCase() : ''
                ) ||
                `${reportUser.lastName.toLocaleLowerCase()} ${reportUser.firstName.toLocaleLowerCase()}`.includes(
                  searchValue.length > 2 ? searchValue.toLocaleLowerCase() : ''
                )
            )
            .slice(start, end)
            .map((reportUser: ReportUserDto) => (
              <FormControl
                key={reportUser.id}
                reportUser={reportUser}
                multiCheckboxHandler={multiCheckboxHandler}
                recipientsValue={recipientsValue}
                disabled={disabled}
              />
            ))}
        </Box>
      );
    };

    return (
      <Box
        sx={{
          overflowY: 'scroll',
          height: `${visibleElementsInColumn * elementHeight}px`,
          position: 'relative',
          display: 'flex'
        }}>
        {recipientsList(0, halfOfReciepients)}
        {recipientsList(halfOfReciepients, users?.results?.length)}
      </Box>
    );
  }
);

export default Recipents;
