import { object, string } from 'yup';
import { polishTaxNumberValidation, euTaxNumberValidation, descriptionValidation } from '../../../../lib/utils';
import { ClientFormTypes } from '../types';

const baseSchema = {
  [ClientFormTypes.FIELDS.NAME]: string()
    .max(100, 'Maksymalna ilość znaków to 100')
    .trim()
    .required('To pole jest obowiązkowe'),
  [ClientFormTypes.FIELDS.CITY]: string().max(500, 'Maksymalna ilość znaków to 500').trim(),
  [ClientFormTypes.FIELDS.STREET]: string().max(1000, 'Maksymalna ilość znaków to 1000').trim(),
  [ClientFormTypes.FIELDS.BUILDING_NUMBER]: string().max(10, 'Maksymalna ilość znaków to 10'),
  [ClientFormTypes.FIELDS.APARTAMENT_NUMBER]: string().max(10, 'Maksymalna ilość znaków to 10'),
  [ClientFormTypes.FIELDS.DESCRIPTION_1]: descriptionValidation(),
  [ClientFormTypes.FIELDS.DESCRIPTION_2]: descriptionValidation()
};

const nonPolishPostcode = {
  [ClientFormTypes.FIELDS.POSTCODE]: string().max(50, 'Maksymalna ilość znaków to 50')
};

const extendedPolishSchema = {
  [ClientFormTypes.FIELDS.TAX_NUMBER]: polishTaxNumberValidation()
};

const extendedEuSchema = (value: string) => ({
  [ClientFormTypes.FIELDS.TAX_NUMBER]: euTaxNumberValidation(value),
  ...nonPolishPostcode
});

export const polishClientSchema = object().shape({ ...baseSchema, ...extendedPolishSchema });
export const euClientSchema = (value: string) => object().shape({ ...baseSchema, ...extendedEuSchema(value) });
