import { FC } from 'react';
import { DownloadProps } from './Download.types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import classes from './Download.module.scss';
import { getXlsPeriodicReport } from 'api/services/periodicReports';
import { downloadXlsFile } from 'lib/utils';

const Download: FC<DownloadProps> = ({ download, fileName }) => {
  const downloadFile = () => downloadXlsFile(getXlsPeriodicReport(String(download)), fileName);

  return (
    <button className={classes.button} onClick={downloadFile} type="button">
      <FileDownloadIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </button>
  );
};

export default Download;
