import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-width: 10rem;
  min-height: 10rem;
`;

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: 3.25rem;
  height: 3.25rem;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Circle = styled.circle`
  stroke: #000;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
`;
