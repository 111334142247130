import { FC } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { CustomerCheckboxGroup } from './CheckboxGroup.types';
import { Tooltip } from 'components';
import classes from './styles.module.scss';

const CheckboxGroup: FC<CustomerCheckboxGroup> = ({
  children,
  title,
  tooltipText,
  tooltipLink,
  flexDirection,
  isLast,
  handleChange,
  tooltipTutorialInfo
}) => {
  return (
    <div className={isLast ? `${classes.container} ${classes.containerLast}` : classes.container}>
      <div className={classes.title}>
        <Typography variant="subtitle2">{title}</Typography>
        {tooltipText && <Tooltip text={tooltipText} link={tooltipLink} tutorialInfo={tooltipTutorialInfo} />}
      </div>
      <FormGroup sx={{ flexDirection: flexDirection ? flexDirection : '' }} onChange={handleChange}>
        {children}
      </FormGroup>
    </div>
  );
};

export default CheckboxGroup;
