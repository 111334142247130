import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TitlePrefix } from './PageTitle.styled';
import { Props } from './PageTitle.types';

const PageTitle: FC<Props> = ({ children, sx, dataTest }) => {
  return (
    <Box display="flex" alignItems="center" mb="0.75rem" sx={sx}>
      <TitlePrefix />
      <Typography variant="h5" component="h1" fontWeight="500" id={dataTest}>
        {children}
      </Typography>
    </Box>
  );
};

export default PageTitle;
