import { FC } from 'react';
import SimpleBar from 'simplebar-react';

const Scrollbar: FC = ({ children }) => {
  return (
    <SimpleBar autoHide={false} forceVisible={true}>
      {children}
    </SimpleBar>
  );
};

export default Scrollbar;
