import { FC } from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { CustomerCheckboxGroup } from './RadioGroup.types';
import { Tooltip } from 'components';

const RadioGroup: FC<CustomerCheckboxGroup> = ({
  children,
  title,
  tooltipText,
  tooltipLink,
  flexDirection,
  isLast,
  handleChange,
  noPadding,
  tooltipTutorialInfo
}) => {
  return (
    <Box
      sx={{
        borderBottom: isLast ? 'none' : '1px solid #E0E0E0',
        padding: noPadding ? '' : '1rem 1.5rem'
      }}>
      <Box
        sx={{
          display: 'flex',
          padding: '0.4rem 0'
        }}>
        <Typography variant={'subtitle2'}>{title}</Typography>
        {tooltipText && <Tooltip text={tooltipText} link={tooltipLink} tutorialInfo={tooltipTutorialInfo} />}
      </Box>
      <FormGroup sx={{ flexDirection: flexDirection ? flexDirection : '' }} onChange={handleChange}>
        {children}
      </FormGroup>
    </Box>
  );
};

export default RadioGroup;
