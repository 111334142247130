import { FC, useState } from 'react';
import { TutorialMenuSectionProps } from '../types/TutorialSection.types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TutorialMenuSubsection from './TutorialMenuSubsection';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const TutorialMenuSection: FC<TutorialMenuSectionProps> = ({ name, index, subsections }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => (e: any) => {
    setExpanded(!expanded);
  };

  const style = { color: expanded ? '#078A51' : 'inherit' };
  const headerStyle = { display: 'flex', fontSize: '16px', fontWeight: '500', ...style };

  return (
    <Accordion disableGutters square expanded={expanded} onChange={handleChange()}>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon sx={style} />}>
        <div style={headerStyle}>
          <span style={{ marginRight: '0.5rem' }}>{index}</span>
          <span id={`tutorial-menu-item-${index}`}>{name}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {subsections.map((x, i) => (
            <TutorialMenuSubsection key={i} id={x.id} name={x.name} index={x.index}></TutorialMenuSubsection>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TutorialMenuSection;
