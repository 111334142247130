import { FC, memo } from 'react';
import MuiTextField from '@mui/material/TextField';
import { handleChange, getBackendValidationError } from 'lib/utils';
import { Props } from './TextField.types';
import { Box } from '@mui/system';
import { StyledTextField, Tooltip } from 'components';

const TextField: FC<Props> = memo(
  ({
    field,
    validationErrors,
    disabled,
    onChangeHandler,
    onBlurHandler,
    onKeyPressHandler,
    errorsSetter,
    autoFocus,
    extraStyles,
    tooltipText,
    dataTest,
    onPasteHandle
  }) => {
    const { name, label, value, error, helperText } = field;

    const backendError = getBackendValidationError(name, validationErrors);
    const tooltip = tooltipText ? (
      <Box sx={{ position: 'absolute', right: '-2rem', margin: '1.1rem 0 0 0' }}>
        <Tooltip text={tooltipText} />
      </Box>
    ) : (
      ''
    );
    return (
      <Box
        id={dataTest ? `${dataTest}-wrapper` : undefined}
        sx={{
          width: '100%',
          marginTop: '1.5rem',
          display: 'flex',
          alignItems: 'flex-start',
          position: 'relative',
          ...extraStyles
        }}>
        <StyledTextField
          name={name}
          label={label}
          value={value}
          onChange={handleChange(name, onChangeHandler, errorsSetter)}
          onBlur={onBlurHandler}
          onKeyPress={onKeyPressHandler}
          error={error || !!backendError}
          helperText={helperText || backendError}
          sx={{ width: '100%' }}
          autoFocus={autoFocus}
          disabled={disabled}
          id={dataTest}
          onPaste={() => onPasteHandle && onPasteHandle(name)}
        />
        {tooltip}
      </Box>
    );
  }
);

export default TextField;
