import { FC } from 'react';
import Box from '@mui/material/Box';
import { CredibilityProps } from './CredibilityIcon.types';

const CredibilityIcon: FC<CredibilityProps> = ({ letter }) => {
  const colors: any = {
    A: {
      backgroundColor: 'rgba(0, 211, 47, 0.1)',
      color: '#00D32F'
    },
    B: {
      backgroundColor: 'rgba(119, 211, 0, 0.1)',
      color: '#77D300'
    },
    C: {
      backgroundColor: 'rgba(172, 209, 0, 0.1)',
      color: '#ACD100'
    },
    D: {
      backgroundColor: 'rgba(216, 205, 0, 0.1)',
      color: '#D8CD00'
    },
    E: {
      backgroundColor: 'rgba(255, 199, 0, 0.1)',
      color: '#FFC700'
    },
    F: {
      backgroundColor: 'rgba(229, 39, 19, 0.1)',
      color: '#FF9A00'
    },
    G: {
      backgroundColor: 'rgba(255, 104, 0, 0.1)',
      color: '#FF6800'
    },
    H: {
      backgroundColor: 'rgba(249, 24, 24, 0.1)',
      color: '#F91818'
    },
    X: {
      backgroundColor: 'rgba(41, 44, 55, 0.1)',
      color: '#292C37'
    },
    none: {
      backgroundColor: 'rgba(138, 143, 163, 0.1)',
      color: '#8A8FA3'
    }
  };

  return (
    <>
      {colors[letter] && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: '0',
            flexGrow: '0',
            width: '1.6rem',
            height: '1.6rem',
            background: colors[letter].backgroundColor,
            border: `1px solid ${colors[letter].color}`,
            color: colors[letter].color,
            fontWeight: '500',
            borderRadius: '4px',
            '&:hover': {
              background: colors[letter].color,
              color: '#fff'
            }
          }}>
          {letter === 'none' ? '-' : letter}
        </Box>
      )}
    </>
  );
};

export default CredibilityIcon;
