import { FC } from 'react';
import { Props } from './Description.types';
import { nameShortener } from 'lib/utils';
import { Tooltip } from '@mui/material';

const Description: FC<Props> = ({ description }) => {
  if (description) {
    return (
      <Tooltip title={description}>
        <div>{nameShortener(description, 15)}</div>
      </Tooltip>
    );
  }
  return null;
};

export default Description;
