import { AxiosPromise } from 'axios';
import { ApiResponse } from 'api/models';

export enum FIELDS {
  FIRST_NAME = 'FirstName',
  LAST_NAME = 'LastName',
  EMAIL = 'Email',
  PHONE = 'Phone',
  COMPANY_ID = 'CompanyId',
  ROLE = 'Role',
  HAS_MONITORING_PERMISSION = 'HasClientMonitoringPermission',
  HAS_REPORT_PERMISSION = 'HasClientReportPermission',
  HAS_CSV_PERMISSION = 'HasCsvPermission',
  HAS_ROLE_CHANGING_PERMISSIONS = 'HasRoleChangingPermissions'
}

export type FormikValues = {
  [FIELDS.FIRST_NAME]: string;
  [FIELDS.LAST_NAME]: string;
  [FIELDS.EMAIL]: string;
  [FIELDS.PHONE]: string;
  [FIELDS.COMPANY_ID]: string;
  [FIELDS.ROLE]: number;
  [FIELDS.HAS_MONITORING_PERMISSION]: boolean;
  [FIELDS.HAS_REPORT_PERMISSION]: boolean;
  [FIELDS.HAS_CSV_PERMISSION]: boolean;
  [FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]: boolean;
};

export type FieldType = {
  name: FIELDS;
  label: string;
  value: string;
  error?: boolean;
  helperText?: string | false;
  type?: string;
  dataTest?: string;
};

export type Props = {
  inEditMode?: boolean;
  initialValues: FormikValues;
  submitHandler: (values: FormikValues) => AxiosPromise<ApiResponse<string>>;
};

export const mapRolesToNames = (role: number) => {
  switch (role) {
    case 3:
      return 'Administrator firmy';
    case 4:
      return 'Pracownik firmy';
    case 5:
      return 'Użytkownik bez dostępu do serwisu';
    case 100:
      return 'Konto techniczne';
    default:
      return '';
  }
};
