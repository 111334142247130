import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import { ROLE } from 'config/oidc';
import { useAuth } from 'oidc-react';
import {
  Indebtedness,
  PaymentCredibility as PaymentCredibilityComponent,
  TradeCreditLimit as TradeCreditLimitComponent,
  Report as ReportComponent,
  SaleDebtItem,
  Description
} from './index';
import { Typography, Tooltip } from '@mui/material';
import { CellType } from 'types';
import { nameShortener } from 'lib/utils';
import { CUSTOMER_FIELDS } from '../customersList.types';
import { PATH } from 'router/routes';
import { memo, FC } from 'react';
import { TableCellsProps } from './TableCells.types';
import { StyledCheckbox } from 'components';

const fieldsWithLongNames = [CUSTOMER_FIELDS.NAME];

const TableCells: FC<TableCellsProps> = memo(
  ({ row, setMonitoringTaxId, handleCheckboxClick, handleCustomerCheckbox, isCheck }) => {
    const createCells = (cell: CellType<CUSTOMER_FIELDS>) => {
      if (fieldsWithLongNames.includes(cell.name)) {
        return (
          <Tooltip title={cell.value}>
            <Typography
              variant="body2"
              sx={{ maxWidth: '8rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {nameShortener(cell.value, 15)}
            </Typography>
          </Tooltip>
        );
      }
      return (
        <Typography variant="body2" sx={{ maxWidth: '15.5rem' }}>
          {cell.value}
        </Typography>
      );
    };

    const cells: CellType<CUSTOMER_FIELDS>[] = [
      { value: row[CUSTOMER_FIELDS.NAME], name: CUSTOMER_FIELDS.NAME },
      {
        value: row[CUSTOMER_FIELDS.TAX_NUMBER].replace('PL', ''),
        name: CUSTOMER_FIELDS.TAX_NUMBER
      }
    ];

    const auth = useAuth();

    const userRole = auth?.userData?.profile?.Role;
    const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
    const companyId =
      userRole === ROLE.CURATOR && curatorSelectedCompanyId
        ? curatorSelectedCompanyId
        : auth?.userData?.profile?.Company;

    return (
      <>
        <TableCell sx={{ padding: '1rem 0 1rem .5rem' }}>
          <StyledCheckbox
            key={row.id}
            id={`${row.id}`}
            onChange={(event) => {
              handleCheckboxClick(event);
              handleCustomerCheckbox(row.taxIdentificationNumber, row?.monitoringId, event);
            }}
            checked={isCheck.includes(`${row.id}`)}
            inputProps={{ 'aria-label': row.taxIdentificationNumber }}
          />
        </TableCell>
        {cells.map((cell) => (
          <TableCell key={`x${row.id}-${cell.name}`} sx={{ padding: '1rem 0.5rem 1rem 1.5rem' }}>
            {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR].includes(userRole) ? (
              <Link
                to={`${PATH.CUSTOMER_PROFILE}/${row.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '3.25rem',
                  whiteSpace: 'nowrap',
                  textDecoration: cell.name === CUSTOMER_FIELDS.NAME ? 'underline' : 'none'
                }}>
                {createCells(cell)}
              </Link>
            ) : (
              createCells(cell)
            )}
          </TableCell>
        ))}
        <TableCell sx={{ padding: '1rem 0.5rem 1rem 1.5rem' }}>
          <PaymentCredibilityComponent scoring={row} />
        </TableCell>
        <TableCell sx={{ padding: '1rem .5rem 1rem 1.5rem', width: '149px', textAlign: 'right' }} align="right">
          <Indebtedness
            data={row}
            role={userRole}
            enableMonitoring={() => setMonitoringTaxId(row.taxIdentificationNumber)}
          />
        </TableCell>
        <TableCell>
          <TradeCreditLimitComponent
            tradeCreditLimitAuthorizationErrorState={row.tradeCreditLimitAuthorizationErrorState}
            monitoringState={row.monitoringState}
            scoringState={row.scoringState}
            tradeCreditLimitValue={row.tradeCreditLimitValue}
            tradeCreditLimitModel={row.tradeCreditLimitModel}
            tradeCreditLimitStatus={row.tradeCreditLimitStatus}
            riskGroupCode={row.riskGroupCode}
            isMonitoringRetry={row.isMonitoringRetry}
          />
        </TableCell>
        <TableCell>
          {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER].includes(userRole) && (
            <ReportComponent {...row} companyId={companyId} />
          )}
        </TableCell>
        <TableCell sx={{ padding: '1rem .5rem 1rem 1.5rem', minWidth: '158px' }}>
          <Description description={row.description1} />
        </TableCell>
        <TableCell sx={{ padding: '1rem .5rem 1rem 1.5rem', minWidth: '158px' }}>
          <Description description={row.description2} />
        </TableCell>
        <TableCell sx={{ padding: '1rem .5rem 1rem 1.5rem', width: '150px' }}>
          <Link
            to={`${PATH.CUSTOMER_PROFILE}/${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '3.2rem',
              whiteSpace: 'nowrap'
            }}>
            <SaleDebtItem
              value={row.GrossSaleLast12Months}
              isImportOutdated={row.isImportOutdated}
              importDate={row.importDate}
            />
          </Link>
        </TableCell>
        <TableCell sx={{ padding: '1rem .5rem 1rem 1.5rem', width: '150px' }}>
          <Link
            to={`${PATH.CUSTOMER_PROFILE}/${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '3.2rem',
              whiteSpace: 'nowrap'
            }}>
            <SaleDebtItem value={row.GrossDebt} isImportOutdated={row.isImportOutdated} importDate={row.importDate} />
          </Link>
        </TableCell>
        <TableCell sx={{ width: '100%' }}></TableCell>
      </>
    );
  }
);

export default TableCells;
