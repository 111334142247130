import { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { handleChange, getBackendValidationError } from 'lib/utils';
import { Dictionary } from 'types';
import { Props } from './Select.types';

const Select: FC<Props> = ({
  field,
  options,
  validationErrors,
  disabled,
  onChangeHandler,
  onClick,
  errorsSetter,
  dataTest
}) => {
  const { name, label, value, error, helperText } = field;
  const backendError = getBackendValidationError(name, validationErrors);

  return (
    <FormControl sx={{ marginTop: '1.5rem' }}>
      <InputLabel id={name}>Firma</InputLabel>
      <MuiSelect
        name={name}
        labelId={name}
        label={label}
        value={value}
        onChange={handleChange(name, onChangeHandler, errorsSetter)}
        error={error || !!backendError}
        disabled={disabled}
        id={dataTest}
        onClick={onClick}>
        {options.map((option: Dictionary) => (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
      </MuiSelect>
      {(error || !!backendError) && (
        <FormHelperText sx={{ color: '#d32f2f' }}>{helperText || backendError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
