import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { monitoringsUsage } from 'api/services';

export enum Actions {
  getMonitorings = '[MONITORING] get monitorings counter'
};

export const getMonitoringCounterThunk = createAsyncThunk(Actions.getMonitorings, async (companyId: string) => {
  const { data } = await monitoringsUsage(companyId);
  return data?.Result;
});

export const isPendingAction = isPending(getMonitoringCounterThunk);
export const isFulfilledAction = isFulfilled(getMonitoringCounterThunk);
export const isRejectedAction = isRejected(getMonitoringCounterThunk);