export enum FIELDS {
  ID = 'id',
  ROW_NUMBER = 'rowNumber',
  COLUMN_NAME = 'fieldName',
  ERROR_MESSAGE = 'errorName'
};

export type FormikValues = {
  [FIELDS.ID]: number;
  [FIELDS.ROW_NUMBER]: number;
  [FIELDS.COLUMN_NAME]: string;
  [FIELDS.ERROR_MESSAGE]: string;
};