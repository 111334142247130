import { ClientFormTypes } from '../../shared';

export const initialValues = {
  [ClientFormTypes.FIELDS.NAME]: '',
  [ClientFormTypes.FIELDS.TAX_NUMBER]: '',
  [ClientFormTypes.FIELDS.BUILDING_NUMBER]: '',
  [ClientFormTypes.FIELDS.APARTAMENT_NUMBER]: '',
  [ClientFormTypes.FIELDS.CITY]: '',
  [ClientFormTypes.FIELDS.STREET]: '',
  [ClientFormTypes.FIELDS.POSTCODE]: '',
  [ClientFormTypes.FIELDS.DESCRIPTION_1]: '',
  [ClientFormTypes.FIELDS.DESCRIPTION_2]: ''
};
