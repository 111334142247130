import { logoBlack } from 'assets/images/logo';
import styles from './generatingPeriodicReport.module.scss';
import FadeLoader from 'react-spinners/FadeLoader';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPeriodicReport } from 'api/services/periodicReports';

const GeneratingPeriodicReport = () => {
  const [reportGenerated, setReportGenerated] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [searchParams] = useSearchParams();

  const getReport = async () => {
    setIsActive(true);
    try {
      const token = searchParams.get('token');
      const id = searchParams.get('id');

      if (id === null || token === null) {
        setIsActive(false);
        return;
      }

      const reportResult = await getPeriodicReport(token, id);

      if (reportResult) {
        setPdfData(reportResult);
        setReportGenerated(true);
      }
    } catch {
      setIsActive(false);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  if (reportGenerated) {
    window.location.href = pdfData.config.url;
  }

  return (
    <div className={styles.generetingReportBox}>
      <div className={styles.generetingReportTop}>
        <div className={styles.generetingReportTopLeft}>
          {isActive ? 'Trwa generowanie raportu' : 'Pobieranie raportu'}
        </div>
        <div className={styles.generetingReportTopRight}>
          <img src={logoBlack} alt="Logo" className={styles.generetingReportLogo} />
        </div>
      </div>
      <div className={styles.generetingReportBottom}>
        {isActive ? (
          <>
            <div className={styles.generetingReportBottomLeft}>
              <FadeLoader color="#078A51" />
            </div>
            <div className={styles.generetingReportBottomRight}>
              <span className={styles.generetingReportBottomMessage1}>Może to potrwać 30 sekund.</span>
              <span className={styles.generetingReportBottomMessage2}>
                Nie odświeżaj strony i nie zamykaj przeglądarki.
              </span>
            </div>
          </>
        ) : (
          <>
            <div className={styles.generetingReportBottomLeft}>
              <div className={styles.generetingReportError}>!</div>
            </div>
            <div className={styles.generetingReportBottomRight}>
              <span className={styles.generetingReportBottomMessageError}>
                Link do raportu wygasł lub nie posiadasz uprawnień do pobierania raportów.
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GeneratingPeriodicReport;
