import { FC } from "react";
import { TutorialMenuSubsectionProps } from "../types/TutorialSection.types";
import { HashLink } from 'react-router-hash-link';
import styles from "./tutorial.module.scss";

const TutorialMenuSubsection: FC<TutorialMenuSubsectionProps> = ({ id, name, index }) => {
    return (
        <HashLink smooth to={`#${id}`}>
            <div className={styles.menuLink}>
                <span style={{ marginRight: "0.5rem" }}>{index}</span>
                <span>{name}</span>
            </div>
        </HashLink>
    );
};

export default TutorialMenuSubsection;