import { Button } from '@mui/material';
import { getImportFile } from 'api/services';
import { downloadCsvFile } from 'lib/utils';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from 'router/routes';
import { IMPORT_STATUS } from '../csvList.types';
import { Props } from './CsvAction.types';

const CsvAction: FC<Props> = ({ id, fileName, disabled = false, status }) => {
  const downloadButton = (
    <Button
      sx={{
        height: '1.8rem',
        fontSize: '0.7rem',
        textTransform: 'uppercase',
        margin: '.2rem 1rem .2rem 0',
        color: '#078A51',
        border: '1px solid rgba(7, 138, 81, 0.5)',
        whiteSpace: 'nowrap'
      }}
      variant="outlined"
      id="csv-list-download-csv-button"
      disabled={disabled}
      onClick={() => downloadCsvFile(getImportFile(String(id)), fileName, id)}>
      Pobierz plik
    </Button>
  );

  const showErrorsButton = (
    <Button
      component={Link}
      disabled={disabled}
      id="csv-list-errors-link"
      to={`${PATH.CSV_ERRORS}/${id}`}
      color="error"
      sx={{ height: '1.8rem', fontSize: '0.7rem', textTransform: 'uppercase' }}
      variant="outlined">
      Pokaż błędy
    </Button>
  );

  const downloadFile = fileName && status === IMPORT_STATUS.COMPLETED ? downloadButton : null;

  const errors = status === IMPORT_STATUS.ERROR || status === IMPORT_STATUS.FILE_ERROR ? showErrorsButton : null;

  const finishedWithErrors =
    status === IMPORT_STATUS.COMPLETED_WITH_ERRORS ? (
      <>
        {downloadButton}
        {showErrorsButton}
      </>
    ) : null;

  return (
    <>
      {downloadFile}
      {errors}
      {finishedWithErrors}
    </>
  );
};

export default CsvAction;
