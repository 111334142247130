import React, { useEffect, useRef } from 'react';

export const SwaggerAuthorize = () => {
  const formRef = useRef(document.createElement('form'));
  const actionUrl = process.env.REACT_APP_API_URL + '/securedswagger';

  useEffect(() => {
    formRef.current.submit();
  }, [formRef]);
  return (
    <form id="tokenForm" ref={formRef} method="post" action={actionUrl}>
      <input type="hidden" value={localStorage.getItem('authToken') || ''} name="token" />
    </form>
  );
};
