import { FIELDS, ReportRow } from '../reportsList.types';

export const createRows = (report?: ReportRow[]) => {
  if (!report) return [];

  return report.map((result) => ({
    [FIELDS.REPORT_ID]: result.id,
    [FIELDS.NAME]: result.name,
    [FIELDS.CREATOR]: getCreatorFullName(result),
    [FIELDS.FREQUENCY]: result.frequency
  }));

  function getCreatorFullName(result: ReportRow): string {
    if (result.creator === null || result.creator === undefined) {
      return '';
    }
    return result.creator.firstName + ' ' + result.creator.lastName;
  }
};
