import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { ReportCounterStateType } from './reducer';
import { RequestStatus } from '../Store.types';

const selectState = ({ reportCounter }: RootState) => reportCounter;

export const reportCounterSelector = createSelector(selectState, (state: ReportCounterStateType) => state.value);

const mapStatus = (reportCounter: ReportCounterStateType): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = reportCounter;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

export const reportCounterStatusSelector = createSelector(selectState, (reportCounter) => mapStatus(reportCounter));
