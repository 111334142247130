import { FC } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  createTheme
} from '@mui/material';
import classes from './ComboBox.module.scss';
import { Props } from './ComboBox.types';

const ComboBox: FC<Props> = ({ values, handleChange, label, size, selectValue }) => {
  const handleChangeEvent = (e: SelectChangeEvent): void => {
    handleChange(e.target.value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#078A51'
      },
      secondary: {
        main: '#078A51'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          className={classes.input}
          labelId="select-label"
          value={selectValue}
          label={label}
          size={size}
          onChange={handleChangeEvent}>
          {values.map((value) => (
            <MenuItem value={value.value} key={value.value}>
              {value.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default ComboBox;
