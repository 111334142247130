export type Nullable<T> = T | null | undefined;

export type Dictionary = {
  id: string;
  value: string;
};

export type CellType<T> = {
  name: T;
  value: string;
};

export type HeadCell<T> = {
  disablePadding: boolean;
  id: T;
  label: string;
  numeric: boolean;
  sortable: boolean;
};

export type TableHeadProps<T> = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: T) => void;
  order: Order;
  orderBy: T;
  headCells: HeadCell<T>[];
};

export type Order = 'asc' | 'desc';

export const mapSortDirection = (order: Order) => (order === 'asc' ? 'true' : 'false');
