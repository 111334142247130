import { CsvErrorDto } from 'api/models';
import { FIELDS } from '../csvErrors.types';

export const createRows = (csv?: CsvErrorDto[]) => {
  if (!csv) return [];

  return csv.map((result) => ({
    [FIELDS.ID]: result.id,
    [FIELDS.ROW_NUMBER]: result.rowNumber,
    [FIELDS.COLUMN_NAME]: result.columnName,
    [FIELDS.ERROR_MESSAGE]: result.errorMessage,
  }));
};