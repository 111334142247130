import { getReportFile } from "api/services";
import moment from "moment";

export const downloadReport = async (reportId: number | undefined, reportDate: string | undefined) => {
  const getData = await getReportFile(String(reportId));
  const blob = new Blob([getData.data as unknown as BlobPart], {type: 'application/pdf'});
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `raport-${moment(reportDate ? reportDate : undefined).format('DD-MM-YYYY')}-${reportId}.pdf`;
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};