import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { Props } from './PageSubtitle.types';

const PageSubtitle: FC<Props> = ({ subtitle, sx, dataTest }) => {
  return (
    <Typography variant="h6" component="h2" sx={{ fontWeight: '500', ...sx }}>
      {subtitle}
    </Typography>
  );
};

export default PageSubtitle;
