import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from './StyledCheckbox.styled';

const StyledCheckbox = (props: CheckboxProps) => {

  return (
    <MuiThemeProvider theme={theme}>
      <Checkbox {...props}></Checkbox>
    </MuiThemeProvider>
  );
};

export default StyledCheckbox;