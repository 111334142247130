import { useState, MouseEvent, useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { logout } from 'lib/utils';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { logoWhite, logoKaczmarski } from 'assets/images/logo';
import { Navigation, UserName, MenuButton, Stat, StatCount, tooltipTheme } from './Nav.styled';
import { ROLE } from 'config/oidc';
import { MonitoringIcon, ReportIcon } from 'assets/images/icons';
import { ThemeProvider } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getReportCounterThunk, reportCounterSelector } from 'store/reportCounter';
import { getMonitoringCounterThunk, monitoringCounterSelector } from 'store/monitoringCounter';
import { Typography } from '@mui/material';
import classes from './styles.module.scss';
import { connectionSelector } from 'store/connection';
import { NotificationType, PushNotification } from '../../App';

const Nav = () => {
  const dispatch = useDispatch();
  const reportCounter = useSelector(reportCounterSelector);
  const monitoringCounter = useSelector(monitoringCounterSelector);
  const connection = useSelector(connectionSelector);

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const [shouldIgnore, setShouldIgnore] = useState(true);
  const [reportHover, setReportHover] = useState<boolean>(false);
  const [monitoringHover, setMonitoringHover] = useState<boolean>(false);
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    (userRole === ROLE.CURATOR || userRole === ROLE.ADMIN_KG) && curatorSelectedCompanyId
      ? curatorSelectedCompanyId
      : auth?.userData?.profile?.Company;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const truncateText = (str: string, n: number) => {
    return str.length > n ? str.slice(0, n - 1) + '...' : str;
  };

  const showCuratorSelectedCompanyName = () => {
    const curatorSelectedCompanyName = localStorage.getItem('curatorSelectedCompanyName');
    if (userRole === ROLE.CURATOR && curatorSelectedCompanyName) {
      return curatorSelectedCompanyName.length > 20 ? (
        <>({truncateText(curatorSelectedCompanyName, 17)})</>
      ) : (
        <>({curatorSelectedCompanyName})</>
      );
    }
  };

  useEffect(() => {
    if ([ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER].includes(userRole)) {
      setShouldIgnore(false);
    }
  }, [userRole]);

  useEffect(() => {
    if (!shouldIgnore) {
      dispatch(getMonitoringCounterThunk(companyId));
      dispatch(getReportCounterThunk(companyId));
    }
  }, [shouldIgnore]);

  useEffect(() => {
    connection?.on('SendNotification', (message: PushNotification) => {
      if (message?.type === NotificationType.UserReportGenerated) {
        dispatch(getReportCounterThunk(companyId));
      }
    });
  }, [connection, companyId]);

  useEffect(() => {
    if (companyId && typeof companyId === 'string' && userRole === ROLE.CURATOR) {
      dispatch(getMonitoringCounterThunk(companyId));
    }
  }, [companyId, userRole]);

  return (
    <>
      {[ROLE.TECH_ACCOUNT, ROLE.USER_WITHOUT_WEB_ACCESS].includes(userRole) ? null : (
        <Navigation>
          <Box sx={{ width: '10rem', height: '2.5rem', flexShrink: '0' }}>
            <a href="/">
              <img src={logoWhite} alt="Logo" />
            </a>
          </Box>
          <a href="https://www.kaczmarskigroup.pl/" target="_blank" rel="noopener noreferrer" className={classes.link}>
            <img src={logoKaczmarski} alt="Kaczmarski Group" className={classes.kaczmarski} />
          </a>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER].includes(userRole) && (
              <Box sx={{ display: 'flex', fontSize: '1.1rem' }}>
                <ThemeProvider theme={tooltipTheme}>
                  <MuiTooltip arrow={true} title={'Liczba klientów aktualnie monitorowanych'} placement={'bottom'}>
                    <Stat onMouseEnter={() => setMonitoringHover(true)} onMouseLeave={() => setMonitoringHover(false)}>
                      <Box sx={{ height: '1.1rem' }}>
                        <MonitoringIcon color={monitoringHover ? '#FCB542' : '#fff'} />
                      </Box>
                      <StatCount>{monitoringCounter ? monitoringCounter : 0}</StatCount>
                    </Stat>
                  </MuiTooltip>
                </ThemeProvider>
                <ThemeProvider theme={tooltipTheme}>
                  <MuiTooltip
                    arrow={true}
                    title={'Liczba wszystkich raportów o kliencie pobranych w bieżącym miesiącu'}
                    placement={'bottom'}>
                    <Stat onMouseEnter={() => setReportHover(true)} onMouseLeave={() => setReportHover(false)}>
                      <Box sx={{ height: '1.1rem' }}>
                        <ReportIcon color={reportHover ? '#FCB542' : '#fff'} />
                      </Box>
                      <StatCount>{reportCounter.count ? reportCounter.count : 0}</StatCount>
                    </Stat>
                  </MuiTooltip>
                </ThemeProvider>
                {/* I'M NOT REMOVING IT, MAYBE SOMEDAY KG WILL WANT TO BRING IT BACK
                <ThemeProvider theme={tooltipTheme}>
                  <MuiTooltip
                    arrow={true}
                    title={'Liczba raportów pobranych w bieżącym miesiącu z raportu email z listą klientów'}
                    placement={'bottom'}>
                    <Stat onMouseEnter={() => setReportMailHover(true)} onMouseLeave={() => setReportMailHover(false)}>
                      <Box sx={{ height: '1.1rem' }}>
                        <ReportMailIcon color={reportMailHover ? '#FCB542' : '#fff'} />
                      </Box>
                      <StatCount>{reportCounter.fromLinkCount ? reportCounter.fromLinkCount : 0}</StatCount>
                    </Stat>
                  </MuiTooltip>
            </ThemeProvider>*/}
              </Box>
            )}
            <MenuButton onClick={handleClick} isOpen={isOpen}>
              <AccountCircleIcon
                fontSize="large"
                sx={{ marginLeft: '1.25rem', marginRight: '0.75rem', color: '#fff' }}
              />
              <UserName>
                {auth.userData?.profile?.FirstName} {auth.userData?.profile?.LastName}
                <Typography sx={{ display: 'flex', flexGrow: '1' }}>{showCuratorSelectedCompanyName()}</Typography>
              </UserName>
              <KeyboardArrowDownIcon sx={{ color: '#FFF' }} />
            </MenuButton>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              elevation={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{
                '& .MuiMenu-list': {
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}>
              {auth?.userData && (
                <MenuItem
                  sx={{ padding: '0.5rem 1rem' }}
                  onClick={() => {
                    handleClose();
                    logout(auth, connection);
                  }}
                  disableRipple>
                  Wyloguj
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Navigation>
      )}
    </>
  );
};
export default Nav;
