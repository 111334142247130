import { FIELDS, EXPORT_TYPES } from '../dataExportList.types';
import { ExportDataResultDto } from 'api/models';

export const createRows = (dataExport?: ExportDataResultDto[]) => {
  if (!dataExport) return [];

  return dataExport.map((result: ExportDataResultDto) => ({
    [FIELDS.TYPE]: result.type,
    [FIELDS.GENERATED_AT]: result.generatedAt,
    [FIELDS.REQUESTED_BY_USER]: result.requestedByUser,
    [FIELDS.STATUS]: result.status,
    [FIELDS.ID]: result.id,
    [FIELDS.FILENAME]: result.filename
  }));
};

export const parseType = (type: EXPORT_TYPES) => {
  if (type === EXPORT_TYPES.USER_LIST) {
    return 'Lista klientów';
  }
  if (type === EXPORT_TYPES.HISTORY_CHANGES) {
    return 'Historia zmian';
  }
  return '';
};
