import { ChangeEvent, FC, useEffect, useState, memo } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import MuiInput, { inputClasses } from '@mui/material/Input';
import { StyledTextField, Tooltip } from 'components';
import { CustomerSliderProps } from './RangeSlider.types';
import { CustomSlider } from 'components';
import { VALUE_LABEL } from 'components/CustomSlider/CustomSlider.types';

const Input = styled(MuiInput)`
  width: 100px;
  border: 0;
  font-size: 1rem;
  border: 1px #000 solid;
  border-radius: 3px;
  height: 40px;

  &.${inputClasses.underline} {
    border: 1px #e0e0e0 solid;
    border-radius: 3px;
    padding: 0 15px;

    &:before {
      display: none;
    }
  }
`;

const RangeSlider: FC<CustomerSliderProps> = memo(
  ({
    title,
    tooltipText,
    tooltipLink,
    value,
    isLast,
    maxValue,
    handleChangeCommitted,
    from,
    to,
    isInsideAnotherElement,
    onChangeCallback,
    isDisabled
  }) => {
    const [currentValue, setCurrentValue] = useState<(number | string)[]>(value);

    const parseCurrentValue = (currentValue: (number | string)[]) =>
      currentValue?.map((el) => (!el || typeof el === 'string' ? 0 : el));

    const handleSliderChange = (event: any) => {
      setCurrentValue(event.target.value);

      if (onChangeCallback) {
        onChangeCallback();
      }
    };

    const handleSliderDragStop = (event: any) => {
      const parsedCurrentValue = parseCurrentValue(currentValue);

      handleChangeCommitted(parsedCurrentValue, from, to);
    };

    const handleInputBlur = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
      index: number
    ) => {
      const value = Number(event.target.value);
      const values = parseCurrentValue([...currentValue]);
      let [firstValue, secondValue] = values;
      let curValue = Number(index ? secondValue : firstValue);
      firstValue = Number(firstValue);
      secondValue = Number(secondValue);

      if (value >= 0) {
        if (index === 0 && value >= secondValue) {
          curValue = secondValue > 0 ? secondValue - 1 : 0;
          values[index] = curValue;
          setCurrentValue(values);
          handleChangeCommitted(values, from, to);
        } else {
          let setValue = value > maxValue ? maxValue : value;
          setValue = secondValue < firstValue ? firstValue + 1 : setValue;
          if (setValue < firstValue) {
            values[0] = setValue < 0 ? 0 : setValue;
          }
          values[index] = setValue;
          setCurrentValue(values);
          handleChangeCommitted(values, from, to);
        }
      }
    };

    const handleEnter = (event: KeyboardEvent, index: number) => {
      if (['Enter', 'NumpadEnter'].includes(event.key)) {
        //@ts-ignore
        handleInputBlur(event, index);
      }
    };

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
      const value = event.target.value ? Number(event.target.value) : event.target.value;
      const values = [...currentValue];

      if (value === '') {
        values[index] = '';
        setCurrentValue(values);
      }

      if (value >= 0) {
        values[index] = value;
        setCurrentValue(values);
      }
    };

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const handleInputFocus = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
      index: number
    ) => {
      const value = event.target.value ? Number(event.target.value) : event.target.value;
      const values = [...currentValue];
      if (onChangeCallback) {
        onChangeCallback();
      }

      if (!value) {
        values[index] = '';
        setCurrentValue(values);
      }
    };

    return (
      <Box sx={{ display: 'block', width: '100%' }}>
        <Box
          sx={{
            borderBottom: isLast ? 'none' : isInsideAnotherElement ? 'none' : '1px solid #E0E0E0',
            padding: isInsideAnotherElement ? '0' : '1rem 1.5rem'
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              maxWidth: 'fit-content'
            }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography variant="subtitle2">{title}</Typography>
                {tooltipText ? <Tooltip text={tooltipText} link={tooltipLink} /> : null}
              </Box>
              <Box sx={{ padding: '0.2rem .2rem 0.2rem 0.4rem', display: 'flex', width: '100%' }}>
                <CustomSlider
                  name={title}
                  title={title}
                  value={parseCurrentValue(currentValue)}
                  valueLabelDisplay={VALUE_LABEL.AUTO}
                  maxValue={maxValue}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderDragStop}
                  isDisabled={isDisabled}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Box sx={{ maxWidth: '45%' }}>
                <StyledTextField
                  disabled={isDisabled}
                  type="number"
                  value={currentValue?.[0] ?? ''}
                  onFocus={(e) => handleInputFocus(e, 0)}
                  onChange={(e) => handleInputChange(e, 0)}
                  onBlur={(e) => handleInputBlur(e, 0)}
                  //@ts-ignore
                  onKeyDown={(e) => handleEnter(e, 0)}
                  size="small"
                />
              </Box>
              -
              <Box sx={{ maxWidth: '45%' }}>
                <StyledTextField
                  disabled={isDisabled}
                  type="number"
                  value={currentValue?.[1] ?? ''}
                  onFocus={(e) => handleInputFocus(e, 1)}
                  onChange={(e) => handleInputChange(e, 1)}
                  onBlur={(e) => handleInputBlur(e, 1)}
                  //@ts-ignore
                  onKeyDown={(e) => handleEnter(e, 1)}
                  size="small"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default RangeSlider;
