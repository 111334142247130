import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Breadcrumbs, Loader } from 'components';
import { PATH } from 'router/routes';
import { updateUser, getUser } from 'api/services';
import { UserDto } from 'api/models';
import { useFetch } from 'lib/hooks';
import { UserForm, UserFormTypes } from '../shared';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { ROLE_MAP } from 'config/oidc';
import { Helmet } from 'react-helmet-async';

const EditUser = () => {
  const { id } = useParams();

  const { result, isLoading } = useFetch<UserDto>(useCallback(() => getUser(id || ''), [id]));
  const breadcrumbs = [{ to: PATH.USERS_LIST, label: 'Użytkownicy i uprawnienia' }, { label: result?.fullName || '' }];

  const valuesForForm: UserFormTypes.FormikValues = {
    [UserFormTypes.FIELDS.FIRST_NAME]: result?.firstName || '',
    [UserFormTypes.FIELDS.LAST_NAME]: result?.lastName || '',
    [UserFormTypes.FIELDS.EMAIL]: result?.email || '',
    [UserFormTypes.FIELDS.PHONE]: result?.phone || '',
    [UserFormTypes.FIELDS.COMPANY_ID]: result?.companyId || '',
    [UserFormTypes.FIELDS.ROLE]: result?.role || 0,
    [UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION]: !!result?.hasClientMonitoringPermission,
    [UserFormTypes.FIELDS.HAS_REPORT_PERMISSION]: !!result?.hasClientReportPermission,
    [UserFormTypes.FIELDS.HAS_CSV_PERMISSION]: !!result?.hasCsvPermission,
    [UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]: result?.role !== ROLE_MAP.TechAccount
  };

  const handleSubmit = useCallback((values: UserFormTypes.FormikValues) => updateUser(id || '', values), [id]);
  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>
          RiskRadar - {result?.firstName ?? ''} {result?.lastName ?? ''}
        </title>
      </Helmet>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Loader isLoading={isLoading}>
          <UserForm inEditMode initialValues={valuesForForm} submitHandler={handleSubmit} />
        </Loader>
      </Paper>
    </Box>
  );
};

export default EditUser;
