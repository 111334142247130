import { FC } from 'react';
import { styled } from '@mui/system';
import { sliderUnstyledClasses } from '@mui/base/SliderUnstyled';
import Slider from '@mui/material/Slider';
import { Props } from './CustomSlider.types';

const Root = styled('span')`
  border-radius: 27px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 300px;
  color: rgb(46, 125, 50);

  &.${sliderUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${sliderUnstyledClasses.track} {
    background: #078a51;
    border-radius: 27px;
    display: block;
    height: 4px;
    width: 100%;
    position: absolute;
    border: 1px solid #078a51;
  }

  & .${sliderUnstyledClasses.rail} {
    display: block;
    position: absolute;
    border-radius: inherit;
    background-color: #078a51;
    opacity: 0.38;
    width: 100%;
    height: inherit;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  & .${sliderUnstyledClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 15pxpx;
    left: -7px;
    border-radius: 16px;
    background-color: #fff;
    border: 3px solid #078a51;
    position: absolute;
    transition: all 200ms ease;
  }
`;

const CustomSlider: FC<Props> = ({ value, name, maxValue, onChange, onChangeCommitted, isDisabled }) => {
  const props = {
    value,
    min: 0,
    max: maxValue,
    name: name,
    onChange,
    onChangeCommitted
  };
  return <Slider component={Root} {...props} disabled={isDisabled} />;
};

export default CustomSlider;
