import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Props, initialValues, FIELDS, newReportSchema } from './NewReportDialog.types';
import { Dialog } from 'components';
import { useDispatch } from 'react-redux';
import { useFetch, useSendRequest } from 'lib/hooks';
import { ApiResponse, ClientAutocompleteQueryResultDto } from 'api/models';
import { delay } from 'lib/utils';
import {
  addReportRequest,
  checkIfCounterpartyExist,
  createCustomer,
  getClientAutocomplete,
  getReportFile
} from 'api/services';
import { reportCounterActions } from 'store/reportCounter';
import { Box, FormControl, TextField } from '@mui/material';
import { TextField as CustomTextField } from 'components';
import { useFormik } from 'formik';
import Autocomplete from 'components/Autocomplete';
import { AutocompleteDto } from 'api/models/AutocompleteDto';

const NewReportDialog: FC<Props> = ({ isOpen, companyId, closeNewReportModal, getReportsData, query }) => {
  const [currentTaxNumber, setCurrentTaxNumber] = useState('');
  const [currentClientName, setCurrentClientName] = useState('');
  const [taxNumberStatus, setTaxNumberStatus] = useState<any>('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [inputValue, setInputValue] = useState<AutocompleteDto | null>(null);

  const dispatch = useDispatch();

  const {
    result: newCustomerResult,
    validationErrors,
    sendData: createNewCustomer,
    setValidationErrors,
    clearResult: clearNewCustomer,
    isLoading: createNewCustomerIsLoading
  } = useSendRequest();
  const {
    result: generateReportRequestResult,
    sendData: sendGenerateReportRequest,
    clearResult: clearGeneratorReport,
    isLoading: generateReportsIsLoading
  } = useSendRequest();
  const {
    result,
    validationErrors: taxNumberError,
    sendData: sendRequest,
    clearResult: clearCheckCounterparty,
    isLoading: checkCounterpartyIsLoading
  } = useSendRequest();

  const formik = useFormik({
    validationSchema: newReportSchema,
    enableReinitialize: false,
    initialValues: initialValues,
    onSubmit: () => {}
  });

  useEffect(() => {
    if (newCustomerResult || (result === 'true' && !isLoading)) {
      generateReport(currentTaxNumber);
      setCurrentTaxNumber('');
      setCurrentClientName('');
      setTaxNumberStatus('');
      setOpen(false);
    }
  }, [newCustomerResult, result, isLoading]);

  useEffect(() => {
    if (!open) {
      clearGeneratorReport();
      clearCheckCounterparty();
      clearNewCustomer();
      formik.resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      localStorage.removeItem('showNewReportModal');
    }
  }, [isOpen]);

  useEffect(() => {
    const generateReportRequest = async (da: any) => {
      const data = da as ApiResponse<number>;
      await delay(90000);
      const getData = await getReportFile(String(data));
      if (getData.status === 200) {
        dispatch({ type: reportCounterActions.increment });
      }
    };

    if (generateReportRequestResult) {
      generateReportRequest(generateReportRequestResult);
    }
  }, [generateReportRequestResult]);

  const generateReport = async (taxNumber: string) => {
    if ((!acceptDisabled || taxNumberStatus !== 'false') && taxNumber) {
      sendGenerateReportRequest(
        () => addReportRequest(companyId, taxNumber),
        () => getReportsData(query)
      );
      closeNewReportModal();
      localStorage.removeItem('showNewReportModal');
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    closeNewReportModal();
    setTaxNumberStatus('');
    setCurrentTaxNumber('');
    setCurrentClientName('');
    setOpen(false);
    localStorage.removeItem('showNewReportModal');
  };

  useEffect(() => {
    setTaxNumberStatus(result);
  }, [taxNumberError, result]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentTaxNumber(value);
    setOpen(true);
    if (value === '') {
      setOpen(false);
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentClientName(value);
  };

  const { result: autocompleteResult } = useFetch<ClientAutocompleteQueryResultDto>(
    useCallback(async () => {
      if (searchPhrase && searchPhrase !== '') return await getClientAutocomplete(searchPhrase ?? '');
      return null!;
    }, [searchPhrase])
  );

  const autocompleteOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = event.target.value;

    if (currentValue.length >= 3) {
      setSearchPhrase(event.target.value);
    } else {
      setSearchPhrase('');
    }
  };

  const autocompleteOnOptionSelected = async (company: AutocompleteDto | null) => {
    await formik.setFieldValue(FIELDS.CLIENT_NAME, company?.name ?? '');
    await formik.setFieldTouched(FIELDS.CLIENT_NAME);
    setCurrentClientName(company?.name ?? '');
    setCurrentTaxNumber(company?.taxIdentificationNumber ?? '');
  };

  const acceptDisabled = useMemo(() => {
    return isLoading ||
      (taxNumberStatus === 'false' && generateReportsIsLoading) ||
      (taxNumberStatus === 'false' && createNewCustomerIsLoading) ||
      (taxNumberStatus !== 'false' && checkCounterpartyIsLoading) ||
      taxNumberStatus === 'false'
      ? currentClientName && !(formik.touched[FIELDS.CLIENT_NAME] && !!formik.errors[FIELDS.CLIENT_NAME])
        ? false
        : true
      : currentTaxNumber
      ? false
      : true;
  }, [
    currentClientName,
    currentTaxNumber,
    formik.errors,
    formik.touched,
    isLoading,
    taxNumberStatus,
    checkCounterpartyIsLoading,
    createNewCustomerIsLoading,
    generateReportsIsLoading
  ]);

  return (
    <Dialog
      dialogMaxWidth="md"
      dialogActionsWidth="100%"
      dialogActionsJustify="space-between"
      isOpen={isOpen}
      acceptDisabled={acceptDisabled}
      closeModal={closeModal}
      accept={() => {
        if (taxNumberStatus === 'false' && !isLoading) {
          setIsLoading(true);
          createNewCustomer(() =>
            createCustomer({
              TaxIdentificationNumber: currentTaxNumber,
              Name: currentClientName,
              CompanyId: companyId
            })
          );
        } else {
          sendRequest(() => checkIfCounterpartyExist(companyId, currentTaxNumber));
        }
      }}
      acceptLabel={taxNumberStatus === 'false' ? 'Dodaj klienta i pobierz raport' : 'Pobierz raport'}
      closeLabel="Anuluj">
      <Box
        sx={{
          display: 'flex',
          margin: '0 0 1rem 0',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '23px',
          letterSpacing: '0.01em',
          color: '#000',
          justifyContent: 'center'
        }}>
        Pobieranie raportu
      </Box>
      <Box style={{ width: '45rem' }}>
        {taxNumberStatus === 'false'
          ? 'Klient o podanym NIP nie istnieje w RiskRadar. Uzupełnij nazwę klienta, aby dodać go do RiskRadar i od razu pobrać o nim raport.'
          : 'Działanie to spowoduje pobranie aktualnego raportu o kliencie oraz naliczenie usługi.'}
      </Box>
      <Box
        sx={{
          paddingTop: '35px',
          paddingBottom: '5px',
          width: '45rem'
        }}>
        <Box>Na liście raportów pojawi się nowa pozycja. Kliknięcie w nią spowoduje otworzenie raportu.</Box>
      </Box>
      <Box sx={{ margin: '1rem 0 0 0' }}>
        <FormControl fullWidth sx={{ justifyContent: 'center' }}>
          {taxNumberStatus !== 'false' && (
            <Autocomplete
              outerBoxSx={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                position: 'relative'
              }}
              setInputValue={setInputValue}
              inputValue={inputValue}
              label="Wyszukaj klienta po NIP lub nazwie"
              key="company_autocomplete"
              onChangeHandler={autocompleteOnChangeHandler}
              autofocus={true}
              onOptionSelected={autocompleteOnOptionSelected}
              companies={autocompleteResult?.results}
            />
          )}
          <TextField
            value={currentTaxNumber}
            error={taxNumberError?.length > 0 ? true : false}
            helperText={taxNumberError?.length > 0 ? taxNumberError[0].ErrorMessage : ''}
            color={taxNumberError?.length > 0 ? 'error' : 'success'}
            label={currentTaxNumber || open ? 'NIP' : 'Wprowadź NIP'}
            disabled={taxNumberStatus === 'false' ? true : false}
            onChange={onBlur}
            onBlur={onBlur}
            onClick={() => setOpen(true)}
            sx={{
              width: '100%',
              marginTop: '1.25rem',
              color: 'rgba(0, 0, 0, 0.87)'
            }}
          />
          {taxNumberStatus === 'false' && (
            <CustomTextField
              field={{
                name: FIELDS.CLIENT_NAME,
                label: 'Nazwa klienta',
                value: formik.values[FIELDS.CLIENT_NAME],
                error: formik.touched[FIELDS.CLIENT_NAME] && !!formik.errors[FIELDS.CLIENT_NAME],
                helperText: formik.touched[FIELDS.CLIENT_NAME] && formik.errors[FIELDS.CLIENT_NAME]
              }}
              validationErrors={validationErrors}
              onChangeHandler={(event) => {
                formik.handleChange(event);
                onNameChange(event);
              }}
              onBlurHandler={formik.handleBlur}
              errorsSetter={setValidationErrors}
            />
          )}
        </FormControl>
      </Box>
    </Dialog>
  );
};

export default NewReportDialog;
