import { combineReducers } from 'redux';
import { reducer as companyReducer } from './company';
import { reducer as reportCounterReducer } from './reportCounter';
import { reducer as monitoringCounterReducer } from './monitoringCounter';
// TODO: #989
// import { reducer as owuReducer } from './owu';
import { reducer as errorsReducer } from './errors';
import { reducer as successReducer } from './success';
import connectionSlice from './connection';

const rootReducer = combineReducers({
  company: companyReducer,
  reportCounter: reportCounterReducer,
  monitoringCounter: monitoringCounterReducer,
  // TODO: #989
  // owu: owuReducer,
  errors: errorsReducer,
  success: successReducer,
  connection: connectionSlice
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
