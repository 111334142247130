import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { PATH } from 'router/routes';
import HomeIcon from '@mui/icons-material/Home';
import { Props, Breadcrumb } from './Breadcrumbs.types';

const createBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
  return breadcrumbs.map((breadcrumb, index) => {
    if (index === breadcrumbs.length - 1) {
      return (
        <Typography key={index} variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {breadcrumb.label}
        </Typography>
      );
    }

    return (
      <Link key={index} to={breadcrumb.to || '/'}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            {breadcrumb.label}
          </Typography>
        </Box>
      </Link>
    );
  });
};

const Breadcrumbs: FC<Props> = memo(({ breadcrumbs }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        padding: '1rem 1.5rem'
      }}>
      <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
        <Link to={PATH.ROOT}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <HomeIcon
              fontSize="large"
              color="disabled"
              sx={{
                background: '#E0E0E0',
                borderRadius: '50%',
                padding: '0.75rem',
                width: '2.625rem',
                height: '2.625rem'
              }}
            />
            <Typography variant="body1" color="action" sx={{ marginLeft: '1rem', color: 'rgba(0, 0, 0, 0.87)' }}>
              Home
            </Typography>
          </Box>
        </Link>
        {createBreadcrumbs(breadcrumbs)}
      </MuiBreadcrumbs>
    </Box>
  );
});

export default Breadcrumbs;
