import { Box, createTheme, styled, SvgIconTypeMap, Tooltip } from '@mui/material';
import { FC } from 'react';
import { Props } from './CsvStatus.types';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ThemeProvider } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IMPORT_STATUS } from '../csvList.types';
import { Colors } from './CsvAction.types';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const Root = styled('div')`
  margin: 0 0 0 1rem;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: '#1367E5'
    },
    secondary: {
      main: '#EA9617'
    },
    error: {
      main: '#F51B42'
    },
    success: {
      main: '#29A745'
    },
    info: {
      main: '#078A51'
    }
  }
});

const waitingText = 'Oczekujący';
const tooltipWaitingText =
  'Trwa import danych. Może to potrwać kilka minut. Po tym czasie odśwież stronę aby sprawdzić status importu.';

const CsvStatus: FC<Props> = ({ status, id }) => {
  const boxWithTooltip = (
    text: string,
    tooltipText: string,
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
    iconColor: Colors
  ) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', whiteSpace: 'nowrap' }}>
        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            alignItems: 'center'
          }}>
          <Tooltip title={tooltipText}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon color={iconColor} />
              <Box sx={{ color: '#1367E5' }} component={Root}>
                {text}
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  let csvStatus = null;

  switch (status) {
    case IMPORT_STATUS.WAITING:
      csvStatus = boxWithTooltip(waitingText, tooltipWaitingText, HourglassEmptyIcon, 'primary');
      break;
    case IMPORT_STATUS.PROCESSING:
      csvStatus = (
        <>
          <MoreHorizIcon color="secondary" />{' '}
          <Box sx={{ color: '#EA9617' }} component={Root}>
            W toku
          </Box>
        </>
      );
      break;
    case IMPORT_STATUS.COMPLETED:
      csvStatus = (
        <>
          <DoneIcon color="success" /> <Box component={Root}>Zakończony</Box>
        </>
      );
      break;
    case IMPORT_STATUS.COMPLETED_WITH_ERRORS:
      csvStatus = (
        <>
          <WarningAmberIcon color="secondary" /> <Box component={Root}>Zakończony</Box>
        </>
      );
      break;
    case IMPORT_STATUS.FILE_ERROR:
      csvStatus = (
        <>
          <WarningAmberIcon color="error" />
          <Box component={Root} sx={{ color: '#d32f2f' }}>
            Błędny
          </Box>
        </>
      );
      break;
    case IMPORT_STATUS.ERROR:
      csvStatus = (
        <>
          <WarningAmberIcon color="error" />
          <Box component={Root} sx={{ color: '#d32f2f' }}>
            Błędny
          </Box>
        </>
      );
      break;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box id={`csv-list-status-${id}`} sx={{ display: 'flex', alignItems: 'center' }}>
        {csvStatus}
      </Box>
    </ThemeProvider>
  );
};

export default CsvStatus;
