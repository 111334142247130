import { HeadCell } from 'types';
import { FIELDS } from '../usersList.types';

export const headCells: HeadCell<FIELDS>[] = [
  {
    id: FIELDS.FULL_NAME,
    numeric: false,
    disablePadding: true,
    label: 'Użytkownik',
    sortable: true
  },
  {
    id: FIELDS.ROLE,
    numeric: false,
    disablePadding: true,
    label: 'Rola',
    sortable: true
  },
  {
    id: FIELDS.COMPANY_NAME,
    numeric: false,
    disablePadding: true,
    label: 'Firma',
    sortable: true
  },
  {
    id: FIELDS.TAX_NUMBER,
    numeric: false,
    disablePadding: true,
    label: 'NIP',
    sortable: true
  },
  {
    id: FIELDS.INTEGRATOR_NAME,
    numeric: false,
    disablePadding: true,
    label: 'Integrator',
    sortable: true
  },
  {
    id: FIELDS.ERP_NAME,
    numeric: false,
    disablePadding: true,
    label: 'ERP',
    sortable: true
  },
  {
    id: FIELDS.ACTION,
    numeric: true,
    disablePadding: true,
    label: 'Akcja',
    sortable: false
  },
  {
    id: FIELDS.IS_ACTIVE,
    numeric: true,
    disablePadding: true,
    label: 'Konto aktywne',
    sortable: false
  }
];

export const initialValues = {
  [FIELDS.FULL_NAME]: '',
  [FIELDS.COMPANY_NAME]: '',
  [FIELDS.TAX_NUMBER]: '',
  [FIELDS.INTEGRATOR_NAME]: '',
  [FIELDS.ERP_NAME]: '',
  [FIELDS.COMPANY_ID]: '',
  [FIELDS.INTEGRATOR_ID]: '',
  [FIELDS.COMPANY_ROLE]: '',
  forceRender: false
};

export enum TOOLTIPS {
  COMPANY = 'Firma to podmiot, który wykupił dostęp do RiskRadar.',
  INTEGRATOR = 'Integrator to podmiot, który wykonuje oraz utrzymuje integrację RiskRadar z ERPem firmy.',
  ROLE = 'Użytkownik firmy to osoba, która korzysta z RiskRadar.'
}
