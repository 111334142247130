import { FC, memo } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { TableHeadProps } from './TableHead.types';
import { CUSTOMER_FIELDS } from '../customersList.types';
import { StyledCheckbox } from 'components';

const TableHead: FC<TableHeadProps> = memo(({ order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property: CUSTOMER_FIELDS) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells
          .filter(
            (item) =>
              !(
                (item?.userRole &&
                  !!item?.preventDisplay &&
                  item?.roles &&
                  ![...item?.roles].includes(item?.userRole)) ||
                item?.preventDisplay === true
              )
          )
          .map((headCell) => (
            <TableCell
              colSpan={headCell?.colSpan}
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                position: 'relative',
                padding: headCell?.callback ? '1rem 0 1rem .5rem' : '1rem .5rem 1rem 1.5rem',
                whiteSpace: 'nowrap',
                textAlign: headCell?.textAlign,
                width: headCell?.width ? headCell?.width : 'auto'
              }}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={headCell.sortable && headCell.id ? createSortHandler(headCell.id) : undefined}
                hideSortIcon={!headCell.sortable}
                sx={{
                  pointerEvents: headCell.sortable ? 'all' : 'none',
                  position: 'relative'
                }}>
                <Box sx={{ position: 'relative' }}>
                  {headCell.label}
                  <Box sx={{ position: 'absolute', top: '20px', left: '50%', transform: 'translate(-50%, 0)' }}>
                    {headCell.showCurrency ? '(PLN)' : ''}
                  </Box>
                </Box>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              {headCell?.callback ? (
                <StyledCheckbox
                  checked={headCell?.checked}
                  onChange={(e) => (headCell.callback ? headCell.callback(e) : '')}
                />
              ) : (
                ''
              )}
            </TableCell>
          ))}
      </TableRow>
    </MuiTableHead>
  );
});

export default TableHead;
