import { TradeCreditLimitAuthorizationErrorState } from 'api/models';

export type SelectOption = {
  id: string;
  value: string;
};

export enum PAYMENT_CREDIBILITY {
  NotDebtor = 0,
  Debtor = 1,
  Both = 2
}

export enum MONITOR_CLIENT {
  Off = 0,
  On = 1,
  Both = 2
}

export enum RADIOGROUP_VALUES {
  ALL = 0,
  NONE = 5,
  DAYS_7 = 1,
  DAYS_30 = 2,
  DAYS_60 = 3,
  DAYS_90 = 4,
  YESTERDAY = 6
}

export enum RADIOGROUP_CHANGE_CREDIBILITY_VALUES {
  ALL = 0,
  NONE = 5,
  DAYS_7 = 1,
  DAYS_30 = 2,
  DAYS_60 = 3,
  DAYS_90 = 4,
  TODAY = 7
}

export enum TRADE_CREDIT_LIMIT_FILTER_VALUES {
  NOT_SET = '0',
  VALUE = '1'
}

export enum RISKGROUP_CODE {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  X = 'X'
}

export enum ACTION_TEXT_OPTIONS {
  Enable = 'Włącz monitorowanie',
  Disable = 'Wyłącz monitorowanie'
}

export enum CUSTOMER_FIELDS {
  NAME = 'Name',
  TAX_NUMBER = 'taxIdentificationNumber',
  MONITORING_STATE = 'monitoringState',
  MONITORING_ID = 'monitoringId',
  IS_MONITORING_RETRY = 'isMonitoringRetry',
  LAST_MONITORING_EVENT_DATE = 'lastMonitoringEventDate',
  DEBT_AMOUNT_PLN = 'debtAmountPln',
  REPORT = 'Report',
  STATE = 'state',
  REPORT_GENERETED_AT = 'reportGeneretedAt',
  ACTION = 'action',
  MONITOR_CLIENT = 'MonitorClient',
  MONITOR_CLIENT_ENABLED = 'MonitorClientEnabled',
  MONITOR_CLIENT_DISABLED = 'MonitorClientDisabled',
  DEBT_VALUE_FROM = 'DebtValueFrom',
  DEBT_VALUE_TO = 'DebtValueTo',
  PAYMENT_CREDIBILITY = 'PaymentCredibility',
  PAYMENT_CREDIBILITY_DEBTOR = 'PaymentCredibilityDebtor',
  PAYMENT_CREDIBILITY_NON_DEBTOR = 'PaymentCredibilityNonDebtor',
  COMPANY_ID = 'CompanyId',
  SCORING = 'scoring',
  LAST_DEBT = 'LastDebt',
  LAST_EVENT = 'LastEvent',
  RISK_GROUP = 'riskGroup',
  RISK_GROUP_CODE = 'riskGroupCode',
  SCORING_ID = 'scoringId',
  GROSS_SALE_LAST_12_MONTHS_FROM = 'GrossSaleLast12MonthsFrom',
  GROSS_SALE_LAST_12_MONTHS_TO = 'GrossSaleLast12MonthsTo',
  GROSS_DEBT_FROM = 'GrossDebtFrom',
  GROSS_DEBT_TO = 'GrossDebtTo',
  RISK_GROUP_CODES = 'RiskGroupCodes',
  RISK_GROUP_IDS = 'RiskGroupIds',
  GROSS_SALE_LAST_12_MONTHS = 'GrossSaleLast12Months',
  GROSS_DEBT = 'GrossDebt',
  IS_IMPORT_OUTDATED = 'isImportOutdated',
  IMPORT_DATE = 'importDate',
  SCORING_STATUS = 'scoringStatus',
  SCORING_STATUS_CODE = 'scoringStatusCode',
  SCORING_STATE = 'scoringState',
  MONITORING_END_DATE = 'monitoringEndDate',
  DESCRIPTION1 = 'description1',
  DESCRIPTION2 = 'description2',
  TRADE_CREDIT_LIMIT = 'tradeCreditLimit',
  TRADE_CREDIT_LIMIT_AUTHORIZATION_ERROR_STATE = 'tradeCreditLimitAuthorizationErrorState',
  TRADE_CREDIT_LIMIT_VALUE = 'tradeCreditLimitValue',
  TRADE_CREDIT_LIMIT_MODEL = 'tradeCreditLimitModel',
  TRADE_CREDIT_LIMIT_STATUS = 'tradeCreditLimitStatus',
  TRADE_CREDIT_LIMIT_CHECKBOX_VALUE = 'TradeCreditLimitCheckboxValue',
  TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM = 'TradeCreditLimitCheckboxValueFrom',
  TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO = 'TradeCreditLimitCheckboxValueTo',
  CHANGE_CREDIBILITY = 'ChangeCredibility',
  CHANGE_LIMIT = 'ChangeLimit'
}

export enum ACTION_TYPES {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export type CustomerFieldType = {
  name: CUSTOMER_FIELDS | '' | 'separator';
  value: string;
  error?: boolean;
  helperText?: string | false;
  colSpan?: number;
  width?: number;
  callback?: () => void;
  separatorValue?: number;
};

export type CustomerFormikValues = {
  [CUSTOMER_FIELDS.NAME]: string;
  [CUSTOMER_FIELDS.TAX_NUMBER]: string;
  [CUSTOMER_FIELDS.MONITORING_STATE]: string;
  [CUSTOMER_FIELDS.MONITORING_ID]: string;
  [CUSTOMER_FIELDS.LAST_MONITORING_EVENT_DATE]: string;
  [CUSTOMER_FIELDS.DEBT_AMOUNT_PLN]: string;
  [CUSTOMER_FIELDS.REPORT]: boolean;
  [CUSTOMER_FIELDS.REPORT_GENERETED_AT]: string;
  [CUSTOMER_FIELDS.STATE]: string | number;
  [CUSTOMER_FIELDS.DEBT_VALUE_FROM]: number;
  [CUSTOMER_FIELDS.DEBT_VALUE_TO]: number;
  [CUSTOMER_FIELDS.MONITOR_CLIENT]: number;
  [CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED]: boolean;
  [CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED]: boolean;
  [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY]: number;
  [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_DEBTOR]: boolean;
  [CUSTOMER_FIELDS.PAYMENT_CREDIBILITY_NON_DEBTOR]: boolean;
  [CUSTOMER_FIELDS.COMPANY_ID]: string;
  [CUSTOMER_FIELDS.SCORING]: string;
  [CUSTOMER_FIELDS.LAST_DEBT]: number;
  [CUSTOMER_FIELDS.LAST_EVENT]: number;
  [CUSTOMER_FIELDS.GROSS_DEBT]: number;
  [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS]: number;
  [CUSTOMER_FIELDS.IS_IMPORT_OUTDATED]: boolean;
  [CUSTOMER_FIELDS.IMPORT_DATE]: string;
  [CUSTOMER_FIELDS.MONITORING_END_DATE]?: Date | string;
  [CUSTOMER_FIELDS.DESCRIPTION1]: string;
  [CUSTOMER_FIELDS.DESCRIPTION2]: string;
  [CUSTOMER_FIELDS.RISK_GROUP_CODES]: string;
  [CUSTOMER_FIELDS.RISK_GROUP_IDS]: string;
  [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_FROM]: number;
  [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS_TO]: number;
  [CUSTOMER_FIELDS.GROSS_DEBT_FROM]: number;
  [CUSTOMER_FIELDS.GROSS_DEBT_TO]: number;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT]: string;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_AUTHORIZATION_ERROR_STATE]: TradeCreditLimitAuthorizationErrorState;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_VALUE]: number;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_MODEL]: string;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_STATUS]: string;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE]: TRADE_CREDIT_LIMIT_FILTER_VALUES[];
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_FROM]: number;
  [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE_TO]: number;
  [CUSTOMER_FIELDS.CHANGE_CREDIBILITY]: number;
  [CUSTOMER_FIELDS.CHANGE_LIMIT]: number;
};

export type checkboxGroupType = {
  setFieldValue: CUSTOMER_FIELDS;
  firstField: CUSTOMER_FIELDS;
  secondField: CUSTOMER_FIELDS;
  value: number;
  secondValue: number;
  label: string;
};

export type lastEventType = {
  setFieldValue: CUSTOMER_FIELDS;
  value: number;
  label: string;
};

export type riskGroupType<T> = {
  setFieldValue: T;
  value: string;
  label: string;
};

export type actionType = {
  id: string;
  value: string;
};

export const actionOptions: actionType[] = [
  { id: '1', value: 'Włącz monitorowanie' },
  { id: '0', value: 'Wyłącz monitorowanie' }
];

export type CounterPartiesType = {
  taxNumber: string;
  retry: number;
};

export const changeCredibilityRadio: lastEventType[] = [
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.TODAY,
    label: 'dzisiaj'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_7,
    label: 'do 7 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_30,
    label: 'do 30 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_60,
    label: 'do 60 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_90,
    label: 'do 90 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.NONE,
    label: 'brak zmiany'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_CREDIBILITY,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.ALL,
    label: 'wszystkie'
  }
];

export const changeLimitRadio: lastEventType[] = [
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.TODAY,
    label: 'dzisiaj'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_7,
    label: 'do 7 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_30,
    label: 'do 30 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_60,
    label: 'do 60 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.DAYS_90,
    label: 'do 90 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.NONE,
    label: 'brak zmiany'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.CHANGE_LIMIT,
    value: RADIOGROUP_CHANGE_CREDIBILITY_VALUES.ALL,
    label: 'wszystkie'
  }
];

export const lastAddedDebt: lastEventType[] = [
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.YESTERDAY,
    label: 'wczoraj'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_DEBT,
    value: RADIOGROUP_VALUES.DAYS_7,
    label: 'do 7 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_DEBT,
    value: RADIOGROUP_VALUES.DAYS_30,
    label: 'do 30 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_DEBT,
    value: RADIOGROUP_VALUES.DAYS_60,
    label: 'do 60 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_DEBT,
    value: RADIOGROUP_VALUES.DAYS_90,
    label: 'do 90 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_DEBT,
    value: RADIOGROUP_VALUES.NONE,
    label: 'brak zdarzeń'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_DEBT,
    value: RADIOGROUP_VALUES.ALL,
    label: 'wszystkie'
  }
];

export const lastEvent: lastEventType[] = [
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.YESTERDAY,
    label: 'wczoraj'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.DAYS_7,
    label: 'do 7 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.DAYS_30,
    label: 'do 30 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.DAYS_60,
    label: 'do 60 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.DAYS_90,
    label: 'do 90 dni'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.NONE,
    label: 'brak zdarzeń'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.LAST_EVENT,
    value: RADIOGROUP_VALUES.ALL,
    label: 'wszystkie'
  }
];

export const tradeCreditLimitFilters = [
  {
    setFieldValue: CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE,
    value: TRADE_CREDIT_LIMIT_FILTER_VALUES.NOT_SET,
    label: 'Limit nie został wyznaczony'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_CHECKBOX_VALUE,
    value: TRADE_CREDIT_LIMIT_FILTER_VALUES.VALUE,
    label: 'Wysokość limitu kupieckiego'
  }
];

export const paymentCredibility: riskGroupType<CUSTOMER_FIELDS.RISK_GROUP_CODES>[] = [
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.A,
    label: 'A'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.B,
    label: 'B'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.C,
    label: 'C'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.D,
    label: 'D'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.E,
    label: 'E'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.F,
    label: 'F'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.G,
    label: 'G'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.H,
    label: 'H'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.RISK_GROUP_CODES,
    value: RISKGROUP_CODE.X,
    label: 'Brak kategorii'
  }
];

export const monitorClientCheckboxes: checkboxGroupType[] = [
  {
    setFieldValue: CUSTOMER_FIELDS.MONITOR_CLIENT,
    firstField: CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED,
    secondField: CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED,
    value: 1,
    secondValue: 0,
    label: 'Włączone'
  },
  {
    setFieldValue: CUSTOMER_FIELDS.MONITOR_CLIENT,
    firstField: CUSTOMER_FIELDS.MONITOR_CLIENT_DISABLED,
    secondField: CUSTOMER_FIELDS.MONITOR_CLIENT_ENABLED,
    value: 0,
    secondValue: 1,
    label: 'Wyłączone'
  }
];

export enum TRADE_CREDIT_LIMIT_FILTER {
  None = 0,
  NotCalculated = 1,
  Calculated = 2,
  Both = 3
}

export enum TRADE_CREDIT_LIMIT_OPTIONS {
  NotCalculated = '0',
  Calculated = '1'
}
