import { StyledCheckbox } from 'components';
import Box from '@mui/material/Box';
import { createTheme, FormControlLabel } from '@mui/material';
import { memo, FC } from 'react';
import { FIELDS, RecipientsListProps } from '../types';
import Recipents from './Recipents';
import { ReportUserDto } from 'api/models';

export const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }
});

const RecipientsList: FC<RecipientsListProps> = memo(
  ({ searchValue, setSearchValue, checkAll, users, recipientsValue, multiCheckboxHandler, disabled }) => {
    return (
      <Box sx={{ display: 'flex', paddingBottom: '2.25rem' }}>
        <Box sx={{ padding: '0 1.5rem', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ fontSize: '20px', marginTop: '20px', margin: '20px 0' }}>Wybór odbiorców raportu</Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                border: '1px solid #C4C4C4',
                borderRadius: '5px',
                justifyContent: 'space-between'
              }}
              key={'search'}>
              <Box sx={{ padding: '5px', position: 'relative' }}>
                <Box
                  sx={{
                    color: '#666',
                    fontSize: '12px',
                    background: '#fff',
                    padding: '5px',
                    position: 'absolute',
                    left: '10px',
                    transform: 'translateY(-17px)'
                  }}>
                  Szukaj
                </Box>
                <input
                  disabled={disabled}
                  type="text"
                  value={searchValue}
                  placeholder={''}
                  onChange={(event) => setSearchValue(event.target.value)}
                  style={{ border: 'unset', width: '100%', height: '20px', outline: 'none', padding: '20px 10px' }}
                />
              </Box>
              <Box
                sx={{
                  borderTop: '1px solid #C4C4C4',
                  borderBottom: '1px solid #C4C4C4',
                  padding: '1rem',
                  color: '#666666'
                }}>
                <Box sx={{ width: '100%' }}>
                  <FormControlLabel
                    onChange={(event) =>
                      checkAll(event, FIELDS.RECIPIENTS, users?.results?.map((item: ReportUserDto) => item.id) ?? [])
                    }
                    value={'all'}
                    name={'all'}
                    key={'all'}
                    control={
                      <StyledCheckbox
                        disabled={disabled}
                        sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                        checked={recipientsValue.length > 0}
                        indeterminate={recipientsValue.length > 0 && recipientsValue.length !== users?.results?.length}
                        id="new-report-select-all-recipients-checkbox"
                      />
                    }
                    label={'Zaznacz/odznacz wszystkich'}
                  />
                </Box>
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                <Recipents
                  users={users}
                  multiCheckboxHandler={multiCheckboxHandler}
                  recipientsValue={recipientsValue}
                  searchValue={searchValue}
                  disabled={disabled}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default RecipientsList;
