import { FC, useState, useCallback } from 'react';
import { Switch } from 'components';
import { useSendRequest } from 'lib/hooks';
import { switchUserStatus } from 'api/services';
import { USER_STATUS } from 'types';
import { Props } from './UserStatusSwitch.types';

const mapBooleanToValue = (status: boolean) => (status ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE);

const UserStatusSwitch: FC<Props> = ({ userId, initiallyChecked, disabled }) => {
  const [isChecked, setIsChecked] = useState(initiallyChecked);
  const { isLoading, sendData } = useSendRequest();

  const submitCallback = useCallback(() => {
    setIsChecked((prevState) => !prevState);
  }, []);

  const switchHandler = useCallback(() => switchUserStatus(userId, mapBooleanToValue(!isChecked)), [userId, isChecked]);

  const switchStatus = () => sendData(switchHandler, submitCallback);

  return (
    <Switch
      label="Przełącz aktywność konta"
      isChecked={isChecked}
      onChange={switchStatus}
      disabled={disabled || isLoading}
      justify="flex-end"
    />
  );
};

export default UserStatusSwitch;
