import { AxiosPromise } from 'axios';
import { ApiResponse, CuratorCompaniesDto, UserDto } from '../models';
import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { UserFormTypes } from 'domains/user/shared';
import { USER_STATUS } from 'types';

export const createUser = (values: UserFormTypes.FormikValues): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Users`,
    method: 'POST',
    data: values
  });
};

export const getUser = (id: string): AxiosPromise<ApiResponse<UserDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/CompanyUsers/${id}`,
    method: 'GET'
  });
};

export const updateUser = (id: string, values: UserFormTypes.FormikValues): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Users/${id}`,
    method: 'PUT',
    data: values
  });
};

export const switchUserStatus = (id: number, userStatus: USER_STATUS): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Users/${id}/status/${userStatus}`,
    method: 'PUT'
  });
};

export const removeUser = (id: number | string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Users/${id}`,
    method: 'DELETE'
  });
};

export const getCuratorCompanies = (company?: string): AxiosPromise<ApiResponse<CuratorCompaniesDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/users/CuratorAdministrators/Companies${
      company ? `?SearchQuery=${company}` : ''
    }`,
    method: 'GET'
  });
};
