import { useState, MouseEvent, useEffect, useCallback } from 'react';
import tableClasses from '../../../styles/table.module.scss';
import { CircularProgress, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import { Breadcrumbs, Header, Pagination, Paper, Scrollbar, StyledButton } from 'components';
import { ROLE } from 'config/oidc';
import { useAuth } from 'oidc-react';
import { FIELDS, ReportRow } from './reportsList.types';
import { ListMenu, TableHead } from './components';
import { createRows } from './utils';
import { CellType, HeadCell, mapSortDirection, Order } from 'types';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { getReportsList } from 'api/services';
import { ReportUsersDto } from 'api/models';
import { useFetch, useGoToFirstPage, useRowsPerPage } from 'lib/hooks';
import { PATH } from 'router/routes';
import { Link } from './ReportsWizard.styled';
import { SelectChangeEvent } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import reportFrequency from 'lib/utils/reportFrequency';

const ReportsList = () => {
  const savedOrder = localStorage.getItem('reportsOrder');
  const savedOrderBy = localStorage.getItem('reportsOrderBy');

  const rowsPerPage = useRowsPerPage();
  const [reportsList, setReportsList] = useState<ReportRow[]>();
  const [order, setOrder] = useState<Order>((savedOrder as Order) ?? 'asc');
  const [reportResponse, setReportResponse] = useState<ReportUsersDto>();
  const [orderBy, setOrderBy] = useState<FIELDS>((savedOrderBy as FIELDS) ?? FIELDS.NAME);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);

  const { goToFirstPage } = useGoToFirstPage();

  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get('page');

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  const breadcrumbs = [{ label: 'Kreator raportów cyklicznych' }];

  const fieldsWithLongNames = [FIELDS.NAME];

  const createBaseQueryString = () => {
    return `CompanyId=${companyId}&PageNumber=${
      pageNumber ?? 1
    }&PageSize=${pageSize}&OrderByColumn=${orderBy}&OrderByAscending=${mapSortDirection(order)}`;
  };

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const {
    result: reportsData,
    isLoading: isLoadingReports,
    fetchData: fetchReports
  } = useFetch<any>(
    useCallback(() => getReportsList(query), [query]),
    [query],
    false
  );

  const prepareQuery = () => {
    let queryString = createBaseQueryString();
    setQuery(queryString);
  };

  useEffect(() => {
    prepareQuery();
  }, [pageNumber, pageSize, orderBy, order]);

  useEffect(() => {
    if ((reportsData as ReportUsersDto)?.results) {
      setReportResponse(reportsData as ReportUsersDto);
      //@ts-ignore
      setReportsList((reportsData as ReportUsersDto).results);
    }
  }, [reportsData]);

  const createCells = (cell: CellType<FIELDS>) => {
    if (fieldsWithLongNames.includes(cell.name)) {
      return (
        <Tooltip title={cell.value}>
          <Typography
            variant="body2"
            sx={{ maxWidth: '15.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {cell.value}
          </Typography>
        </Tooltip>
      );
    }
    return (
      <Typography variant="body2" sx={{ maxWidth: '15.5rem' }}>
        {cell.value}
      </Typography>
    );
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: FIELDS) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    localStorage.setItem('reportsOrder', orderValue);
    localStorage.setItem('reportsOrderBy', property);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(PATH.REPORTS_LIST);
  };

  const rows = createRows(reportsList);

  const headCells: HeadCell<FIELDS>[] = [
    {
      id: FIELDS.NAME,
      numeric: false,
      disablePadding: true,
      label: 'Nazwa raportu',
      sortable: true
    },
    {
      id: FIELDS.FIRST_NAME,
      numeric: false,
      disablePadding: true,
      label: 'Właściciel raportu',
      sortable: true
    },
    {
      id: FIELDS.FREQUENCY,
      numeric: false,
      disablePadding: true,
      label: 'Częstotliwość wysyłania raportu',
      sortable: true
    },
    {
      id: FIELDS.ACTIONS,
      numeric: false,
      disablePadding: true,
      label: 'Akcje',
      sortable: false
    }
  ];

  const tooltipLink = { linkName: 'Samouczek, punkt 9.1.', linkUrl: '/tutorial#punkt-9-1' };
  const tooltipText =
    'Lista zawiera wszystkie raporty cykliczne zdefiniowane w Twojej organizacji. Raporty są wysyłane e-mailem do wskazanych użytkowników około godziny 7 rano zgodnie z ustawioną częstotliwością. Dany raport e-mail może zawierać maksymalnie 1000 rekordów.';

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Header
            title={<span className={tableClasses.header}>Kreator raportów cyklicznych</span>}
            tooltipText={tooltipText}
            tooltipLink={tooltipLink}>
            {[ROLE.CLIENT_ADMIN, ROLE.CLIENT_USER, ROLE.CURATOR].includes(userRole) && (
              <Box sx={{ display: 'flex' }}>
                <StyledButton
                  //@ts-ignore //@todo
                  component={Link}
                  to={`${PATH.NEW_REPORT}`}
                  variant="contained"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    border: '1px solid rgba(7, 138, 81, 0.5)',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexShrink: '0',
                    margin: '0 1rem 0 0'
                  }}>
                  Dodaj nowy raport
                </StyledButton>
              </Box>
            )}
          </Header>
          {isLoadingReports ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15rem 0' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead
                      headCells={headCells}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {rows &&
                        rows.map((row) => {
                          const cells: CellType<FIELDS>[] = [
                            { value: row[FIELDS.NAME], name: FIELDS.NAME },
                            { value: row[FIELDS.CREATOR], name: FIELDS.CREATOR }
                          ];

                          return (
                            <TableRow key={`${row.reportId}`} hover tabIndex={-1}>
                              {cells.map((cell) => {
                                return (
                                  <TableCell
                                    key={cell.name}
                                    sx={{
                                      padding: '0.5rem 1.5rem'
                                    }}>
                                    {createCells(cell)}
                                  </TableCell>
                                );
                              })}
                              <TableCell sx={{ padding: '1rem 1.5rem' }}>{reportFrequency(row.frequency)}</TableCell>
                              <TableCell sx={{ padding: '1rem' }}>
                                <ListMenu id={row.reportId} fetchReports={fetchReports} isRemoveDisabled={false} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                extraData={`Liczba rekordów: ${
                  (reportsData as ReportUsersDto)?.totalSize ? (reportsData as ReportUsersDto).totalSize : 0
                }`}
                total={reportResponse?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ReportsList;
