import { FC, memo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { RowsProps, Row } from './Rows.types';
import { CellType } from 'types';
import { FIELDS } from '../usersList.types';
import { ROLE } from 'config/oidc';
import { PATH } from 'router/routes';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { ListMenu, UserStatusSwitch } from './';
import { useAuth } from 'oidc-react';

const fieldsWithLongNames = [FIELDS.COMPANY_NAME, FIELDS.INTEGRATOR_NAME, FIELDS.ERP_NAME];

const createCells = (cell: CellType<FIELDS>) => {
  if (fieldsWithLongNames.includes(cell.name)) {
    return (
      <Tooltip title={cell.value}>
        <Typography
          variant="body2"
          sx={{ maxWidth: '15.5rem', width: 'fit-content', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {cell.value}
        </Typography>
      </Tooltip>
    );
  }
  return (
    <Typography variant="body2" sx={{ maxWidth: '15.5rem' }}>
      {cell.value}
    </Typography>
  );
};

const Rows: FC<RowsProps> = memo(({ rows, fetchUsers }) => {
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const userEmail = auth?.userData?.profile?.Email;

  return (
    <>
      {rows &&
        rows.map((row: Row, index) => {
          const cells: CellType<FIELDS>[] = [
            { value: row[FIELDS.ROLE], name: FIELDS.ROLE },
            { value: row[FIELDS.COMPANY_NAME], name: FIELDS.COMPANY_NAME },
            { value: row[FIELDS.TAX_NUMBER]?.replace('PL', ''), name: FIELDS.TAX_NUMBER },
            { value: row[FIELDS.INTEGRATOR_NAME], name: FIELDS.INTEGRATOR_NAME },
            { value: row[FIELDS.ERP_NAME], name: FIELDS.ERP_NAME }
          ];

          const isManagementDisabled = userRole === ROLE.CLIENT_ADMIN && row[FIELDS.EMAIL] === userEmail;

          const isLinkDisabled = userRole !== ROLE.ADMIN_KG && !row[FIELDS.IS_COMPANY_ACTIVE];

          return (
            <TableRow hover tabIndex={-1} key={row.id} id={`user-row-entire-${index + 1}`}>
              <TableCell
                component="th"
                id={`user-table-row-name-${index + 1}`}
                scope="row"
                sx={{ padding: '1.5rem', width: '12.5%', wordBreak: 'break-word', minWidth: '197px' }}>
                {isLinkDisabled ? (
                  row[FIELDS.FULL_NAME]
                ) : (
                  <Link
                    to={`${PATH.USER_PROFILE}/${row.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'underline'
                    }}>
                    {row[FIELDS.FULL_NAME]}
                  </Link>
                )}
              </TableCell>
              {cells.map((cell, id) => {
                return (
                  <TableCell
                    component="th"
                    scope="row"
                    key={cell.name}
                    id={`user-table-row-${cell.name}-${index + 1}`}
                    sx={{
                      padding: '0.5rem 1.5rem',
                      width: '12.5%',
                      wordBreak: 'break-word',
                      minWidth: '197px'
                    }}>
                    {isLinkDisabled ? (
                      createCells(cell)
                    ) : (
                      <Link
                        to={`${PATH.USER_PROFILE}/${row.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          minHeight: '3.2rem'
                        }}>
                        {createCells(cell)}
                      </Link>
                    )}
                  </TableCell>
                );
              })}
              <TableCell
                sx={{ padding: '0.5rem 1.5rem', textAlign: 'right' }}
                id={`user-table-row-settings-${index + 1}`}>
                <ListMenu
                  id={row.id}
                  fetchUsers={fetchUsers}
                  isRemoveDisabled={isManagementDisabled || isLinkDisabled}
                  isEditDisabled={isLinkDisabled}
                  dataTest="user-table-row-settings"
                  rowIndex={index}
                />
              </TableCell>
              <TableCell
                sx={{ padding: '0.5rem 1rem', textAlign: 'right' }}
                id={`user-table-row-activation-${index + 1}`}>
                <UserStatusSwitch
                  userId={row.id}
                  initiallyChecked={row[FIELDS.IS_ACTIVE]}
                  disabled={!row[FIELDS.IS_COMPANY_ACTIVE] || isManagementDisabled}
                />
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
});

export default Rows;
