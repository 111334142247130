import { FC } from 'react';
import { IconProps } from '../Icons.types';

const NewReportIcon: FC<IconProps> = ({ color = '#000' }) => {
  return (
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.63459 7.315V8.63636H7.88186L6.36186 11.685V10.3636H3.11459L4.63459 7.315ZM6.36186 0L0.316406 12.0909H4.63459V19L10.68 6.90909H6.36186V0Z"
        fill={color}
      />
    </svg>
  );
};

export default NewReportIcon;
