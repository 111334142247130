import styled from '@emotion/styled';
import { RowValueProps } from './Table.types';

export const UnstyledTable = styled.table`
  border-collapse: collapse;
`;

const Row = styled.td`
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
`;

export const RowName = styled(Row)`
  min-width: 11rem;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const RowValue = styled(Row)<RowValueProps>`
  width: 31.75rem;
  ${(props) => props.boldValues && 'font-weight: 500;'}
`;
