import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { FIELDS } from '../dataExportList.types';
import { TableHeadProps } from 'types';

const TableHead: FC<Partial<TableHeadProps<FIELDS>>> = ({ headCells }) => {
  return (
    <MuiTableHead>
      <TableRow>
        {headCells?.map((headCell, id) => (
          <TableCell
            key={headCell.id}
            align={headCells.length === id + 1 ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{
              position: 'relative',
              padding: '1rem .5rem 1rem 1.5rem',
              whiteSpace: 'nowrap',
              width: '23%',
              paddingRight: headCells.length === id + 1 ? '1.5rem' : '.5rem'
            }}>
            <TableSortLabel
              hideSortIcon={true}
              sx={{
                pointerEvents: 'none'
              }}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
