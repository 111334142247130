import { FC } from 'react';
import Snackbar from '../Snackbar';
import { Props } from './Errors.types';

const Errors: FC<Props> = ({ errors, errorId }) => {
  return (
    <>
      {errors.map((error, index) => {
        const info = [`Identyfikator błędu: ${errorId}`];

        const isUnexptectedError = error.ErrorCode === 'ERROR_UNEXPECTED_ERROR_OCCURRED';

        return (
          <Snackbar
            key={error.ErrorCode + index}
            type="error"
            message={error.Message}
            additionalInfo={isUnexptectedError ? info : []}
          />
        );
      })}
    </>
  );
};

export default Errors;
