import tableClasses from '../../../styles/table.module.scss';
import classes from './HistoryChangesList.module.scss';
import SideNav from 'components/SideNav';
import {
  Breadcrumbs,
  Paper,
  Header,
  Scrollbar,
  Pagination,
  StyledButton,
  Tooltip as CustomTooltip,
  SearchField,
  StyledCheckbox,
  StyledRadioButton
} from 'components';
import sideNavClasses from '../../../App.module.scss';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  FIELDS,
  HISTORY_FIELDS,
  informationTypeCheckboxes,
  datesRadioButtons,
  TYPES,
  HistoryDataResults
} from './historyChangesList.types';
import 'moment/locale/pl';
import moment from 'moment';
import { TableHead, ExportModal, Rows } from './components';
import { HeadCell } from './components/TableHead.types';
import { CircularProgress, TableBody, TableContainer, SelectChangeEvent } from '@mui/material';
import Table from '@mui/material/Table';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { mapSortDirection, Order } from 'types';
import { PATH } from 'router/routes';
import { useFormik } from 'formik';
import { initialValues } from './config';
import { useSearchParams } from 'react-router-dom';
import {
  createRows,
  mapOrderBy,
  createSearchQuery,
  createFiltersQuery,
  createInitialSearchQuery,
  createExportToCsvSearchFilters
} from './utils';
import { FiltersTop } from 'domains/customer/shared';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useAuth } from 'oidc-react';
import { ROLE } from 'config/oidc';
import {
  getHistoryData as getHistoryDataService,
  exportHistoryToCsv,
  getHistorySummaryData as getHistorySummaryDataService
} from 'api/services';
import { useSendRequest, useRowsPerPage, useGoToFirstPage, useDidUpdateEffect, useFormikFilters } from 'lib/hooks';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { ExportHistoryDto } from 'api/models';

const exportTooltip =
  'Wyeksportowane zostaną dane o zmianach zadłużenia w KRD, wiarygodności klientów i limitu kupieckiego w ostatnich 9 tygodniach z uwzględnieniem ustawień sortowania, wyszukiwania i wszystkich stron.';
const headerTooltip =
  'W historii zmian uwzględnieni są tylko klienci z aktualnie włączonym monitorowaniem, dla których w ostatnich 9 tygodniach wystąpiły zmiany salda zadłużenia w KRD (wyrażone w PLN) i/lub kategorii wiarygodności płatniczej i/lub limitu kupieckiego (wyrażone w PLN). Wartości w poszczególnych polach pokazują stan po wystąpieniu zmiany w RiskRadar. Puste pole oznacza, że danego dnia nie wystąpiła zmiana w RiskRadar.';

const breadcrumbs = [{ label: 'Historia zmian na klientach' }];

const defaultRowsPerPage = 30;
const rowsPerPageOptions = [30, 60, 90, 120]; //rowsPerPage value options that are divisible by 2 and 3
const rowsPerPageLocaleStorageName = 'historyChangesListRowsPerPage';

const HistoryChanges = () => {
  moment.locale('pl');
  const savedOrder = localStorage.getItem('historyOrder');
  const savedOrderBy = localStorage.getItem('historyOrderBy');
  const [order, setOrder] = useState<Order>((savedOrder as Order) ?? 'asc');
  const [orderBy, setOrderBy] = useState<HISTORY_FIELDS | FIELDS>(
    (savedOrderBy as HISTORY_FIELDS) ?? HISTORY_FIELDS.NAME
  );
  const rowsPerPage = useRowsPerPage(rowsPerPageLocaleStorageName, defaultRowsPerPage);
  const { goToFirstPage } = useGoToFirstPage();
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const pageNumber = searchParams.get('page');
  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const [sessionFilters, setSessionFilters] = useFormikFilters('historyFilterValues');
  const [isDefaultValues, setIsDefaultValues] = useState<boolean>(true);
  const navigate = useNavigate();

  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;
  const [handleClearValueCounter, setHandleClearValueCounter] = useState('');

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      ...initialValues,
      [HISTORY_FIELDS.NAME]: searchParams.get(HISTORY_FIELDS.NAME) || initialValues[HISTORY_FIELDS.NAME],
      [HISTORY_FIELDS.TAX_ID]: searchParams.get(HISTORY_FIELDS.TAX_ID) || initialValues[HISTORY_FIELDS.TAX_ID]
    },
    onSubmit: () => {}
  });

  const { selectedDate, type } = formik.values;
  const isSummaryView = selectedDate === HISTORY_FIELDS.SUMMARY_VIEW;

  const createBaseQueryString = () => {
    const fromDate = moment(selectedDate).subtract(6, 'd').format('YYYY-MM-DD');
    const endDate = moment(selectedDate).format('YYYY-MM-DD');
    const daysQuantity = 55;
    const fromDateSummaryView = (
      moment().day() === 6 ? moment().subtract(7, 'd') : moment().subtract(moment().day(), 'd')
    )
      .subtract(daysQuantity, 'd')
      .format('YYYY-MM-DD');
    const endDateSummaryView = (
      moment().day() === 6 ? moment().subtract(7, 'd') : moment().subtract(moment().day(), 'd')
    ).format('YYYY-MM-DD');

    return `CompanyId=${companyId}&PageNumber=${pageNumber ?? 1}&PageSize=${pageSize}&OrderByColumn=${mapOrderBy(
      orderBy
    )}&OrderByAscending=${mapSortDirection(order)}&FromDate=${isSummaryView ? fromDateSummaryView : fromDate}&ToDate=${
      isSummaryView ? endDateSummaryView : endDate
    }`;
  };

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const { result: dataHistoryResults, isLoading: isLoadingHistoryData, sendData } = useSendRequest();
  const { sendData: sendExportRequest, isLoading: isLoadingSendExport } = useSendRequest();

  const getHistoryData = async (query: string) => {
    sendData(() => (isSummaryView ? getHistorySummaryDataService(query) : getHistoryDataService(query)));
  };

  useEffect(() => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{ "type": [1, 2, 3] }');
    parsedSessionFilters && formik.setValues({ ...formik.values, ...parsedSessionFilters });
  }, []);

  useEffect(() => {
    if (pageNumber) {
      const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
      const name = parsedSessionFilters[HISTORY_FIELDS.NAME];
      const taxId = parsedSessionFilters[HISTORY_FIELDS.TAX_ID];

      if (name) {
        searchParams.set(HISTORY_FIELDS.NAME, name);
      }

      if (taxId) {
        searchParams.set(HISTORY_FIELDS.TAX_ID, taxId);
      }

      setSearchParams(searchParams);
    }
  }, [pageNumber]);

  useDidUpdateEffect(() => {
    if (pageNumber) {
      getHistoryData(query);
    }
  }, [query]);

  const openModalExport = useCallback(() => {
    setIsExportModalVisible(true);
  }, []);

  const closeModalExport = useCallback(() => {
    setIsExportModalVisible(false);
  }, []);

  const exportToCsv = () => {
    const data: ExportHistoryDto = {
      orderByColumn: orderBy,
      orderByAscending: order === 'asc' ? true : false,
      filters: createExportToCsvSearchFilters(searchParams),
      types: formik.values.type,
      companyId: Number(companyId)
    };

    sendExportRequest(() => exportHistoryToCsv(data), openModalExport);
  };

  const rows = useMemo(
    () => createRows((dataHistoryResults as HistoryDataResults)?.results),
    [(dataHistoryResults as HistoryDataResults)?.results]
  );

  const daysCells: HeadCell[] = useMemo(
    () => [
      {
        id: FIELDS.DAY_7,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(6, 'd').format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(6, 'd'),
        sortable: false
      },
      {
        id: FIELDS.DAY_6,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(5, 'd').format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(5, 'd'),
        sortable: false
      },
      {
        id: FIELDS.DAY_5,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(4, 'd').format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(4, 'd'),
        sortable: false
      },
      {
        id: FIELDS.DAY_4,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(3, 'd').format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(3, 'd'),
        sortable: false
      },
      {
        id: FIELDS.DAY_3,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(2, 'd').format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(2, 'd'),
        sortable: false
      },
      {
        id: FIELDS.DAY_2,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(1, 'd').format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).subtract(1, 'd'),
        sortable: false
      },
      {
        id: FIELDS.DAY_1,
        disablePadding: true,
        labelTop: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]).format('dddd'),
        labelBottom: moment(formik.values[HISTORY_FIELDS.SELECTED_DATE]),
        sortable: false
      },
      {
        id: FIELDS.DAY_CURRENT,
        disablePadding: true,
        labelTop: 'Dziś',
        labelBottom: moment(),
        sortable: false
      }
    ],
    [formik.values[HISTORY_FIELDS.SELECTED_DATE]]
  );

  const daysCellsSummaryView: HeadCell[] = useMemo(
    () => [
      {
        id: FIELDS.WEEK_8,
        disablePadding: true,
        labelTop: '8 tygodni',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_7,
        disablePadding: true,
        labelTop: '7 tygodni',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_6,
        disablePadding: true,
        labelTop: '6 tygodni',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_5,
        disablePadding: true,
        labelTop: '5 tygodni',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_4,
        disablePadding: true,
        labelTop: '4 tygodnie',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_3,
        disablePadding: true,
        labelTop: '3 tygodnie',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_2,
        disablePadding: true,
        labelTop: '2 tygodnie',
        labelBottom: 'temu',
        sortable: false
      },
      {
        id: FIELDS.WEEK_1,
        disablePadding: true,
        labelTop: 'Poprzedni',
        labelBottom: 'tydzień',
        sortable: false
      },
      {
        id: FIELDS.CURRENT_WEEK,
        disablePadding: true,
        labelTop: 'Stan na dziś',
        labelBottom: '(bieżący tydzień)',
        sortable: false
      }
    ],
    [formik.values[HISTORY_FIELDS.SELECTED_DATE]]
  );

  const headCells: HeadCell[] = useMemo(
    () => [
      {
        id: FIELDS.NAME,
        disablePadding: true,
        labelTop: 'Klient',
        sortable: true
      },
      {
        id: FIELDS.TAX_ID,
        disablePadding: true,
        labelTop: 'NIP',
        sortable: false
      },
      {
        id: FIELDS.INFORMATION_TYPE,
        disablePadding: true,
        labelTop: 'Rodzaj informacji',
        sortable: false
      },
      ...daysCells
    ],
    [daysCells]
  );

  const headCellsSummaryView: HeadCell[] = useMemo(
    () => [
      {
        id: FIELDS.NAME,
        disablePadding: true,
        labelTop: 'Klient',
        sortable: true
      },
      {
        id: FIELDS.TAX_ID,
        disablePadding: true,
        labelTop: 'NIP',
        sortable: false
      },
      {
        id: FIELDS.INFORMATION_TYPE,
        disablePadding: true,
        labelTop: 'Rodzaj informacji',
        sortable: false
      },
      ...daysCellsSummaryView
    ],
    [daysCellsSummaryView]
  );

  const handleRequestSort = useCallback(
    (_, property: HISTORY_FIELDS | FIELDS) => {
      const isAsc = orderBy === property && order === 'asc';
      const orderValue = isAsc ? 'desc' : 'asc';
      setOrder(orderValue);
      setOrderBy(property);
      localStorage.setItem('historyOrder', orderValue);
      localStorage.setItem('historyOrderBy', property);
    },
    [orderBy, order]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: SelectChangeEvent<number>) => {
      setPageSize(Number(event.target.value));
      goToFirstPage(PATH.HISTORY_CHANGES, searchParams);
    },
    [searchParams]
  );

  const handleClearValue = (field: string) => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    const sessionFiltersWithSearch = { ...parsedSessionFilters, [field]: '' };
    setSessionFilters(JSON.stringify(sessionFiltersWithSearch));

    formik.setFieldValue(field, '');
    if (searchParams.has(field)) {
      searchParams.delete(field);
      setSearchParams(searchParams);
      setHandleClearValueCounter(field);
      goToFirstPage(PATH.HISTORY_CHANGES, searchParams);
    }
  };

  const handleSearch = (fieldName: HISTORY_FIELDS) => {
    const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
    const filters = { ...parsedFilters, [fieldName]: (formik.values as any)[fieldName] };
    setSessionFilters(JSON.stringify(filters));

    if (pageNumber === '1') {
      prepareQuery(fieldName);
    } else {
      goToFirstPage(PATH.HISTORY_CHANGES);
    }
  };

  const handleEnterPress = (fieldName: HISTORY_FIELDS) => handleSearch(fieldName);

  const prepareQuery = (searchFieldName?: HISTORY_FIELDS, initialRequest?: boolean) => {
    let queryString = createBaseQueryString();

    const searchQuery = createSearchQuery(formik.values, searchParams, searchFieldName as HISTORY_FIELDS);
    const filtersQuery = createFiltersQuery(type);

    if (initialRequest) {
      const initialSearchQuery = createInitialSearchQuery(searchParams);
      if (initialSearchQuery) {
        queryString = queryString + `&Filters=${initialSearchQuery}`;
      }
    }

    if (searchQuery && searchFieldName) {
      setSearchParams(searchParams);
      queryString = queryString + `&Filters=${searchQuery}`;
    }

    if (filtersQuery) {
      queryString = queryString + filtersQuery;
    }

    const notIncludedTypes = !queryString.includes('types=');

    if (notIncludedTypes) {
      queryString = `types=1&types=2&types=3&${queryString}`;
    }

    setQuery(queryString);
  };

  useEffect(() => {
    if (handleClearValueCounter) {
      setHandleClearValueCounter('');
      prepareQuery(handleClearValueCounter as HISTORY_FIELDS);
    }
  }, [handleClearValueCounter]);

  useEffect(() => {
    if (Array.from(searchParams).length) {
      const field: HISTORY_FIELDS = formik.values[HISTORY_FIELDS.TAX_ID]
        ? HISTORY_FIELDS.TAX_ID
        : ('' as HISTORY_FIELDS);

      const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
      const filters = {
        ...parsedFilters,
        [HISTORY_FIELDS.DATES]: formik.values[HISTORY_FIELDS.DATES],
        [HISTORY_FIELDS.SELECTED_DATE]: formik.values[HISTORY_FIELDS.SELECTED_DATE],
        [HISTORY_FIELDS.TAX_ID]: formik.values[HISTORY_FIELDS.TAX_ID]
      };
      setSessionFilters(JSON.stringify(filters));

      prepareQuery(field, true);
    }
  }, [pageNumber, pageSize, selectedDate, type, order]);

  useEffect(() => {
    const emptySearchParams = {
      [HISTORY_FIELDS.NAME]: '',
      [HISTORY_FIELDS.TAX_ID]: ''
    };

    const initialValuesWithEmptySearchParams = {
      ...initialValues,
      ...emptySearchParams
    };

    const formikValuesWithEmptySearchParams = {
      ...initialValues,
      ...formik.values,
      ...emptySearchParams
    };

    setIsDefaultValues(isEqual(initialValuesWithEmptySearchParams, formikValuesWithEmptySearchParams));
  }, [formik.values]);

  const clearFilters = () => {
    const typeDefaultValue = [TYPES.DEBT, TYPES.SCORING, TYPES.TRADE_CREDIT_LIMIT];

    formik.setFieldValue(HISTORY_FIELDS.TYPE, typeDefaultValue);
    formik.setFieldValue(HISTORY_FIELDS.SELECTED_DATE, HISTORY_FIELDS.SUMMARY_VIEW);
    formik.setFieldValue(HISTORY_FIELDS.DATES, 'Widok sumaryczny');

    const parsedFilters = sessionFilters && JSON.parse(sessionFilters);

    setSessionFilters(
      JSON.stringify(
        {
          ...parsedFilters,
          [HISTORY_FIELDS.TYPE]: typeDefaultValue,
          [HISTORY_FIELDS.SELECTED_DATE]: HISTORY_FIELDS.SUMMARY_VIEW,
          [HISTORY_FIELDS.DATES]: 'Widok sumaryczny'
        } || '{}'
      )
    );
    searchParams.set('page', '1');
    navigate({ pathname: PATH.HISTORY_CHANGES, search: `${searchParams}` });
    setIsDefaultValues(false);
  };

  const handleCheckboxChange = (event: any) => {
    const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
    const { value, checked } = event.target;
    if (!checked) {
      const newValue = [...formik.values[HISTORY_FIELDS.TYPE].filter((el) => el !== parseInt(value))];
      formik.setFieldValue(HISTORY_FIELDS.TYPE, newValue.sort());
      const filters = { ...parsedFilters, [HISTORY_FIELDS.TYPE]: newValue };
      setSessionFilters(JSON.stringify(filters));
    } else {
      const newValue = [...formik.values[HISTORY_FIELDS.TYPE], parseInt(value)];
      const filters = { ...parsedFilters, [HISTORY_FIELDS.TYPE]: newValue };
      setSessionFilters(JSON.stringify(filters));
      formik.setFieldValue(HISTORY_FIELDS.TYPE, newValue.sort());
    }
    goToFirstPage(PATH.HISTORY_CHANGES);
  };

  const handleRadioChange = (event: any, value: string) => {
    const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
    const filters = {
      ...parsedFilters,
      [HISTORY_FIELDS.DATES]: event.target.value,
      [HISTORY_FIELDS.SELECTED_DATE]: value
    };
    setSessionFilters(JSON.stringify(filters));
    formik.setFieldValue(HISTORY_FIELDS.DATES, event.target.value);
    formik.setFieldValue(HISTORY_FIELDS.SELECTED_DATE, value);
  };

  return (
    <div className={classes.wrapper}>
      <div className={sideNavClasses.sidebar}>
        <SideNav />
        <Paper margin="0 0 0.75rem 0.75rem">
          <FiltersTop clearFilters={() => clearFilters()} disabled={isDefaultValues} />
          <div className={classes.filterSection}>
            <Typography variant="subtitle2">Rodzaj informacji</Typography>
            <FormGroup sx={{ marginTop: '5 px', marginLeft: '6px' }}>
              {informationTypeCheckboxes.map((checkbox) => (
                <div key={checkbox.label}>
                  <FormControlLabel
                    onChange={handleCheckboxChange}
                    value={checkbox.value}
                    name={checkbox.label}
                    control={
                      <StyledCheckbox
                        sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                        checked={formik.values[HISTORY_FIELDS.TYPE].includes(checkbox.value)}
                        id={`history-changes-checkbox-${checkbox.value}`}
                      />
                    }
                    label={checkbox.label}
                  />
                </div>
              ))}
            </FormGroup>
          </div>
          <div className={classes.filterSection}>
            <Typography variant="subtitle2">Zakres czasowy</Typography>
            <FormGroup sx={{ marginTop: '5 px', marginLeft: '6px' }}>
              {datesRadioButtons.map((radio, id) => (
                <FormControlLabel
                  onChange={(event) => handleRadioChange(event, radio.value)}
                  value={radio.label}
                  name={radio.label}
                  key={radio.label}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={formik.values[HISTORY_FIELDS.DATES] === radio.label}
                      id={`history-changes-radio-${id + 1}`}
                    />
                  }
                  label={radio.label}
                />
              ))}
            </FormGroup>
          </div>
        </Paper>
      </div>
      <div className={classes.innerWrapper}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {isLoadingHistoryData ? (
            <div className={classes.spinnerWrapper} data-test-id="history-changes-spinner">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div>
                <Header
                  title={
                    <span className={classes.header} data-test-id="history-changes-title">
                      Historia zmian na klientach
                    </span>
                  }
                  tooltipText={headerTooltip}
                  justifyContent="space-between"
                  hidePagination={false}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  paginationPageSize={pageSize}
                  paginationTotalSize={(dataHistoryResults as HistoryDataResults)?.totalSize}
                  paginationRowsPageSizeOptions={rowsPerPageOptions}
                  paginationRowsPerPageLocaleStorageName={rowsPerPageLocaleStorageName}>
                  <StyledButton
                    variant="outlined"
                    sx={{
                      border: '1px solid rgba(7, 138, 81, 0.5)',
                      color: '#078A51',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      flexShrink: '0',
                      margin: '0 .5rem 0 1.5rem'
                    }}
                    onClick={exportToCsv}
                    startIcon={<FileDownloadIcon />}
                    disabled={isLoadingSendExport}
                    data-test="history-changes-export-to-csv-button">
                    Eksport do CSV
                  </StyledButton>
                  <CustomTooltip text={exportTooltip} />
                </Header>
              </div>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead
                      headCells={isSummaryView ? headCellsSummaryView : headCells}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      isSummaryView={isSummaryView}
                      formik={formik}
                    />
                    <TableBody>
                      <tr className={classes.trHead}>
                        <SearchField
                          name={HISTORY_FIELDS.NAME}
                          value={formik.values[HISTORY_FIELDS.NAME]}
                          onChange={formik.handleChange}
                          onKeyDown={() => handleEnterPress(HISTORY_FIELDS.NAME)}
                          onClearValue={() => handleClearValue(HISTORY_FIELDS.NAME)}
                          hasError={formik.touched[HISTORY_FIELDS.NAME] && !!formik.errors[HISTORY_FIELDS.NAME]}
                          helperText={formik.touched[HISTORY_FIELDS.NAME] && formik.errors[HISTORY_FIELDS.NAME]}
                          onClick={() => handleSearch(HISTORY_FIELDS.NAME)}
                        />
                        <SearchField
                          name={HISTORY_FIELDS.TAX_ID}
                          value={formik.values[HISTORY_FIELDS.TAX_ID]}
                          onChange={formik.handleChange}
                          onKeyDown={() => handleEnterPress(HISTORY_FIELDS.TAX_ID)}
                          onClearValue={() => handleClearValue(HISTORY_FIELDS.TAX_ID)}
                          hasError={formik.touched[HISTORY_FIELDS.TAX_ID] && !!formik.errors[HISTORY_FIELDS.TAX_ID]}
                          helperText={formik.touched[HISTORY_FIELDS.TAX_ID] && formik.errors[HISTORY_FIELDS.TAX_ID]}
                          onClick={() => handleSearch(HISTORY_FIELDS.TAX_ID)}
                        />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        {isSummaryView ? <td /> : null}
                      </tr>
                      <Rows
                        rows={rows}
                        daysCells={isSummaryView ? daysCellsSummaryView : daysCells}
                        isSummaryView={isSummaryView}
                        formik={formik}
                        refreshTable={() => handleSearch(HISTORY_FIELDS.TAX_ID)}
                      />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                options={rowsPerPageOptions}
                extraData={`Liczba rekordów: ${(dataHistoryResults as HistoryDataResults)?.totalSize ?? ''}`}
                total={(dataHistoryResults as HistoryDataResults)?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageLocaleStorageName={rowsPerPageLocaleStorageName}
              />
            </>
          )}
          <ExportModal isExportModalVisible={isExportModalVisible} closeExportModal={closeModalExport} />
        </Paper>
      </div>
    </div>
  );
};

export default HistoryChanges;
