import { DetailedCounterpartyList, ReportSharingMethod } from 'api/models/ReportsDto';
import { FIELDS } from '../../shared';
import { allChangesOptionValue } from 'domains/reportsWizard/shared/components/ReportForm';

export const initialValues = {
  [FIELDS.COMPANY_ID]: 0,
  [FIELDS.REPORT_ID]: 0,
  [FIELDS.NAME]: '',
  [FIELDS.FREQUENCY]: 7,
  [FIELDS.RECIPIENTS]: [],
  [FIELDS.DETAILED_COUNTERPARTY_LIST]: DetailedCounterpartyList.FileAvailableInServiceAndEmail,
  [FIELDS.REPORT_SHARING_METHOD]: ReportSharingMethod.AvailableInServiceAndEmail,

  [FIELDS.RISK_GROUP_DEFINITION_STATE]: 1,
  [FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER]: true,
  [FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD]: allChangesOptionValue,
  [FIELDS.RISK_GROUP_USE_DECLINE_FILTER]: true,
  [FIELDS.RISK_GROUP_DECLINE_THRESHOLD]: allChangesOptionValue,
  [FIELDS.RISK_GROUPS]: [],

  [FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]: 1,
  [FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD]: true,
  [FIELDS.TRADE_CREDIT_LIMIT_IMPROVEMENT_THRESHOLD]: allChangesOptionValue,
  [FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD]: true,
  [FIELDS.TRADE_CREDIT_LIMIT_DECLINE_THRESHOLD]: allChangesOptionValue,
  [FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER]: [],

  [FIELDS.DEBT_DEFINITION_STATE]: 1,
  [FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD]: true,
  [FIELDS.DEBT_IMPROVEMENT_THRESHOLD]: allChangesOptionValue,
  [FIELDS.DEBT_USE_DECLINE_THRESHOLD]: true,
  [FIELDS.DEBT_DECLINE_THRESHOLD]: allChangesOptionValue,
  [FIELDS.DEBT_ADDING_DEBTOR]: true,
  [FIELDS.DEBT_REMOVING_DEBTOR]: true,
  [FIELDS.DEBT_RANGE_FILTER]: []
};
