export enum FIELDS {
  REPORT_ID = 'reportId',
  NAME = 'name',
  RISK_GROUPS = 'riskGroups',
  CREATOR = 'creator',
  FIRST_NAME = 'firstName',
  FREQUENCY = 'frequency',
  ACTIONS = 'actions'
}

export type ReportRow = {
  id: number;
  name: string;
  creator: UserDto;
  frequency: number;
};

export type UserDto = {
  firstName: string;
  lastName: string;
};
