import { FC } from 'react';
import MuiPaper from '@mui/material/Paper';
import { Props } from './Paper.types';

const Paper: FC<Props> = ({ children, margin, overflow, fullSize, padding, flexGrow, height }) => {
  const paperHeight = height
    ? {
        height
      }
    : null;

  return (
    <MuiPaper
      elevation={3}
      sx={{
        padding: padding || '',
        margin: margin || '0 0.75rem 0.75rem 0.75rem',
        borderRadius: '0.75rem',
        width: fullSize ? 'calc(100% - 24px)' : 'auto',
        overflow: overflow ? 'auto' : 'visible',
        flexGrow: flexGrow ? '1' : '0',
        ...paperHeight
      }}>
      {children}
    </MuiPaper>
  );
};

export default Paper;
