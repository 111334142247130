import { FC } from 'react';
import { Props } from './CredibilityCircleIcon.types';
import Box from '@mui/material/Box';

const CredibilityIcon: FC<Props> = ({ letter, color, remSize }) => {
 
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: '0',
      flexGrow: '0',
      width: `${remSize}rem`,
      height: `${remSize}rem`,
      background: color,
      color: '#fff',
      fontWeight: '500',
      borderRadius: '50%',
      fontSize: `${remSize/2}rem`
    }}>
      {letter}
    </Box>
  );
};

export default CredibilityIcon;