import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Switch } from 'components';
import { Props } from './SwitchSection.types';

const SwitchSection: FC<Props> = ({ label, isChecked, disabled, onChange, borderRadius, borderTop, dataTest }) => {
  return (
    <Box sx={{ display: 'flex', border: '1px solid #C4C4C4', borderRadius: borderRadius || '0.25rem', borderTop }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRight: '1px solid #C4C4C4',
          width: '100%',
          padding: '0 3rem 0 0.75rem',
          height: '3.4rem'
        }}>
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Switch label={label} isChecked={isChecked} onChange={onChange} disabled={disabled} dataTest={dataTest} />
    </Box>
  );
};

export default SwitchSection;
