import { AxiosPromise } from 'axios';
import { ReactElement } from 'react';
import { ApiResponse } from '../../../../api/models';

export enum FIELDS {
  NAME = 'Name',
  TAX_NUMBER = 'TaxIdentificationNumber',
  ID = 'Id',
  STREET = 'Street',
  BUILDING_NUMBER = 'BuildingNumber',
  APARTAMENT_NUMBER = 'ApartmentNumber',
  POSTCODE = 'Postcode',
  CITY = 'CITY',
  DESCRIPTION_1 = 'Description1',
  DESCRIPTION_2 = 'Description2',
  COMPANY_ID = 'CompanyId'
}

export type FormikValues = {
  [FIELDS.NAME]: string;
  [FIELDS.TAX_NUMBER]: string;
  [FIELDS.ID]?: number;
  [FIELDS.STREET]: string;
  [FIELDS.BUILDING_NUMBER]: string;
  [FIELDS.APARTAMENT_NUMBER]: string;
  [FIELDS.POSTCODE]: string;
  [FIELDS.CITY]: string;
  [FIELDS.DESCRIPTION_1]: string;
  [FIELDS.DESCRIPTION_2]: string;
  [FIELDS.COMPANY_ID]?: string;
};

export type FieldItem = {
  name: FIELDS;
  label: string;
  value: string;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  styles?: object;
  tooltipText?: string | ReactElement;
};

export type FieldType = FieldItem[];

export type Props = {
  inEditMode?: boolean;
  initialValues: FormikValues;
  submitHandler: (values: FormikValues) => AxiosPromise<ApiResponse<string>>;
};
