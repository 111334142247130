import { Box } from '@mui/system';
import * as d3 from 'd3';
import { ChartGroup, ChartRow, colors } from 'domains/customer/customersList/components/Charts/Charts.types';
import { monitoringCounterSelector } from 'store/monitoringCounter';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
interface DonutData {
  label: string;
  count: number;
  amount: number;
  color: string;
  percentage: number;
  nonEmptyCount: number;
}

const defaultValueChartRow = [
  {
    label: '',
    count: 1,
    nonEmptyCount: 0,
    amount: 0
  }
];

const DonutChart = ({ props }: { props?: ChartGroup }) => {
  const [showDummyData, setShowDummyData] = useState<boolean>(false);
  const [donutData, setDonutData] = useState<DonutData[]>([]);
  const svgRef = useRef(null);
  const [chartRows, setChartRows] = useState<ChartRow[]>(() =>
    props?.chartRows.some((val) => val.amount > 0) || props?.chartRows.some((val) => val.count > 0)
      ? props.chartRows
      : defaultValueChartRow
  );
  const [totalCount, setTotalCount] = useState<number>(() => (props?.totalCount ? props.totalCount : 0));
  const monitoringCounter = useSelector(monitoringCounterSelector);

  const prepareDonutChartData = (data: ChartRow[]) => {
    //@ts-ignore
    const totalAmount = Number(data.reduce((a, b) => a.amount + b.amount));
    const chartRowsCopy = [...data];
    const prepareData = (chartRowsCopy as DonutData[]).map((item, index) => {
      item.color = index in colors ? colors[index].color : '#000000';
      item.percentage = item.amount ? (Number(item.amount) / totalAmount) * 100 : 0;
      return item;
    });
    setDonutData(prepareData);
  };

  useEffect(() => {
    if (chartRows) {
      prepareDonutChartData(chartRows);
    }
  }, [chartRows]);

  useEffect(() => {
    if (
      !(props?.chartRows && props?.chartRows.length) ||
      props?.chartRows.every((val) => val.amount === 0 && props?.totalCount === 0)
    ) {
      setShowDummyData(true);
    }
  }, [props]);

  const getFontSize = (value: number) => {
    if (value > 9999999999) {
      return '16px';
    }
    if (value > 99999) {
      return '20px';
    }
    return '24px';
  };

  useEffect(() => {
    if (donutData?.length) {
      const svg = d3.select(svgRef.current);

      const width = 300,
        height = 300,
        radius = 120;

      const arc: d3.Arc<any, d3.DefaultArcObject> = d3
        .arc()
        .outerRadius(radius - 10)
        .cornerRadius(5)
        .innerRadius(90);

      const pie = d3
        .pie()
        .sort(null)
        //@ts-ignore
        .value((d) => d.count);

      svg.attr('width', width).attr('height', height);

      const newG = svg.append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

      const g = newG
        .selectAll('.arc')
        //@ts-ignore
        .data(pie(donutData))
        .enter()
        .append('g');

      g.append('path')
        .attr('class', 'piece')
        .style('stroke-width', (d) => (showDummyData ? 1 : donutData?.filter((el) => el.amount > 0).length > 0 ? 3 : 0))
        .attr('rx', 5)

        //@ts-ignore
        .attr('d', arc)
        .style('stroke', (d) => (showDummyData ? '#ccc' : '#fff'))
        //@ts-ignore
        .style('fill', (d) => (showDummyData ? '#fff' : d.data.color))
        .on('mouseover', (event, data) => {
          if (!showDummyData) {
            //@ts-ignore
            if (data?.data?.count !== 0) {
              d3.select('#tooltip')
                .style('opacity', 1)
                .style('text-anchor', 'middle')
                .style('background', '#616161eb')
                .style('padding', '5px 10px')
                .style('border-radius', '5px')
                .style('color', '#fff')
                .style('position', 'absolute')
                //@ts-ignore
                .text(`${data.data.label} - ${data.data.count}`);
            }
          }
        })
        .on('mousemove', (event, data) => {
          d3.select('#tooltip')
            .style('left', event.offsetX + 'px')
            .style('top', event.offsetY + 20 + 'px');
        })
        .on('mouseout', () => {
          d3.select('#tooltip').style('opacity', 0);
        });

      const totalCountFontSize = getFontSize(totalCount);
      const monitoringCounterFontSize = getFontSize(monitoringCounter);

      newG
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .style('font-size', totalCountFontSize)
        .attr('y', '-35')
        .text(totalCount);

      newG
        .append('text')
        .attr('text-anchor', 'middle')
        .style('font-size', '12px')
        .attr('font-weight', '500')
        .attr('y', '-15')
        .text('klientów w RiskRadar');

      newG
        .append('rect')
        .attr('y', '0')
        .attr('x', '-60')
        .style('width', '120px')
        .style('height', '1px')
        .style('fill', '#D7DDE2');

      newG
        .append('text')
        .attr('text-anchor', 'middle')
        .style('font-size', '12px')
        .attr('font-weight', '500')
        .attr('y', '23')
        .text('w tym monitorowanych');

      newG
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .style('font-size', monitoringCounterFontSize)
        .attr('y', '51')
        .text(monitoringCounter ?? 0);
    }
  }, [donutData]);

  return (
    <Box>
      <Box id="tooltip" sx={{ position: 'absolute' }}></Box>
      <svg ref={svgRef}></svg>
    </Box>
  );
};

export default DonutChart;
