import classes from './Faq.module.scss';
import { HashLink } from 'react-router-hash-link';

const Faq = () => {
  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>Często zadawane pytania</span>
      <ul className={classes.list}>
        <li className={classes.row}>
          <span className={classes.point}>7.4.</span>
          <HashLink smooth to="/tutorial#punkt-7-4" className={classes.question}>
            Jakie kryteria musi spełniać plik CSV, aby import się powiódł?
          </HashLink>
        </li>
        <li className={classes.row}>
          <span className={classes.point}>3.1.</span>
          <HashLink smooth to="/tutorial#punkt-3-1" className={classes.question}>
            Na czym polega usługa monitorowania?
          </HashLink>
        </li>
        <li className={classes.row}>
          <span className={classes.point}>4.1.</span>
          <HashLink smooth to="/tutorial#punkt-4-1" className={classes.question}>
            Na czym polega analiza wiarygodności płatniczej?
          </HashLink>
        </li>
        <li className={classes.row}>
          <span className={classes.point}>5.1.</span>
          <HashLink smooth to="/tutorial#punkt-5-1" className={classes.question}>
            Na czym polega usługa raportu o kliencie?
          </HashLink>
        </li>
        <li className={classes.row}>
          <span className={classes.point}>6.4.</span>
          <HashLink smooth to="/tutorial#punkt-6-4" className={classes.question}>
            Jak działa licznik usług?
          </HashLink>
        </li>
      </ul>
    </div>
  );
};

export default Faq;
