import { FC } from 'react';

interface TickProps {
  className: string;
}

const TickDownloaded: FC<TickProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="14" height="14" rx="7" fill="#078A51" />
      <path
        d="M5.75 8L7.25 9.5L10.25 6.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="1" y="1" width="14" height="14" rx="7" stroke="white" stroke-width="2" />
    </svg>
  );
};

export default TickDownloaded;
