import { FC, memo } from 'react';
import { NameProps } from './Name.types';
import { nameShortener } from 'lib/utils';
import { Tooltip } from '@mui/material';

const Name: FC<NameProps> = memo(({ name }) => {
  if (name) {
    return (
      <Tooltip title={name}>
        <div>{nameShortener(name, 15)}</div>
      </Tooltip>
    );
  }
  return null;
});

export default Name;
