import Button, { ButtonProps } from '@mui/material/Button';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { FC } from 'react';
import { theme } from './StyledButton.styled';

const StyledButton: FC<ButtonProps> = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>
) => {
  const { children, componente, ...rest } = props;

  return (<MuiThemeProvider theme={theme}>
    <Button {...rest}>{children}</Button>
  </MuiThemeProvider>);
};

export default StyledButton;