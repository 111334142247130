import { FC } from 'react';
import styles from './styles.module.scss';
import { Props } from './SalesChart.types';

const SaleChart: FC<Props> = ({ percentage, color }) => {
  const mainStyle = color === 'red' ? styles.redSalesChart : styles.salesChart;
  const mainStyleLine = color === 'red' ? styles.redSalesChartLine : styles.salesChartLine;
  const mainStyleTopLine = color === 'red' ? styles.redSalesChartTopLine : styles.salesChartTopLine;

  return (
    <div className={mainStyle}>
      <div className={mainStyleLine} style={{width: `${percentage}%`}}>
        <div className={mainStyleTopLine}></div> 
      </div>
    </div>
  )
};

export default SaleChart;