import Box from '@mui/material/Box';
import { RadioGroup, StyledRadioButton } from 'components';
import FormControlLabel from '@mui/material/FormControlLabel';
import { memo, FC } from 'react';
import { RadioProps } from './Radio.types';

const Radio: FC<RadioProps> = memo(
  ({
    radioBoxHandler,
    tooltipText,
    tooltipLink,
    name,
    radioButtonsData,
    value,
    title,
    tooltipTutorialInfo,
    isDisabled
  }) => {
    return (
      <Box sx={{ display: 'block', width: '100%' }}>
        <RadioGroup
          title={title}
          tooltipText={tooltipText}
          tooltipLink={tooltipLink}
          tooltipTutorialInfo={tooltipTutorialInfo}>
          <Box sx={{ display: 'flex', padding: '0 0 0 .3rem' }}>
            <Box sx={{ width: '90%' }}>
              {radioButtonsData.slice(0, 4).map((radio) => (
                <FormControlLabel
                  disabled={isDisabled}
                  onChange={(event) => radioBoxHandler(event, name)}
                  value={radio.value}
                  name={radio.label}
                  key={radio.label}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={value === radio.value}
                    />
                  }
                  label={radio.label}
                />
              ))}
            </Box>
            <Box sx={{ width: '100%' }}>
              {radioButtonsData.slice(4).map((radio) => (
                <FormControlLabel
                  disabled={isDisabled}
                  onChange={(event) => radioBoxHandler(event, name)}
                  value={radio.value}
                  name={radio.label}
                  key={radio.label}
                  control={
                    <StyledRadioButton
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={value === radio.value}
                    />
                  }
                  label={radio.label}
                />
              ))}
            </Box>
          </Box>
        </RadioGroup>
      </Box>
    );
  }
);

export default Radio;
