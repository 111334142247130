import { object, string } from 'yup';

export type Props = {
  isOpen: boolean;
  companyId: string;
  closeNewReportModal: () => void;
  getReportsData: (query: string) => void;
  query: string;
};

export enum FIELDS {
  CLIENT_NAME = 'counterpartyName'
}

export const initialValues = {
  [FIELDS.CLIENT_NAME]: ''
};

export const newReportSchema = object().shape({
  [FIELDS.CLIENT_NAME]: string()
    .max(100, 'Maksymalna ilość znaków to 100.')
    .trim()
    .required('To pole jest obowiązkowe.')
});
