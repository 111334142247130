import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { ExportDataResultDto } from '../models';
import { AxiosPromise } from 'axios';
import { ApiResponse } from '../models';

export const getExportData = (queryString?: string): AxiosPromise<ApiResponse<ExportDataResultDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Exports`.concat(queryString ? `?${queryString}` : '')
  });
};

export const getExportFile = (id: string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Exports/${id}/File`,
    method: 'GET',
    responseType: 'blob'
  });
};
