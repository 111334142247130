import { memo, FC } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { TableHeadProps } from './TableHead.types';
import { HeadCell, FIELDS } from '../reportsHistoryList.types';

const TableHead: FC<TableHeadProps> = memo(({ headCells, onRequestSort, orderBy, order }) => {
  const createSortHandler = (property: FIELDS) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells?.map((headCell: HeadCell<FIELDS>, id: number) => (
          <TableCell
            key={headCell.id}
            id={`reports-history-${headCell.id}`}
            align={headCells.length === id + 1 ? 'right' : 'left'}
            sx={{
              position: 'relative',
              padding: '15px .5rem 15px 1.5rem',
              whiteSpace: 'nowrap',
              width: id > 2 ? '8%' : '12%',
              paddingRight: headCells.length === id + 1 ? '1.5rem' : '.5rem'
            }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              hideSortIcon={!headCell.sortable}
              onClick={headCell.sortable && headCell.id ? createSortHandler(headCell.id) : undefined}
              sx={{
                pointerEvents: headCell.sortable ? 'all' : 'none',
                display: 'flex'
              }}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
});

export default TableHead;
