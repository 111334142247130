import { AuthContextProps } from 'oidc-react';
import { HubConnection } from '@microsoft/signalr';

export const logout = (auth: AuthContextProps, connection: HubConnection | null) => {
  connection?.stop();
  clearLocalAndSessionStorage();
  auth.signOutRedirect();
};

export const clearLocalAndSessionStorage = () => {
  localStorage.removeItem('curatorSelectedCompanyId');
  localStorage.removeItem('curatorSelectedCompanyName');
  localStorage.removeItem('customerFilterValues');
  localStorage.removeItem('userFilterValues');
  localStorage.removeItem('usersOrder');
  localStorage.removeItem('usersOrderBy');
  localStorage.removeItem('reportsOrder');
  localStorage.removeItem('reportsOrderBy');
  localStorage.removeItem('customerOrder');
  localStorage.removeItem('customerOrderBy');
  localStorage.removeItem('historyFilterValues');
  localStorage.removeItem('historyOrder');
  localStorage.removeItem('historyOrderBy');
  localStorage.removeItem('dataExportFilterValues');
  localStorage.removeItem('reportsHistoryFilterValues');
  localStorage.removeItem('reportsHistoryOrder');
  localStorage.removeItem('reportsHistoryOrderBy');
};
