import { FC } from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Loader from '../Loader';
import { Props } from './Dialog.types';
import { StyledButton } from 'components';
import classes from './styles.module.scss';

const Dialog: FC<Props> = ({
  children,
  title,
  isOpen,
  isPending = false,
  acceptLabel = 'Potwierdzam',
  closeLabel = 'Anuluj',
  closeModal,
  accept,
  disableBackdropClick,
  disableEscapeKeyDown,
  acceptDisabled,
  dialogActionsJustify,
  dialogActionsWidth,
  dialogMaxWidth
}) => {
  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (closeModal instanceof Function) {
      closeModal();
    }
  };

  return (
    <MuiDialog
      maxWidth={dialogMaxWidth ? dialogMaxWidth : 'xl'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={title && 'alert-dialog-title'}
      aria-describedby="alert-dialog-description"
      sx={{ minWidth: '19rem', minHeight: '9rem' }}
      disableScrollLock>
      <Loader isLoading={isPending}>
        <div className={classes.container}>
          {title && (
            <div className={classes.title} id="alert-dialog-title">
              {title}
            </div>
          )}

          <DialogContent>
            <DialogContentText component={'div'} id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: dialogActionsJustify ? dialogActionsJustify : 'flex-start',
              width: dialogActionsWidth ? '100%' : 'auto',
              padding: closeLabel === '' && acceptLabel === '' ? '' : '0.5rem 1rem'
            }}>
            {closeLabel !== '' && <StyledButton onClick={closeModal}>{closeLabel}</StyledButton>}
            {acceptLabel !== '' && accept instanceof Function && (
              <StyledButton onClick={accept} disabled={acceptDisabled}>
                {acceptLabel}
              </StyledButton>
            )}
          </DialogActions>
        </div>
      </Loader>
    </MuiDialog>
  );
};

export default Dialog;
