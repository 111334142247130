import { ReportDetailsDto } from '.';

export enum REPORT_STATE_MESSAGE {
  NO_ACCESS = 'ERROR_REPORT_REQUEST_NO_ACCESS'
}

export type ReportDto = {
  result: string;
};

export type ReportItemDto = {
  id: number;
  name: string;
  frequency: number;
  recipients: ReportRecipientItemDto[];
  detailedCounterpartyList: number;
  riskGroupDefinition: RiskGroupReportDefiniitionDto;
  tradeCreditLimitFilter: TradeCreditLimitFilterDto;
  debtReportDefinition: DebtReportDefinitionDto;
};

export type RiskGroupReportDefiniitionDto = {
  riskGroupDefinitionState: RiskGroupDefinitionState;
  useImprovementFilter?: boolean;
  improvementThreshold?: ReportRiskGroupItemDto | null;
  useDeclineFilter?: boolean;
  declineThreshold?: ReportRiskGroupItemDto | null;
  riskGroups?: ReportRiskGroupItemDto[] | null;
};

export type TradeCreditLimitFilterDto = {
  tradeCreditLimitDefinitionState: TradeCreditLimitDefinitionState;
  useImprovementThreshold?: boolean;
  improvementThreshold?: number | null;
  useDeclineThreshold?: boolean;
  declineThreshold?: number | null;
  useRangeFilter?: boolean;
  rangeFilter?: TradeCreditLimitRangeDto[] | null;
};

export type DebtReportDefinitionDto = {
  debtDefinitionState: DebtDefinitionState;
  addingDebtor?: boolean;
  removingDebtor?: boolean;
  useImprovementThreshold?: boolean;
  improvementThreshold?: number | null;
  useDeclineThreshold?: boolean;
  declineThreshold?: DebtDefinitionDeclineThresholdDto | null;
  useRangeFilter?: boolean;
  rangeFilter?: DebtRangeDto[] | null;
};

export type DebtDefinitionDeclineThresholdDto = {
  threshold: number | null;
  isDefined: boolean;
};

export type TradeCreditLimitRangeDto = {
  lowerBound?: number | null;
  upperBound?: number | null;
  isDefined?: boolean | null;
};

export type DebtRangeDto = {
  lowerBound?: number | null;
  upperBound?: number | null;
  isDefined?: boolean | null;
};

export enum DetailedCounterpartyList {
  FileAvailableInServiceAndEmail = 1,
  FileAvailableInService = 2
}

export enum ReportSharingMethod {
  AvailableInServiceAndEmail = 1,
  AvailableInService = 2
}

export enum RiskGroupDefinitionState {
  Disabled = 1,
  AllChanges = 2,
  CustomDefinition = 3
}

export enum TradeCreditLimitDefinitionState {
  Disabled = 1,
  AllChanges = 2,
  CustomDefinition = 3
}

export enum DebtDefinitionState {
  Disabled = 1,
  AllChanges = 2,
  CustomDefinition = 3
}

export type ReportRiskGroupItemDto = {
  externalId: number;
};

export type ReportRecipientItemDto = {
  externalId: number;
  email: string;
  firstName: string;
  lastName: string;
};

export type RiskGroupDto = {
  riskGroups: RiskGroupItemDto[];
};

export type RiskGroupItemDto = {
  id: number;
  code: string;
  value: string;
};

export type ReportUsersDto = {
  results: ReportUserDto[];
  totalSize: number;
};

export type ReportUserDto = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
};

export type LatestReportsDto = {
  availableReportsCount: number;
  reports: ReportDetailsDto[];
};

export type ExportCounterpartiesDto = {
  monitorClient: number;
  debtValueFrom: number;
  debtValueTo: number;
  report: boolean;
  companyId: number;
  riskGroupIds: number[];
  grossSaleLast12MonthsFrom: number;
  grossSaleLast12MonthsTo: number;
  grossDebtFrom: number;
  grossDebtTo: number;
  lastDebt: number;
  lastEvent: number;
  orderByColumn: string;
  orderByAscending: boolean;
  filters: string;
  tradeCreditLimitChangePeriod: number;
  riskGroupChangePeriod: number;
  tradeCreditLimitFilter: number;
  tradeCreditLimitFrom: number;
  tradeCreditLimitTo: number;
};

export type ExportHistoryDto = {
  companyId: number;
  types: number[];
  orderByColumn: string;
  orderByAscending: boolean;
  filters: string;
};

export type ReportHistoryItemDto = {
  counterpartyName: string;
  taxIdentificationNumber: string;
  requesterUserName: string;
  generatedAt: string;
  reportRequestId: number;
  state: number;
};
