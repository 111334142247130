import { AxiosPromise } from 'axios';
import {
  ApiResponse,
  CustomerDetailsDto,
  FilterConfigurationsDto,
  CustomersByTaxIdResult,
  MonitoringEventsDto,
  CsvResultsDto,
  CsvErrorsDto,
  RiskGroupDto,
  MonitoringEnableOrDisableDto,
  ClientAutocompleteQueryResultDto
} from '../models';
import { CustomersDto } from '../models';
import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { ClientFormTypes } from 'domains/customer/shared';

export const getCustomers = (queryString?: string): AxiosPromise<ApiResponse<CustomersDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties`.concat(queryString ? `?${queryString}` : ''),
    method: 'GET'
  });
};

export const enableMonitoring = (
  companyId: string,
  taxNumbers: string[],
  earliestDate: string
): AxiosPromise<ApiResponse<CustomersDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/monitoring/Monitorings`,
    method: 'POST',
    data: {
      taxIdentificationNumbers: taxNumbers,
      monitoringStartDate: earliestDate,
      companyId
    }
  });
};

export const disableMonitoring = (
  companyId: string,
  monitoringIds: string[],
  disableDate: Date | null | string
): AxiosPromise<ApiResponse<CustomersDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/monitoring/Monitorings`,
    method: 'DELETE',
    data: {
      companyId,
      monitoringIds: monitoringIds,
      disableDate
    }
  });
};

export const monitoringEvents = (id: string): AxiosPromise<ApiResponse<MonitoringEventsDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/monitoring/Monitorings/${id}/MonitoringEvents`,
    method: 'GET'
  });
};

export const monitoringsUsage = (companyId: string): AxiosPromise<ApiResponse<number>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/monitoring/monitorings/usage/company/${companyId}`,
    method: 'GET'
  });
};

export const createCustomer = (values: Partial<ClientFormTypes.FormikValues>): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties`,
    method: 'POST',
    data: values
  });
};

export const getCustomer = (id: string): AxiosPromise<ApiResponse<CustomerDetailsDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/${id}`,
    method: 'GET'
  });
};

export const removeCustomer = (id: number | string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/${id}`,
    method: 'DELETE'
  });
};

export const updateCustomer = (id: string, values: ClientFormTypes.FormikValues): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/${id}`,
    method: 'PUT',
    data: values
  });
};

export const getFilterConfigurations = (id: string): AxiosPromise<ApiResponse<FilterConfigurationsDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/filterconfigurations?CompanyId=${id}`,
    method: 'GET'
  });
};

export const getCustomersByTaxNumber = (taxId: string): AxiosPromise<ApiResponse<CustomersByTaxIdResult>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/${taxId}/ByTaxIdentificationNumber`,
    method: 'GET'
  });
};

export const getChartData = (companyId: string): AxiosPromise<ApiResponse<CustomersByTaxIdResult>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/aggregates?CompanyId=${companyId}`,
    method: 'GET'
  });
};

export const getImports = (queryString?: string): AxiosPromise<ApiResponse<Partial<CsvResultsDto>>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Imports`.concat(queryString ? `?${queryString}` : ''),
    method: 'GET'
  });
};

export const getImportErrors = (id: number, queryString?: string): AxiosPromise<ApiResponse<CsvErrorsDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Imports/${id}/Errors`.concat(queryString ? `?${queryString}` : ''),
    method: 'GET'
  });
};

export const checkIfCounterpartyExist = (
  companyId: string,
  taxNumber: string
): AxiosPromise<ApiResponse<CustomersByTaxIdResult>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Counterparties/any?companyId=${companyId}&taxIdentificationNumber=${encodeURIComponent(
      taxNumber
    )}`,
    method: 'GET'
  });
};

export const uploadCsv = (companyId: string, file: Blob): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Imports/company/${companyId}`,
    data: file,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getImportFile = (id: string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Imports/${id}/File`,
    method: 'GET',
    responseType: 'blob'
  });
};

export const getRiskGroups = (includeDefaultRiskGroup: boolean): AxiosPromise<ApiResponse<RiskGroupDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Dictionaries/RiskGroups?IncludeDefaultRiskGroup=${includeDefaultRiskGroup}`,
    method: 'GET'
  });
};

export const getExampleCsv = (): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `/ImportCSV.csv`,
    method: 'GET'
  });
};

export const checkMonitoringEnableOrDisable = (
  date: string
): AxiosPromise<ApiResponse<MonitoringEnableOrDisableDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/monitoring/Monitorings/canEnableOrDisable?Date=${date}`,
    method: 'GET'
  });
};

export const getClientAutocomplete = (
  searchPhrase: string
): AxiosPromise<ApiResponse<ClientAutocompleteQueryResultDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/counterparties/Autocomplete?SearchPhrase=${searchPhrase}&PageNumber=1&PageSize=100`,
    method: 'GET'
  });
};
