import { FC, memo, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Switch } from 'components';
import { Tooltip } from 'components';
import { Props } from './FilterSwitch.types';

const CustomerSwitchFilter: FC<Props> = memo(
  ({ title, tooltipText, tooltipLink, name, value, isChecked, isLast, label, setFieldValue }) => {
    const props = {
      value,
      name,
      isChecked,
      label
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      if (name) {
        setFieldValue(name, checked);
      }
    };

    return (
      <Box sx={{ display: 'block', width: '100%' }}>
        <Box sx={{ borderBottom: isLast ? 'none' : '1px solid #E0E0E0', padding: '0.5rem 1rem 0.5rem 1.5rem' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography variant="subtitle2">{title}</Typography>
              <Tooltip text={tooltipText} link={tooltipLink} />
            </Box>
            <Switch {...props} onChange={handleChange} />
          </Box>
        </Box>
      </Box>
    );
  }
);

export default CustomerSwitchFilter;
