import { ComponentType, FC, useEffect } from 'react';
import { useAuth } from 'oidc-react';
import { Spinner } from 'components';
import { ROUTE_TYPE } from 'types';

interface PublicRouteProps {
  component: ComponentType;
}

const PublicRoute: FC<PublicRouteProps> = ({ component: RouteComponent }) => {
  const auth = useAuth();

  useEffect(() => {
    localStorage.setItem('routeType', ROUTE_TYPE.PUBLIC);
  });

  if (auth.isLoading) {
    return <Spinner />;
  }

  return <RouteComponent />;
};

export default PublicRoute;
