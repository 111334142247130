import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import isPropValid from '@emotion/is-prop-valid';
import { StyledProps } from './Nav.types';
import { Box, createTheme } from '@mui/material';

export const Navigation = styled.nav`
  position: relative;
  margin: 0.75rem;
  padding: 0.75rem 1.25rem;
  display: flex;
  justify-content: space-between;
  background: #5a5a5a;
  height: 71px;
  border-radius: 0.75rem;
  align-items: center;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
`;

export const UserName = styled(Box)`
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.00938em;
`;

export const MenuButton = styled(Button, { shouldForwardProp: isPropValid })<StyledProps>(({ isOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: isOpen ? 'rgba(255, 255, 255, 0.22)' : 'transparent',
  borderRadius: '0.5rem'
}));

export const Stat = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  height: 2.1rem;
  padding: 0 1.8rem;
  cursor: pointer;
  border-right: 1px rgba(255, 255, 255, 0.2) solid;
  &:hover {
    color: #fcb542;
  }
`;

export const StatCount = styled.div`
  margin: 0 0 0 0.5rem;
`;

export const tooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'rgba(252, 181, 66, 0.82)',
          fontSize: '0.9rem',
          maxWidth: 'none'
        },
        arrow: {
          color: 'rgba(252, 181, 66, 0.82)',
          '&:before': {
            border: '1px solid rgba(252, 181, 66, 0.82)'
          }
        }
      }
    }
  }
});
