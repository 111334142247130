import { FC } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Props } from './Tooltip.types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

const Tooltip: FC<Props> = ({
  text,
  position = 'top',
  link = null,
  hideDetailsText = false,
  tutorialInfo,
  noIcon,
  children
}) => {
  const { pathname } = useLocation();

  const theme = createTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          colorPrimary: {
            fill: '#078A51'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '14px',
            maxWidth: pathname === '/reports' ? '306px' : '300px'
          }
        }
      }
    }
  });

  const result = tutorialInfo ? (
    <MuiTooltip
      title={
        <>
          {text}
          <div>Szczegóły - Samouczek</div>
        </>
      }
      placement={position}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
        {!noIcon ? <HelpIcon color="primary" sx={{ marginLeft: '0.25rem' }} /> : null}
      </Box>
    </MuiTooltip>
  ) : link ? (
    <MuiTooltip
      title={
        <>
          {text}
          <div>
            {hideDetailsText ? '' : 'Szczegóły'} -{' '}
            <a style={{ textDecoration: 'underline', color: '#FF9A00' }} href={link.linkUrl} target="_blank">
              {link.linkName}
            </a>
          </div>
        </>
      }
      placement={position}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
        {!noIcon ? <HelpIcon color="primary" sx={{ marginLeft: '0.25rem' }} /> : null}
      </Box>
    </MuiTooltip>
  ) : (
    <MuiTooltip title={text} placement={position}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
        {!noIcon ? <HelpIcon color="primary" sx={{ marginLeft: '0.25rem' }} /> : null}
      </Box>
    </MuiTooltip>
  );

  return <ThemeProvider theme={theme}>{result}</ThemeProvider>;
};

export default Tooltip;
