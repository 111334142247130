import { ApiResponse } from 'api/models';
import { AxiosPromise } from 'axios';

export const downloadXlsFile = async (callback: AxiosPromise<ApiResponse<string>>, fileName: string) => {
  const getData = await callback;
  const blob = new Blob([getData.data as unknown as BlobPart], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};
