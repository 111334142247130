import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const Link = styled(NavLink)`
  align-items: center;
  color: #078A51;
  text-transform: uppercase;

  &.active {
    & > p {
      color: #078A51;
    }
  }
`;