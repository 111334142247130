import { useState, useEffect, useCallback, MouseEvent, useMemo, useRef } from 'react';
import tableClasses from '../../../styles/table.module.scss';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { useFormik } from 'formik';
import { getCompanyUsers, getCompanyUserRolesDict, getCompaniesDict, getIntegratorsDict } from 'api/services';
import { CompanyUsersDto, DictionaryDto } from 'api/models';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Paper, Breadcrumbs, Pagination, SearchField, Scrollbar, Filter, StyledButton } from 'components';
import { useFetch, useRowsPerPage, useGoToFirstPage, useFormikFilters, useDidUpdateEffect } from 'lib/hooks';
import { ROLE } from 'config/oidc';
import { PATH } from 'router/routes';
import { FIELDS, FieldType } from './usersList.types';
import { TableHead, Rows } from './components';
import { headCells, initialValues, TOOLTIPS } from './config';
import { createRows, createFiltersQuery, createSearchQuery } from './utils';
import { CircularProgress, Typography } from '@mui/material';
import { mapSortDirection, Order } from 'types';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { FiltersTop } from 'domains/customer/shared';
import { Helmet } from 'react-helmet-async';
import { sortByName } from 'lib/utils';

const breadcrumbs = [{ label: 'Użytkownicy i uprawnienia' }];

const UsersList = () => {
  const savedOrder = localStorage.getItem('usersOrder');
  const savedOrderBy = localStorage.getItem('usersOrderBy');
  const firstUpdate = useRef(true);

  const rowsPerPage = useRowsPerPage();
  const [sessionFilters, setSessionFilters] = useFormikFilters('userFilterValues');
  const [order, setOrder] = useState<Order>((savedOrder as Order) ?? 'asc');
  const [orderBy, setOrderBy] = useState<FIELDS>((savedOrderBy as FIELDS) ?? FIELDS.FULL_NAME);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [isDefaultValues, setIsDefaultValues] = useState<boolean>(true);
  const [handleClearValueCounter, setHandleClearValueCounter] = useState(0);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get('page');

  const { goToFirstPage } = useGoToFirstPage();

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  const formik = useFormik({
    initialValues,
    onSubmit: () => {}
  });

  useEffect(() => {
    formik.setValues({ ...initialValues, forceRender: true });
  }, []);

  useEffect(() => {
    sessionFilters && formik.setValues({ ...JSON.parse(sessionFilters) });
  }, [useFormik]);

  const { CompanyId, IntegratorId, Role, forceRender } = formik.values;

  useEffect(() => {
    const values = JSON.stringify(formik.values);
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (values !== JSON.stringify(sessionFilters)) {
      setSessionFilters(values);
    }

    const emptySearchParams = {
      [FIELDS.FULL_NAME]: '',
      [FIELDS.COMPANY_NAME]: '',
      [FIELDS.TAX_NUMBER]: '',
      [FIELDS.INTEGRATOR_NAME]: '',
      [FIELDS.ERP_NAME]: ''
    };

    const initialValuesWithEmptySearchParams = {
      ...initialValues,
      ...emptySearchParams,
      forceRender: true
    };

    const formikValuesWithEmptySearchParams = {
      ...formik.values,
      ...emptySearchParams
    };

    setIsDefaultValues(
      JSON.stringify(initialValuesWithEmptySearchParams) === JSON.stringify(formikValuesWithEmptySearchParams)
    );
  }, [formik.values[FIELDS.COMPANY_ID], formik.values[FIELDS.INTEGRATOR_ID], formik.values[FIELDS.ROLE]]);

  const createBaseQueryString = () => {
    const companyIdQuery = [ROLE.ADMIN_KG, ROLE.INTEGRATOR].includes(userRole)
      ? ''
      : `CompanyId=${CompanyId ? CompanyId : companyId}&`;
    return `${companyIdQuery}PageNumber=${
      pageNumber ? pageNumber : 1
    }&PageSize=${pageSize}&OrderByColumn=${orderBy}&OrderByAscending=${mapSortDirection(order)}`;
  };

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const prepareQuery = (searchInputName?: string) => {
    let queryString = createBaseQueryString();
    const searchQuery = createSearchQuery(formik.values, sessionFilters, searchInputName);
    const filtersQuery = createFiltersQuery(CompanyId, IntegratorId, Role);

    if (searchQuery) {
      queryString = queryString + `&Filters=${searchQuery}`;
    }

    if (filtersQuery) {
      queryString = queryString + filtersQuery;
    }

    setQuery(queryString);
  };

  const {
    result: usersData,
    isLoading: isLoadingUsers,
    fetchData: fetchUsers
  } = useFetch<CompanyUsersDto>(
    useCallback(() => getCompanyUsers(query), [query]),
    [query],
    false
  );

  useDidUpdateEffect(() => {
    if (!filtersLoaded) {
      setFiltersLoaded(true);
    }
    prepareQuery();

    if (filtersLoaded) {
      goToFirstPage(PATH.USERS_LIST);
    }

    // eslint-disable-next-line
  }, [order, orderBy, pageSize, CompanyId, IntegratorId, Role, forceRender]);

  useEffect(() => {
    prepareQuery();
  }, [pageNumber]);

  const { result: companiesDict } = useFetch<DictionaryDto>(
    getCompaniesDict,
    [],
    ![ROLE.ADMIN_KG, ROLE.INTEGRATOR].includes(userRole)
  );

  const { result: integratorsDict } = useFetch<DictionaryDto>(
    getIntegratorsDict,
    [],
    ![ROLE.ADMIN_KG].includes(userRole)
  );

  const { result: userRolesDict } = useFetch<DictionaryDto>(getCompanyUserRolesDict);

  const rows = useMemo(() => createRows(usersData?.results), [usersData?.results]);

  const handleRequestSort = useCallback(
    (event: MouseEvent<unknown>, property: FIELDS) => {
      const isAsc = orderBy === property && order === 'asc';
      const orderValue = isAsc ? 'desc' : 'asc';
      setOrder(orderValue);
      setOrderBy(property);
      localStorage.setItem('usersOrder', orderValue);
      localStorage.setItem('usersOrderBy', property);
    },
    [orderBy, order]
  );

  const handleSearch = (name: FIELDS) => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    const sessionFiltersWithSearch = {
      ...parsedSessionFilters,
      [name]: formik.values[name as keyof typeof formik.values]
    };
    setSessionFilters(JSON.stringify(sessionFiltersWithSearch));
    prepareQuery(name);
    goToFirstPage(PATH.USERS_LIST);
  };

  const handleEnterPress = (name: FIELDS) => handleSearch(name);

  const handleClearValue = (field: string) => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    const sessionFiltersWithSearch = { ...parsedSessionFilters, [field]: '' };
    setSessionFilters(JSON.stringify(sessionFiltersWithSearch));
    formik.setFieldValue(field, '');
    setHandleClearValueCounter(handleClearValueCounter + 1);
    goToFirstPage(PATH.USERS_LIST);
  };

  useEffect(() => {
    if (handleClearValueCounter) {
      prepareQuery();
    }
  }, [handleClearValueCounter]);

  const handleChangeRowsPerPage = useCallback((event: any) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(PATH.USERS_LIST);
  }, []);

  const clearFilters = () => {
    const values = {
      ...formik.values,
      [FIELDS.COMPANY_ID]: '',
      [FIELDS.INTEGRATOR_ID]: '',
      [FIELDS.COMPANY_ROLE]: ''
    };

    formik.setValues(values);
    setSessionFilters(JSON.stringify(values));

    searchParams.set('page', '1');

    navigate({ pathname: PATH.USERS_LIST, search: `${searchParams}` });
  };

  const fields: FieldType[] = [
    {
      name: FIELDS.FULL_NAME,
      value: formik.values[FIELDS.FULL_NAME],
      error: formik.touched[FIELDS.FULL_NAME] && !!formik.errors[FIELDS.FULL_NAME],
      helperText: formik.touched[FIELDS.FULL_NAME] && formik.errors[FIELDS.FULL_NAME]
    },
    {
      name: '',
      value: ''
    },
    {
      name: FIELDS.COMPANY_NAME,
      value: formik.values[FIELDS.COMPANY_NAME],
      error: formik.touched[FIELDS.COMPANY_NAME] && !!formik.errors[FIELDS.COMPANY_NAME],
      helperText: formik.touched[FIELDS.COMPANY_NAME] && formik.errors[FIELDS.COMPANY_NAME]
    },
    {
      name: FIELDS.TAX_NUMBER,
      value: formik.values[FIELDS.TAX_NUMBER],
      error: formik.touched[FIELDS.TAX_NUMBER] && !!formik.errors[FIELDS.TAX_NUMBER],
      helperText: formik.touched[FIELDS.TAX_NUMBER] && formik.errors[FIELDS.TAX_NUMBER]
    },
    {
      name: FIELDS.INTEGRATOR_NAME,
      value: formik.values[FIELDS.INTEGRATOR_NAME],
      error: formik.touched[FIELDS.INTEGRATOR_NAME] && !!formik.errors[FIELDS.INTEGRATOR_NAME],
      helperText: formik.touched[FIELDS.INTEGRATOR_NAME] && formik.errors[FIELDS.INTEGRATOR_NAME]
    },
    {
      name: FIELDS.ERP_NAME,
      value: formik.values[FIELDS.ERP_NAME],
      error: formik.touched[FIELDS.ERP_NAME] && !!formik.errors[FIELDS.ERP_NAME],
      helperText: formik.touched[FIELDS.ERP_NAME] && formik.errors[FIELDS.ERP_NAME]
    },
    {
      name: '',
      value: ''
    },
    {
      name: '',
      value: ''
    }
  ];

  return (
    <>
      <Helmet>
        <title>RiskRadar - Użytkownicy</title>
      </Helmet>
      <form onSubmit={(e) => e.preventDefault()}>
        <Box sx={{ display: 'flex' }}>
          <Box className={classes.sidebar}>
            <SideNav />
            <Paper margin="0 0 0.75rem 0.75rem">
              <FiltersTop disabled={isDefaultValues} clearFilters={clearFilters} />
              {[ROLE.ADMIN_KG, ROLE.INTEGRATOR].includes(userRole) && (
                <Filter
                  title="Firma"
                  tooltipText={TOOLTIPS.COMPANY}
                  name={FIELDS.COMPANY_ID}
                  value={formik.values[FIELDS.COMPANY_ID]}
                  handleChange={formik.handleChange}
                  error={formik.touched[FIELDS.COMPANY_ID] && !!formik.errors[FIELDS.COMPANY_ID]}
                  options={companiesDict?.dictionary.sort(sortByName) || []}
                  defaultOption={{ value: 'Wszystkie', id: '' }}
                />
              )}

              {[ROLE.ADMIN_KG].includes(userRole) && (
                <Filter
                  title="Integrator"
                  tooltipText={TOOLTIPS.INTEGRATOR}
                  name={FIELDS.INTEGRATOR_ID}
                  value={formik.values[FIELDS.INTEGRATOR_ID]}
                  handleChange={formik.handleChange}
                  error={formik.touched[FIELDS.INTEGRATOR_ID] && !!formik.errors[FIELDS.INTEGRATOR_ID]}
                  options={integratorsDict?.dictionary.sort(sortByName) || []}
                  defaultOption={{ value: 'Wszystkie', id: '' }}
                />
              )}

              {[ROLE.ADMIN_KG, ROLE.INTEGRATOR, ROLE.CLIENT_ADMIN, ROLE.CURATOR].includes(userRole) && (
                <Filter
                  title="Rola użytkownika firmy"
                  tooltipText={TOOLTIPS.ROLE}
                  name={FIELDS.COMPANY_ROLE}
                  value={formik.values[FIELDS.COMPANY_ROLE]}
                  handleChange={formik.handleChange}
                  error={formik.touched[FIELDS.COMPANY_ROLE] && !!formik.errors[FIELDS.COMPANY_ROLE]}
                  options={userRolesDict?.dictionary || []}
                  defaultOption={{ value: 'Wszystkie', id: '' }}
                  isLast
                />
              )}
            </Paper>
          </Box>
          <Paper overflow fullSize>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Box
              sx={{
                padding: '1.5rem'
              }}>
              <StyledButton
                test-data="addUser"
                //@ts-ignore //@todo
                component={Link}
                to={PATH.ADD_USER}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}>
                Dodaj użytkownika
              </StyledButton>
            </Box>
            {isLoadingUsers ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15rem 0' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TableContainer>
                  <Scrollbar>
                    <Table
                      sx={{
                        minWidth: 750,
                        '@media (min-width: 1920px)': {
                          tableLayout: 'fixed'
                        }
                      }}
                      aria-labelledby="tableTitle"
                      size="medium">
                      <TableHead
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        <tr style={{ background: '#FAFAFA', boxShadow: 'inset 0px -1px 0px #E0E0E0' }}>
                          {fields.map((field, index) => {
                            if (!field.name) {
                              return <td key={index} />;
                            }

                            return (
                              <SearchField
                                key={field.name}
                                name={field.name}
                                value={field.value}
                                onChange={formik.handleChange}
                                onKeyDown={() => handleEnterPress(field.name as FIELDS)}
                                onClearValue={() => handleClearValue(field.name)}
                                hasError={field.error}
                                helperText={field.helperText}
                                onClick={() => handleSearch(field.name as FIELDS)}
                                containerWidth={230}
                              />
                            );
                          })}
                        </tr>
                        <Rows rows={rows} fetchUsers={fetchUsers} />
                      </TableBody>
                    </Table>

                    {usersData?.results?.length === 0 && (
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: 'center',
                          margin: '3.5rem 0',
                          fontSize: '1.2rem',
                          color: 'rgba(0, 0, 0, 0.6)'
                        }}>
                        Brak wyników wyszukiwania, zmień kryteria wyszukiwania, wyczyść
                        <br />
                        filtry lub{' '}
                        <Box
                          sx={{ color: '#078A51', textDecoration: 'underline' }}
                          component={Link}
                          to={PATH.ADD_USER}
                          target="_blank"
                          rel="noopener noreferrer">
                          dodaj nowego użytkownika
                        </Box>
                      </Typography>
                    )}
                  </Scrollbar>
                </TableContainer>
                <Pagination
                  total={usersData?.totalSize || 1}
                  pageSize={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Box>
      </form>
    </>
  );
};

export default UsersList;
