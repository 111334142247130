import { useCallback, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Loader, Paper, Breadcrumbs } from 'components';
import { getCustomersByTaxNumber } from 'api/services';
import { CustomersByTaxIdResult } from 'api/models';
import { useFetch } from 'lib/hooks';
import { PATH } from 'router/routes';
import { CUSTOMER_FIELDS } from '../customersList/customersList.types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAuth } from 'oidc-react';
import { ROLE } from 'config/oidc';

const breadcrumbs = [{ to: PATH.CUSTOMERS_LIST, label: 'Klienci' }, { label: 'Brak klienta w systemie' }];

const TaxIdentifier = () => {
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const isKgAdmin = userRole === ROLE.ADMIN_KG;

  const { taxId } = useParams();
  const fetchCustomersByTaxId = useCallback(() => getCustomersByTaxNumber(taxId || ''), [taxId]);
  const { result: customersResult, isLoading } = useFetch<CustomersByTaxIdResult>(
    fetchCustomersByTaxId,
    [taxId],
    isKgAdmin
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (isKgAdmin) {
      navigate(PATH.ACCESS_DENIED, { replace: true });
    }
    if (customersResult?.count === 1) {
      navigate(`${PATH.CUSTOMER_PROFILE}/${customersResult?.singleCounterpartyId}`, { replace: true });
    }

    if (typeof customersResult?.count === 'number' && customersResult?.count > 1) {
      navigate(`${PATH.CUSTOMERS_LIST}?page=1&${CUSTOMER_FIELDS.TAX_NUMBER}=${taxId}`, { replace: true });
    }
  }, [customersResult, navigate, taxId]);

  return (
    <Loader isLoading={isLoading}>
      <Paper overflow>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Box
          sx={{
            minHeight: '20.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D32F2F' }}>
            Klient o numerze NIP {taxId} nie istnieje w RiskRadar.
          </Typography>
          <Stack spacing={3} direction="row" sx={{ marginTop: '1.5rem', textTransform: 'uppercase' }}>
            <Button variant="contained" component={Link} to={`${PATH.CUSTOMERS_LIST}`}>
              Wróć do listy klientów
            </Button>
            <Button
              variant="contained"
              sx={{ display: 'flex', flexWrap: 'nowrap', flexShrink: '0' }}
              component={Link}
              to={PATH.ADD_CUSTOMER}
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<AddCircleOutlineIcon />}>
              Dodaj klienta
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Loader>
  );
};

export default TaxIdentifier;
