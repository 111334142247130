import { Paper, Breadcrumbs } from 'components';
import { PATH } from 'router/routes';
import { createCustomer } from 'api/services';
import { initialValues } from './config';
import { ClientForm } from '../shared';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { Helmet } from 'react-helmet-async';

const breadcrumbs = [{ to: PATH.CUSTOMERS_LIST, label: 'Lista klientów' }, { label: 'Nowy klient' }];

const AddCustomer = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>RiskRadar - Nowy klient</title>
      </Helmet>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ClientForm initialValues={initialValues} submitHandler={createCustomer} />
      </Paper>
    </Box>
  );
};

export default AddCustomer;
