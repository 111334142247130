import { AxiosPromise } from 'axios';
import { ApiResponse, RefreshTokenDto } from '../models';
import { CompanyDto, CompanyUsersDto, DictionaryDto } from '../models';
// TODO: #989
// import { CompanyOwuStatusDto } from '../models';
import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';

export const getCompany = (id: string): AxiosPromise<ApiResponse<CompanyDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Companies/${id}`,
    method: 'GET'
  });
};

export const getCompanyUsers = (queryString?: string): AxiosPromise<ApiResponse<CompanyUsersDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/CompanyUsers`.concat(queryString ? `?${queryString}` : ''),
    method: 'GET'
  });
};

export const getCompaniesDict = (): AxiosPromise<ApiResponse<DictionaryDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Dictionaries/Companies`,
    method: 'GET'
  });
};

export const getIntegratorsDict = (): AxiosPromise<ApiResponse<DictionaryDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Dictionaries/Integrators`,
    method: 'GET'
  });
};

export const getCompanyUserRolesDict = (): AxiosPromise<ApiResponse<DictionaryDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Dictionaries/CompanyUserRoles`,
    method: 'GET'
  });
};

export const getRefreshToken = (userId: string): AxiosPromise<ApiResponse<RefreshTokenDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Users/${userId}/refreshtoken`,
    method: 'GET'
  });
};

// TODO: #989
// export const getCompanyOwuAgreement = (id: string): AxiosPromise<ApiResponse<CompanyOwuStatusDto>> => {
//   return axiosInstance({
//     url: `${baseAPIUrl}/systemManagement/Companies/${id}/agreements/status`,
//     method: 'GET'
//   });
// };
