import { Paper, Breadcrumbs } from 'components';
import { PATH } from 'router/routes';
import TutorialSection from './TutorialSection';
import { PageTitle } from 'components';
import TutorialMenuSection from './TutorialMenuSection';
import { TutorialContent } from '../types/TutorialSection.types';
import styles from './tutorial.module.scss';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { useEffect, useState } from 'react';
import Faq from './Faq';

const Tutorial = () => {
  const [hash, setHash] = useState<string>('');
  const breadcrumbs = [{ to: PATH.TUTORIAL, label: 'Samouczek' }];

  useEffect(() => {
    const hashFromUrl = window.location.hash.replace('#', '');
    setHash(hashFromUrl);
  }, []);

  useEffect(() => {
    const element = document.getElementById(hash);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [hash]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div style={{ display: 'flex' }}>
          <div style={{ padding: '1rem 0', borderRight: '1px solid #E0E0E0' }}>
            <PageTitle sx={{ padding: '0 1.5rem' }} dataTest="tutorial-title">
              Samouczek
            </PageTitle>
            <Faq />
            <div>
              {TutorialContent.map((x, index) => (
                <div className={styles.tutorialMenuSectionWrapper} key={index}>
                  <TutorialSection name={x.name} index={x.index} subsections={x.subsections}></TutorialSection>
                </div>
              ))}
            </div>
          </div>
          <div style={{ padding: '1rem 0', minWidth: '20%' }}>
            <PageTitle sx={{ padding: '0 1.5rem' }} dataTest="tutorial-contents">
              Spis treści
            </PageTitle>
            <div>
              {TutorialContent.map((x, index) => (
                <TutorialMenuSection
                  key={index}
                  name={x.name}
                  index={x.index}
                  subsections={x.subsections}></TutorialMenuSection>
              ))}
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default Tutorial;
