import { FC, memo } from 'react';
import { MultiCheckboxProps } from './MultiCheckbox.types';
import { CheckboxGroup, StyledCheckbox } from 'components';
import FormControlLabel from '@mui/material/FormControlLabel';
import classes from './styles.module.scss';

const MultiCheckbox: FC<MultiCheckboxProps> = memo(
  ({ multiCheckboxHandler, tooltipText, tooltipLink, name, checkboxesData, value, title, fullWidth }) => {
    return (
      <CheckboxGroup title={title} tooltipText={tooltipText} tooltipLink={tooltipLink}>
        <div className={classes.container}>
          <div
            className={`${classes.containerPart} ${fullWidth ? classes.containerPartFull : classes.containerPartHalf}`}>
            {checkboxesData.slice(0, 5).map((radio) => (
              <div className={classes.checkboxContainer} key={radio.label}>
                <FormControlLabel
                  onChange={(event) => multiCheckboxHandler(event, name)}
                  value={radio.value}
                  name={radio.label}
                  key={radio.label}
                  control={
                    <StyledCheckbox
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={value.toString().includes(radio.value)}
                    />
                  }
                  label={radio.label}
                />
              </div>
            ))}
          </div>
          <div className={classes.containerPart}>
            {checkboxesData.slice(5).map((radio) => (
              <div className={classes.checkboxContainer} key={radio.label}>
                <FormControlLabel
                  onChange={(event) => multiCheckboxHandler(event, name)}
                  value={radio.value}
                  name={radio.label}
                  key={radio.label}
                  control={
                    <StyledCheckbox
                      sx={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem' }}
                      checked={value.toString().includes(radio.value)}
                    />
                  }
                  label={radio.label}
                />
              </div>
            ))}
          </div>
        </div>
      </CheckboxGroup>
    );
  }
);

export default MultiCheckbox;
