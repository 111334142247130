import { memo, FC } from 'react';
import moment from 'moment';
import { TableCell, TableRow } from '@mui/material';
import { RowsProps } from './Rows.types';
import { Row } from '../reportsHistoryList.types';
import { nameShortener } from 'lib/utils';
import { Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { STATUS } from '../reportsHistoryList.types';
import classes from './Rows.module.scss';
import { downloadReport } from 'lib/utils';

const Rows: FC<RowsProps> = memo(({ rows }) => {
  const tdStyles = { paddingLeft: '24px' };

  return (
    <>
      {rows.map((row: Row, id: number) => {
        const reportCompleted = row.state === STATUS.COMPLETED;

        return (
          <TableRow
            key={id}
            hover
            tabIndex={-1}
            id={`reports-history-row-${id}`}
            onClick={() => reportCompleted && downloadReport(row.reportRequestId, row.generatedAt)}
            className={reportCompleted ? classes.row : classes.rowNotClickable}>
            <TableCell className={classes.tableTd} sx={tdStyles} id={`reports-history-row-client-${id}`}>
              <Tooltip title={row.counterpartyName}>
                <div>{row?.counterpartyName ? nameShortener(row.counterpartyName, 15) : ''}</div>
              </Tooltip>
            </TableCell>
            <TableCell sx={tdStyles} id={`reports-history-row-taxId-${id}`}>
              {row.taxIdentificationNumber?.replace('PL', '')}
            </TableCell>
            <TableCell sx={tdStyles} id={`reports-history-row-user-${id}`}>
              {row.requesterUserName}
            </TableCell>
            <TableCell sx={tdStyles} id={`reports-history-row-generatedAt-${id}`}>
              {row?.generatedAt ? moment(row.generatedAt).format('DD-MM-YYYY HH:mm:ss') : ''}
            </TableCell>
            <TableCell sx={tdStyles} id={`reports-history-row-downloadReport-${id}`}>
              {reportCompleted ? (
                <button className={classes.button} type="button">
                  <FileDownloadIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </button>
              ) : (
                <HourglassEmptyIcon className={classes.hourglassIcon} />
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
});

export default Rows;
