import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#078A51",
    },
    secondary: {
      main: "#078A51",
    },
  }
});
