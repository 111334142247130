import { Box } from '@mui/material';
import { FC } from 'react';
import SaleChart from '../SaleChart/';
import { Props } from './SaleBox.types';
import ListIcon from '@mui/icons-material/List';
import moment from 'moment';
import { numberWithSpace } from 'lib/utils';

const SaleBox: FC<Props> = ({ number, secondNumber, importDate, text, textColor, color }) => {
  const higher = number && secondNumber && number > secondNumber;
  const resultCount = number ? (
    numberWithSpace(number?.toFixed(2))
  ) : (
    <>
      Brak danych <ListIcon sx={{ marginLeft: '.2rem' }} />
    </>
  );
  const percentage = higher ? '100' : number && secondNumber ? `${(number / secondNumber) * 100}` : '0';

  return (
    <Box sx={{ width: '100%', padding: '0 0 0 1.5rem' }}>
      <Box sx={{ margin: '1rem 0 0.5rem 0', display: 'flex', justifyContent: 'space-between' }}>
        {text} {importDate ? `(${moment(importDate).format('DD-MM-YYYY')})` : ''}
        <Box sx={{ color: number ? textColor : '#EA9617', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
          {resultCount}
        </Box>
      </Box>
      <SaleChart percentage={percentage} color={color} />
    </Box>
  );
};

export default SaleBox;
