import { Box } from '@mui/material';
import moment from 'moment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { REPORT_STATE } from 'api/models/CustomerDto';
import { FC } from 'react';
import { DownloadableReportItemProps } from './DownloadableReportItem.types';
import { downloadReport } from 'lib/utils';

const DownloadableReportItem: FC<DownloadableReportItemProps> = ({ item, index, sx }) => {
  const pendingMessage = 'Trwa generowanie raportu. Może to potrwać 30 sekund. Pobieramy informacje z wielu źródeł.';

  const body = (
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      {item.state === REPORT_STATE.PENDING ? (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ color: '#ec9514' }}>{pendingMessage}</Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <HourglassEmptyIcon fontSize="large" sx={{ color: '#ec9514' }} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
          Otwórz raport pobrany {moment(item.generatedAt).format('DD-MM-YYYY')}
        </Box>
      )}
      {item.state === REPORT_STATE.PENDING ? null : (
        <Box sx={{ margin: '0 0 0 1rem', marginLeft: 'auto' }}>
          <PictureAsPdfIcon fontSize="large" />
        </Box>
      )}
    </Box>
  );

  return (
    <Box
      onClick={() => item.state === REPORT_STATE.CREATED && downloadReport(item.id, item.generatedAt)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        borderRadius: '8px',
        border: item.state === REPORT_STATE.PENDING ? '1px solid #ec9514' : '1px solid #e0e0e0',
        margin: '0.5rem 0',
        background: item.state === REPORT_STATE.PENDING ? '#ec951405' : '',
        cursor: item.state === REPORT_STATE.CREATED ? 'pointer' : 'default',
        ...sx
      }}>
      <Box sx={{ margin: '0 1rem 0 0', color: item.state === REPORT_STATE.PENDING ? '#ec9514' : 'inherit' }}>
        {index}.
      </Box>
      {body}
    </Box>
  );
};

export default DownloadableReportItem;
