import { Paper, Breadcrumbs } from 'components';
import { PATH } from 'router/routes';
import { createUser } from 'api/services';
import { initialValues } from './config';
import { UserForm } from '../shared';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { Helmet } from 'react-helmet-async';

const breadcrumbs = [{ to: PATH.USERS_LIST, label: 'Użytkownicy i uprawnienia' }, { label: 'Nowy użytkownik' }];

const AddUser = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>RiskRadar - Nowy użytkownik</title>
      </Helmet>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <UserForm initialValues={initialValues} submitHandler={createUser} />
      </Paper>
    </Box>
  );
};

export default AddUser;
