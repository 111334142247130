import { memo, FC } from 'react';
import moment from 'moment';
import { TableCell, TableRow } from '@mui/material';
import Name from './Name';
import { CredibilityIcon } from 'assets/images/icons';
import {
  FIELDS,
  HistoryDate,
  ChangeHistoryValue,
  Row,
  TYPES,
  datesRadioButtons,
  HISTORY_FIELDS
} from '../historyChangesList.types';
import { parseType } from '../utils';
import classes from '../HistoryChangesList.module.scss';
import { HeadCell } from './TableHead.types';
import { RowTextStyles, RowsProps } from './Rows.types';
import { PATH } from 'router/routes';
import { numberWithSpace } from 'lib/utils';

const getValue = (
  changeHistoryValue: ChangeHistoryValue,
  type: TYPES,
  isCurrentDayValue: boolean
): string | null | JSX.Element => {
  switch (type) {
    case TYPES.DEBT: {
      const debtAmountPlnValue = changeHistoryValue?.indebtednessValue?.debtAmountPln;
      if (debtAmountPlnValue !== undefined && debtAmountPlnValue !== null && typeof debtAmountPlnValue === 'number') {
        return numberWithSpace(debtAmountPlnValue.toFixed(2)).toString();
      }
      return '';
    }
    case TYPES.SCORING: {
      const riskGroup = changeHistoryValue?.scoringValue?.riskGroup;
      if (typeof riskGroup === 'string') {
        return <CredibilityIcon letter={riskGroup === 'X' ? 'none' : riskGroup} />;
      }
      return '';
    }
    case TYPES.TRADE_CREDIT_LIMIT: {
      const isTradedCreditLimitActive = changeHistoryValue?.tradeCreditLimitValue?.isTradedCreditLimitActive;
      if (typeof isTradedCreditLimitActive === 'boolean' && !isTradedCreditLimitActive) {
        if (isCurrentDayValue) {
          return 'Nieaktywny';
        }
        return '';
      } else {
        const tradedCreditLimitValue = changeHistoryValue?.tradeCreditLimitValue?.tradedCreditLimitValue;
        if (
          tradedCreditLimitValue !== undefined &&
          tradedCreditLimitValue !== null &&
          typeof tradedCreditLimitValue === 'number' &&
          isTradedCreditLimitActive !== null &&
          isTradedCreditLimitActive !== undefined
        ) {
          return numberWithSpace(tradedCreditLimitValue.toFixed(2)).toString();
        }
        if (isTradedCreditLimitActive !== null && isTradedCreditLimitActive !== undefined) {
          return <CredibilityIcon letter="none" />;
        }
        return '';
      }
    }
  }
};

const getTextStyles = (changeHistoryValue: ChangeHistoryValue | null, type: TYPES): RowTextStyles => {
  if (type !== TYPES.TRADE_CREDIT_LIMIT || changeHistoryValue === null) return { align: 'right', color: 'inherit' };

  const isTradedCreditLimitActive = changeHistoryValue?.tradeCreditLimitValue?.isTradedCreditLimitActive;
  if (typeof isTradedCreditLimitActive === 'boolean' && !isTradedCreditLimitActive)
    return { align: 'center', color: '#bdbdbd' };
  else return { align: 'right', color: 'inherit' };
};

const Rows: FC<RowsProps> = memo(({ rows, daysCells, isSummaryView, formik, refreshTable }) => {
  const newValue = (value: string | ChangeHistoryValue | null, type: TYPES, isCurrentDayValue: boolean) => {
    if (typeof value === 'string') return value;
    else if (value === null) return null;
    else return getValue(value, type, isCurrentDayValue);
  };

  const tableCellStyles = { padding: '1.5rem', whiteSpace: 'nowrap' };
  const tableCellStylesWithBorder = { ...tableCellStyles, borderRight: '1px solid rgba(224, 224, 224, 1)' };

  return (
    <>
      {rows.map((row: Row, id: number) => {
        return (
          <TableRow
            key={`${row.taxIdentificationNumber}${id}`}
            hover
            tabIndex={-1}
            id={`history-changes-row-${id + 1}`}>
            <TableCell
              onClick={() => window.open(`${PATH.CUSTOMER_PROFILE}/${row.counterpartyId}`, '_blank')}
              sx={{ ...tableCellStyles, textDecoration: 'underline', cursor: 'pointer' }}
              id={`history-changes-row-name-${id + 1}`}>
              <Name name={row.name} />
            </TableCell>
            <TableCell
              onClick={() => window.open(`${PATH.CUSTOMER_PROFILE}/${row.counterpartyId}`, '_blank')}
              id={`history-changes-row-tax-${id + 1}`}
              sx={{ ...tableCellStyles, cursor: 'pointer' }}>
              {row.taxIdentificationNumber?.replace('PL', '')}
            </TableCell>
            <TableCell
              onClick={() => window.open(`${PATH.CUSTOMER_PROFILE}/${row.counterpartyId}`, '_blank')}
              id={`history-changes-row-type-${id + 1}`}
              sx={{ ...tableCellStylesWithBorder, cursor: 'pointer' }}>
              {parseType(row.type)}
            </TableCell>
            {daysCells.map((el: HeadCell, id: number) => {
              const styles = {
                ...(el.id === (isSummaryView ? FIELDS.WEEK_1 : FIELDS.DAY_1)
                  ? tableCellStylesWithBorder
                  : tableCellStyles),
                textAlign: 'right',
                paddingRight: '14px'
              };

              const isCurrentWeek = el.id === 'current_week';
              const weekId = isCurrentWeek ? 1 : parseInt(el.id?.split('week')[1]);
              const foundWeek = isCurrentWeek ? datesRadioButtons[1] : datesRadioButtons?.[weekId + 1];

              const dayDataId = `history-changes-row-day-${el.id}`;
              const foundDate = row.dates?.find((date: HistoryDate) => {
                const isSameDay = moment(date.day).isSame(moment(el.labelBottom), 'day');
                const isLastItem = daysCells.length === id + 1;

                if (isLastItem && isSameDay) {
                  return null;
                }

                if (isSameDay) {
                  return date;
                }
              });

              const summaryCurrentWeekCellChangeFilter = (taxId: string) => {
                if (foundWeek) {
                  formik.setFieldValue(HISTORY_FIELDS.DATES, foundWeek.label);
                  formik.setFieldValue(HISTORY_FIELDS.SELECTED_DATE, foundWeek.value);
                  formik.setFieldValue(HISTORY_FIELDS.TAX_ID, taxId);
                }
              };

              const summaryHistoryCellChangeFilter = (taxId: string) => {
                if (foundWeek) {
                  formik.setFieldValue(HISTORY_FIELDS.DATES, foundWeek.label);
                  formik.setFieldValue(HISTORY_FIELDS.SELECTED_DATE, foundWeek.value);
                  formik.setFieldValue(HISTORY_FIELDS.TAX_ID, taxId);
                  refreshTable();
                }
              };

              if (isSummaryView) {
                if (el.id === FIELDS.CURRENT_WEEK) {
                  const textStyles = getTextStyles(row.currentValue, row.type);
                  return (
                    <TableCell
                      onClick={() => summaryCurrentWeekCellChangeFilter(row.taxIdentificationNumber?.replace('PL', ''))}
                      id={dayDataId}
                      sx={{ ...styles, cursor: 'pointer', textAlign: textStyles.align, color: textStyles.color }}
                      className={classes.newValue}
                      key={el.id}>
                      {newValue(row.currentValue, row.type, true)}
                    </TableCell>
                  );
                }
                const value = newValue(row?.entries?.[id]?.newValue, row.type, false);
                const isEmpty = value === '';
                const textStyles = getTextStyles(row?.entries?.[id]?.newValue, row.type);

                return (
                  <TableCell
                    onClick={
                      isEmpty
                        ? undefined
                        : () => summaryHistoryCellChangeFilter(row.taxIdentificationNumber?.replace('PL', ''))
                    }
                    id={dayDataId}
                    sx={{
                      ...styles,
                      textAlign: textStyles.align,
                      color: textStyles.color,
                      ...(isEmpty ? {} : { cursor: 'pointer' })
                    }}
                    className={classes.newValue}
                    key={el.id}>
                    {value}
                  </TableCell>
                );
              } else {
                if (foundDate) {
                  const textStyles = getTextStyles(foundDate.newValue, row.type);
                  return (
                    <TableCell
                      id={dayDataId}
                      sx={{ ...styles, textAlign: textStyles.align, color: textStyles.color }}
                      className={classes.newValue}
                      key={el.id}>
                      {newValue(foundDate.newValue, row.type, false)}
                    </TableCell>
                  );
                }
                if (el.id === FIELDS.DAY_CURRENT) {
                  const textStyles = getTextStyles(row.currentValue, row.type);
                  return (
                    <TableCell
                      id={dayDataId}
                      sx={{ ...styles, textAlign: textStyles.align, color: textStyles.color }}
                      className={classes.newValue}
                      key={el.id}>
                      {newValue(row.currentValue, row.type, true)}
                    </TableCell>
                  );
                } else {
                  return <TableCell id={dayDataId} sx={styles} key={el.id}></TableCell>;
                }
              }
            })}
          </TableRow>
        );
      })}
    </>
  );
});

export default Rows;
