import { Box } from '@mui/system';
import { Dialog } from 'components';
import { logout } from 'lib/utils';
import { useAuth } from 'oidc-react';
import { connectionSelector } from 'store/connection';
import { useSelector } from 'react-redux';

const AccessDenied = () => {
  const auth = useAuth();
  const connection = useSelector(connectionSelector);

  return (
    <Dialog
      isOpen={true}
      closeModal={() => {}}
      accept={() => {
        logout(auth, connection);
      }}
      acceptLabel="Wyloguj"
      closeLabel="">
      <Box sx={{ display: 'flex', margin: '0 0 1rem 0', fontWeight: 'bold', justifyContent: 'center' }}>
        Brak uprawnień dostępu do pełnego serwisu RiskRadar.
      </Box>
      Twoje obecne uprawnienia nie pozwalają na dostęp do serwisu RiskRadar. <br />
      Uprawnieniami do RiskRadar zarządza Administrator RiskRadar w Twojej firmie. <br />
      Skontaktuj się z tą osobą, jeśli uważasz, że Twoje uprawnienia są nieodpowiednie.
    </Dialog>
  );
};

export default AccessDenied;
