import { createReducer } from '@reduxjs/toolkit';
import { setSuccessMessage, resetSuccessMessage } from './thunks';

const initialState = {
  message: ''
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSuccessMessage, (state, action) => {
      state.message = action.payload;
    })
    .addCase(resetSuccessMessage, (state, action) => {
      state.message = '';
    });
});

export default reducer;

export type ReportStateType = ReturnType<typeof reducer>;
