import { createTheme } from '@mui/material/styles';
import { ThemeType, ColorType } from 'types';

export const theme: ThemeType = {
  colors: {
    white: ColorType.white
  }
};

export const muiTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px'
        }
      }
    }
  }
});
