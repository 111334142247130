import { FC, useEffect } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Props, BC_MESSAGE_STATUS_CLOSED } from './ImportToast.types';
import { Link } from 'react-router-dom';
import { PATH } from 'router/routes';
import { useLocation } from 'react-router-dom';
import classes from './styles.module.scss';

const ImportToast: FC<Props> = ({ message, closeCallback }) => {
  const bc = new BroadcastChannel('reportToast');

  const closeSnackBar = (_: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (closeCallback instanceof Function) {
      closeCallback();
    }

    bc.postMessage(BC_MESSAGE_STATUS_CLOSED);
  };

  const { pathname } = useLocation();

  const isCsvListPage = pathname === PATH.CSV_LIST;

  const messageElement = (
    <span className={classes.messageContainer}>
      <span>{message}. </span>
      <Link
        target="_blank"
        className={`${classes.link} ${isCsvListPage ? classes.linkDisabled : ''}`}
        to={PATH.CSV_LIST}
        aria-disabled={isCsvListPage ? 'true' : 'false'}>
        Sprawdź wynik w zakładce "Import danych - CSV"
      </Link>
    </span>
  );

  useEffect(() => {
    bc.addEventListener('message', (message) => {
      if (message?.data === BC_MESSAGE_STATUS_CLOSED) {
        if (closeCallback instanceof Function) {
          closeCallback();
        }
      }
    });
  }, []);

  return (
    <MuiSnackbar
      open={true}
      onClose={closeSnackBar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ maxWidth: '40rem' }}>
      <Alert onClose={closeSnackBar} severity="success" variant="filled" sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ color: 'inherit' }}>
          {messageElement}
        </Typography>
      </Alert>
    </MuiSnackbar>
  );
};

export default ImportToast;
