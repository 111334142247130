import { FC, memo } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { FIELDS } from '../usersList.types';
import { TableHeadProps } from 'types';

const TableHead: FC<TableHeadProps<FIELDS>> = memo(({ order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property: FIELDS) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            id={`user-table-head-${headCell.id}`}
            sx={{
              position: 'relative',
              padding: '1rem 1.5rem',
              whiteSpace: 'nowrap',
              width: '12.5%',
              minWidth: '197px',
              '&:not(:last-child):after': {
                content: "''",
                width: '2px',
                height: '14px',
                background: '#E0E0E0',
                right: '0',
                top: '1.375rem',
                position: 'absolute'
              }
            }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
              hideSortIcon={!headCell.sortable}
              sx={{
                pointerEvents: headCell.sortable ? 'all' : 'none'
              }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
});

export default TableHead;
