import { Provider } from 'react-redux';
import { UserManager, AuthProvider } from 'oidc-react';
import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createStore from './store';
import Router from 'router';
import { theme, muiTheme } from 'config/styles/theme';
import { userManagerConfig } from 'config/oidc';
import { Helmet } from 'react-helmet-async';
import { PATH } from 'router/routes';
import { useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'simplebar/dist/simplebar.min.css';
import { LOCAL_STORAGE_ACTIONS } from 'components/ActivityDetector/ActivityDetector.types';

const store = createStore();
const noRedirectUrls = [PATH.SESSION_EXPIRED, PATH.SHOW_REPORT];

const userManager = new UserManager({
  ...userManagerConfig
});

userManager.events.addUserLoaded(async function (user) {
  localStorage.setItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN, user?.access_token);
});

userManager.events.addAccessTokenExpired(async function () {
  if (!noRedirectUrls.find((item) => item === window.location.pathname)) {
    localStorage.removeItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);
  }
});

userManager.events.addUserSignedOut(async function () {
  if (
    !noRedirectUrls.find((item) => item === window.location.pathname) &&
    !localStorage.getItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN)
  ) {
    localStorage.removeItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);
    window.location.href = PATH.SESSION_EXPIRED;
  }
});

export enum NotificationType {
  CompanyReportGenerated = 1,
  UserReportGenerated = 2,
  UserImportCompleted = 3,
  UserImportResultUpdated = 4,
  UserCustomerListExportResultUpdated = 5,
  UserChangeHistoryExportResultUpdated = 6
}

export type ReportNotificationData = {
  reportGeneratedAt: string;
  reportRequestId: number;
  taxIdentificationNumber: string;
};

export type PushNotification<DataType = object> = {
  message: string;
  type: NotificationType;
  data: DataType;
};

export type ReportGeneratedData = {
  TaxIdentificationNumber: string;
  ReportRequestId: number;
};

export type ExportNotificationData = {
  exportStatus: ExportStatus;
};

export enum ExportStatus {
  Created = 1,
  Finished = 10
}

const App = () => {
  useEffect(() => {
    const expiredSessionHandler = async () => {
      const user = await userManager.getUser();
      if (user && (user.expired === true || user.expired === undefined)) {
        if (!noRedirectUrls.find((item) => item === window.location.pathname)) {
          localStorage.removeItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);
        }
      }
    };

    expiredSessionHandler();
  }, []);

  return (
    <Provider store={store}>
      <Helmet>
        <title>RiskRadar</title>
      </Helmet>
      <AuthProvider
        userManager={userManager}
        autoSignIn={false}
        onSignIn={(user: any) => {
          localStorage.setItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN, user.access_token);
          const lastPage = sessionStorage.getItem('lastPage') || process.env.REACT_APP_REDIRECT_URI || '';
          window.location.href = lastPage; // restore last page from session storage
        }}
        onSignOut={() => {
          localStorage.removeItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);
        }}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </MuiThemeProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
