export enum FIELDS {
  FULL_NAME = 'FullName',
  ROLE = 'Role',
  COMPANY_NAME = 'CompanyName',
  TAX_NUMBER = 'CompanyTaxIdentificationNumber',
  INTEGRATOR_NAME = 'IntegratorName',
  ERP_NAME = 'ErpName',
  ACTION = 'Action',
  IS_ACTIVE = 'IsActive',
  COMPANY_ID = 'CompanyId',
  INTEGRATOR_ID = 'IntegratorId',
  COMPANY_ROLE = 'Role',
  EMAIL = 'Email',
  IS_COMPANY_ACTIVE = 'IsCompanyActive'
}

export type FieldType = {
  name: FIELDS | '';
  value: string;
  error?: boolean;
  helperText?: string | false;
};

export type FormikValues = {
  [FIELDS.FULL_NAME]: string;
  [FIELDS.COMPANY_NAME]: string;
  [FIELDS.TAX_NUMBER]: string;
  [FIELDS.INTEGRATOR_NAME]: string;
  [FIELDS.ERP_NAME]: string;
  [FIELDS.IS_ACTIVE]?: boolean;
  [FIELDS.COMPANY_ID]: string | number;
  [FIELDS.INTEGRATOR_ID]: string | number;
  [FIELDS.COMPANY_ROLE]: string | number;
};
