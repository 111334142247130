import { ReportHistoryItemDto } from 'api/models';

export enum FIELDS {
  CLIENT_NAME = 'counterpartyName',
  TAX_ID = 'taxIdentificationNumber',
  USER_NAME = 'requesterUserName',
  DOWNLOADED_AT = 'generatedAt',
  DOWNLOAD = 'reportRequestId',
  STATUS = 'state'
}

export type HeadCell<T> = {
  id: T;
  label: string;
  numeric: boolean;
  sortable: boolean;
};

export type Row = {
  [FIELDS.TAX_ID]: string;
  [FIELDS.CLIENT_NAME]: string;
  [FIELDS.USER_NAME]: string;
  [FIELDS.DOWNLOADED_AT]: string;
  [FIELDS.DOWNLOAD]: number;
  [FIELDS.STATUS]: number;
};

export enum STATUS {
  COMPLETED = 2,
  IN_PROGRESS = 1
}

export type DataExportResults = {
  results: ReportHistoryItemDto[];
  totalSize: number;
};
export interface ReportsHistoryFormikValues {
  [FIELDS.CLIENT_NAME]: string;
  [FIELDS.TAX_ID]: string;
}
