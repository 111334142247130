import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { MonitoringCounterStateType } from './reducer';
import { RequestStatus } from '../Store.types';

const selectState = ({ monitoringCounter }: RootState) => monitoringCounter;

export const monitoringCounterSelector = createSelector(selectState, (state: MonitoringCounterStateType) => state.value);

const mapStatus = (monitoringCounter: MonitoringCounterStateType): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = monitoringCounter;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

export const monitoringCounterStatusSelector = createSelector(selectState, (monitoringCounter) => mapStatus(monitoringCounter));