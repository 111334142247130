import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit';
import { isPendingAction, isFulfilledAction, isRejectedAction } from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';

export interface MonitoringCounterState extends RequestStatus {
  value: number;
}

const initialState: MonitoringCounterState = {
  ...statusNotAsked,
  value: 0
};

export enum monitoringCounterActions {
  increment = 'monitoringCounter/increment',
  decrement = 'monitoringCounter/decrement',
  incrementByAmount = 'monitoringCounter/incrementByAmount',
};

const increment = createAction(monitoringCounterActions.increment)
const decrement = createAction(monitoringCounterActions.decrement)
const incrementByAmount = createAction<number>(monitoringCounterActions.incrementByAmount)

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(increment, (state, action) => {
      state.value++
    })
    .addCase(decrement, (state, action) => {
      state.value--
    })
    .addCase(incrementByAmount, (state, action) => {
      state.value += action.payload
    })
    .addMatcher(isPendingAction, (state: MonitoringCounterState) => ({
      ...state,
      ...statusIsLoading
    }))
    .addMatcher(isFulfilledAction, (state: MonitoringCounterState, action: PayloadAction<number>) => ({
      ...state,
      ...statusIsSuccess,
      value: action.payload
    }))
    .addMatcher(isRejectedAction, (state: MonitoringCounterState) => ({
      ...state,
      ...statusIsFailure
    }));
});

export type MonitoringCounterStateType = ReturnType<typeof reducer>;
