import { SCORING_ID, SCORING_STATE } from './ScoringDto';
import { TradeCreditLimitDto } from './TradeCreditLimitDto';

export enum MONITORING_STATE {
  NOT_EXIST = 0,
  CREATED = 1, // Added to B360 database
  ADDED_IN_MPRO = 5, //Processed successfully by MPRO. Initial debt amount is not yet imported from Chase Api. Monitoring in this state will not gather Monitoring Events from MPRO
  ENABLED = 10, // Processed successfully by MPRO, initial debt amount imported from Chase Api. Monitoring in this state will gather Monitoring Events from MPRO
  DISABLING = 20, // Disabled in B360 database, but waiting for stopping Monitoring in MPRO
  DISABLED = 30, // Monitoring disabled in B360 database and stopped in MPRO
  ERROR_ENABLING = 500, // There was an error while creating Monitoring in MPRO
  ERROR_DISABLING = 501, // There was an error while disabling Monitoring in MPRO
  ERROR_GETTING_DEBT = 502, // There was an error while trying to get debt amount info from Chase API (monitoring already added to MPRO)
  ERROR_GETTING_DEBT_FINAL = 503
}

export enum REPORT_STATE {
  PENDING = 1,
  CREATED = 2
}

export type CounterpartySearchItemDto = {
  id: number;
  name?: string;
  taxIdentificationNumber: string;
  monitoringState?: MONITORING_STATE;
  monitoringId?: string;
  isMonitoringRetry?: boolean;
  lastMonitoringEventDate?: Date;
  lastDebtUpdatedDate?: Date;
  lastDebtSynchronizationDate?: Date;
  debtAmountPln?: number;
  reportId?: number;
  reportState?: REPORT_STATE;
  reportGeneratedAt?: string;
  debtValueFrom?: string;
  debtValueTo?: string;
  report?: string;
  scoringId?: SCORING_ID;
  scoringState?: SCORING_STATE;
  scoringStatusCode?: string;
  scoringStatus?: string;
  riskGroupCode: string;
  riskGroup: string;
  tradeCreditLimitAuthorizationErrorState: TradeCreditLimitAuthorizationErrorState;
  tradeCreditLimitValue: number;
  tradeCreditLimitModel: string;
  tradeCreditLimitStatus: string;

  source?: number;
  grossDebt?: number;
  grossSaleLast12Months?: number;
  isImportOutdated?: boolean;
  importDate?: string;
  monitoringEndDate?: Date | string;
  description1: string;
  description2: string;
};

export type CustomerDetailsDto = {
  id: number;
  name?: string;
  taxIdentificationNumber?: string;
  street?: string;
  buildingNumber?: string;
  apartmentNumber?: string;
  postcode?: string;
  city?: string;
  clientIdErp: string;
  monitoringSummary: MonitoringSummaryDto;
  reportDetails: ReportDetailsDto[];
  source?: number;
  grossSaleLast12Months?: number;
  grossDebt?: number;
  importDate?: Date;
  isOutdated?: boolean;
  scoring?: ScoringDto;
  description1: string;
  description2: string;
  tradeCreditLimit: TradeCreditLimitDto;
};

export type MonitoringSummaryDto = {
  monitoringId: string;
  monitoringState?: MONITORING_STATE;
  isDebtor: boolean;
  debt: number;
  lastDebtSynchronizationDate?: string;
  lastMonitoringEventDate?: string;
  monitoringEndDate?: string;
  monitoringEnableRequestedAt?: string;
  isRetry: boolean;
};

export type ReportDetailsDto = {
  id: number;
  generatedAt?: string;
  state?: REPORT_STATE;
};

export type FilterConfigurationResults = {
  maxDebtValue: string;
};

export type FilterConfigurationsDto = {
  result: FilterConfigurationResults;
};

export type monitoringEvent = {
  id: number;
  name: string;
  date: string;
};

export type MonitoringEventsDto = {
  monitoringEvents?: monitoringEvent[];
};

export type ScoringDto = {
  riskGroup: string;
  riskGroupCode: string;
  scoringId: number;
  scoringStatus: string;
  scoringStatusCode: string;
  state: number;
  tradeCreditLimitAuthorizationErrorState: TradeCreditLimitAuthorizationErrorState;
  calculatedAt: string;
};

export enum TradeCreditLimitAuthorizationErrorState {
  None = 0,
  Forbidden = 1,
  MissingService = 2
}

export enum SourceTypes {
  Manual = 1,
  Csv = 2
}
