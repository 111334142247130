import { CounterpartySearchItemDto } from 'api/models';
import { IMPORT_TYPE } from 'domains/csv/csvList/csvList.types';
import { CUSTOMER_FIELDS, CustomerFormikValues } from '../customersList.types';

export const createRows = (customersData?: CounterpartySearchItemDto[]) => {
  if (!customersData) return [];
  return customersData.map((result) => ({
    id: result.id,
    lastDebtSynchronizationDate: result.lastDebtSynchronizationDate,
    [CUSTOMER_FIELDS.NAME]: result.name || '-',
    [CUSTOMER_FIELDS.TAX_NUMBER]: result.taxIdentificationNumber || '-',
    [CUSTOMER_FIELDS.MONITORING_STATE]: result.monitoringState,
    [CUSTOMER_FIELDS.MONITORING_ID]: result.monitoringId,
    [CUSTOMER_FIELDS.IS_MONITORING_RETRY]: result.isMonitoringRetry,
    [CUSTOMER_FIELDS.LAST_MONITORING_EVENT_DATE]: result.lastMonitoringEventDate,
    [CUSTOMER_FIELDS.DEBT_AMOUNT_PLN]: result.debtAmountPln,
    [CUSTOMER_FIELDS.REPORT]: result.reportId,
    [CUSTOMER_FIELDS.STATE]: result?.reportState,
    [CUSTOMER_FIELDS.REPORT_GENERETED_AT]: result.reportGeneratedAt,
    [CUSTOMER_FIELDS.DEBT_VALUE_FROM]: result.debtValueFrom,
    [CUSTOMER_FIELDS.DEBT_VALUE_TO]: result.debtValueTo,
    [CUSTOMER_FIELDS.RISK_GROUP]: result.riskGroup,
    [CUSTOMER_FIELDS.RISK_GROUP_CODE]: result.riskGroupCode,
    [CUSTOMER_FIELDS.SCORING_ID]: result.scoringId,
    [CUSTOMER_FIELDS.IS_IMPORT_OUTDATED]: result.isImportOutdated,
    [CUSTOMER_FIELDS.IMPORT_DATE]: result.importDate,
    [CUSTOMER_FIELDS.SCORING_STATE]: result.scoringState,
    [CUSTOMER_FIELDS.SCORING_STATUS]: result.scoringStatus,
    [CUSTOMER_FIELDS.SCORING_STATUS_CODE]: result.scoringStatusCode,
    [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_AUTHORIZATION_ERROR_STATE]: result.tradeCreditLimitAuthorizationErrorState,
    [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_VALUE]: result.tradeCreditLimitValue,
    [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_MODEL]: result.tradeCreditLimitModel,
    [CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT_STATUS]: result.tradeCreditLimitStatus,
    [CUSTOMER_FIELDS.GROSS_DEBT]:
      result.grossDebt === null || result.source === IMPORT_TYPE.Manual
        ? 'Brak danych'
        : `${result.grossDebt?.toFixed(2)} PLN`,
    [CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS]:
      result.grossSaleLast12Months === null || result.source === IMPORT_TYPE.Manual
        ? 'Brak danych'
        : `${result.grossSaleLast12Months?.toFixed(2)} PLN`,
    [CUSTOMER_FIELDS.MONITORING_END_DATE]: result?.monitoringEndDate,
    [CUSTOMER_FIELDS.DESCRIPTION1]: result?.description1,
    [CUSTOMER_FIELDS.DESCRIPTION2]: result?.description2
  }));
};

export const createFiltersQuery = (
  Name?: string,
  Report?: boolean,
  LastDebt?: number,
  LastEvent?: number,
  RiskGroupIds?: number[]
) => {
  let filtersQuery = '';

  if (Name) {
    filtersQuery += `&${CUSTOMER_FIELDS.NAME}=${Name}`;
  }

  if (Report) {
    filtersQuery += `&${CUSTOMER_FIELDS.REPORT}=${Report}`;
  }

  if (LastDebt) {
    filtersQuery += `&${CUSTOMER_FIELDS.LAST_DEBT}=${LastDebt}`;
  }

  if (LastEvent) {
    filtersQuery += `&${CUSTOMER_FIELDS.LAST_EVENT}=${LastEvent}`;
  }

  if (RiskGroupIds) {
    const prepareQuery = RiskGroupIds.map((group) => `&${CUSTOMER_FIELDS.RISK_GROUP_IDS}=${group}`).join('');

    filtersQuery += prepareQuery;
  }

  return filtersQuery;
};

export const createSearchQuery = (
  values: CustomerFormikValues,
  searchParams: URLSearchParams,
  sessionFilters: string,
  searchFieldName?: CUSTOMER_FIELDS
) => {
  const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
  const keys = Object.keys(parsedSessionFilters);
  let searchArray: string[] = [];

  keys.forEach((key) => {
    if (parsedSessionFilters[key as keyof typeof parsedSessionFilters]) {
      switch (key) {
        case CUSTOMER_FIELDS.NAME:
          searchArray.push(`${CUSTOMER_FIELDS.NAME};contains;${parsedSessionFilters[key].trim()}`);
          searchParams.set(key, parsedSessionFilters[key].trim());
          break;
        case CUSTOMER_FIELDS.TAX_NUMBER:
          searchArray.push(`${CUSTOMER_FIELDS.TAX_NUMBER};contains;${parsedSessionFilters[key].trim()}`);
          searchParams.set(key, parsedSessionFilters[key].trim());
          break;
        case CUSTOMER_FIELDS.MONITORING_STATE:
          searchArray.push(`${CUSTOMER_FIELDS.MONITORING_STATE};contains;${parsedSessionFilters[key]}`);
          break;
        case CUSTOMER_FIELDS.REPORT:
          searchArray.push(`${CUSTOMER_FIELDS.REPORT};contains;${parsedSessionFilters[key]}`);
          break;
        case CUSTOMER_FIELDS.COMPANY_ID:
          searchArray.push(`${CUSTOMER_FIELDS.COMPANY_ID};contains;${parsedSessionFilters[key]}`);
          break;
        case CUSTOMER_FIELDS.REPORT:
          searchArray.push(`${CUSTOMER_FIELDS.REPORT};contains;${parsedSessionFilters[key]}`);
          break;
        case CUSTOMER_FIELDS.DESCRIPTION1:
          searchArray.push(`${CUSTOMER_FIELDS.DESCRIPTION1};contains;${parsedSessionFilters[key].trim()}`);
          searchParams.set(key, parsedSessionFilters[key].trim());
          break;
        case CUSTOMER_FIELDS.DESCRIPTION2:
          searchArray.push(`${CUSTOMER_FIELDS.DESCRIPTION2};contains;${parsedSessionFilters[key].trim()}`);
          searchParams.set(key, parsedSessionFilters[key].trim());
          break;
      }
    }
  });

  if (searchFieldName) {
    const searchValueExist = searchArray.some((el: string) => el.includes(`${searchFieldName};contains`));
    if (searchValueExist) {
      searchArray = searchArray.filter((el: string) => !el.includes(`${searchFieldName};contains`));
    }
    searchArray.push(`${searchFieldName};contains;${values[searchFieldName as keyof typeof values]}`);
  }

  return encodeURIComponent(searchArray.join('|'));
};

export const mapOrderBy = (orderBy: CUSTOMER_FIELDS) => {
  switch (orderBy) {
    case CUSTOMER_FIELDS.NAME:
      return 'Name';
    case CUSTOMER_FIELDS.DESCRIPTION1:
      return 'description1';
    case CUSTOMER_FIELDS.DESCRIPTION2:
      return 'description2';
    case CUSTOMER_FIELDS.TAX_NUMBER:
      return 'CompanyTaxId';
    case CUSTOMER_FIELDS.GROSS_DEBT:
      return 'GrossDebt';
    case CUSTOMER_FIELDS.GROSS_SALE_LAST_12_MONTHS:
      return 'GrossSaleLast12Months';
    case CUSTOMER_FIELDS.SCORING:
      return 'debtAmountPln';
    case CUSTOMER_FIELDS.TRADE_CREDIT_LIMIT:
      return 'tradeCreditLimitValue';
  }
};

export const createExportToCsvSearchFilters = (searchParams: URLSearchParams) => {
  const name = searchParams.get(CUSTOMER_FIELDS.NAME);
  const taxNumber = searchParams.get(CUSTOMER_FIELDS.TAX_NUMBER);
  const description1 = searchParams.get(CUSTOMER_FIELDS.DESCRIPTION1);
  const description2 = searchParams.get(CUSTOMER_FIELDS.DESCRIPTION2);

  const searchFiltersArray = [
    {
      name: CUSTOMER_FIELDS.NAME,
      value: name
    },
    {
      name: CUSTOMER_FIELDS.TAX_NUMBER,
      value: taxNumber
    },
    {
      name: CUSTOMER_FIELDS.DESCRIPTION1,
      value: description1
    },
    {
      name: CUSTOMER_FIELDS.DESCRIPTION2,
      value: description2
    }
  ];

  let searchFiltersString = '';

  searchFiltersArray.forEach((el) => {
    if (el.value) {
      searchFiltersString += `${searchFiltersString ? '|' : ''}${el.name};contains;${el.value}`;
    }
  });

  return searchFiltersString;
};
