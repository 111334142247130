import { FC } from 'react';
import { IconProps } from '../Icons.types';

const MonitoringIcon: FC<IconProps> = ({ color }) => {
  return (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M11.8027 4.56684C11.6052 4.31339 11.2403 4.26839 10.9874 4.46551L7.50305 7.17999L6.66838 5.90491C6.58245 5.7734 6.44658 5.68224 6.29213 5.65262C6.13797 5.62243 5.97772 5.65698 5.8494 5.74727L2.46226 8.12149C2.19981 8.30555 2.13594 8.66757 2.32 8.93031C2.43294 9.09144 2.61293 9.17766 2.79612 9.17766C2.91109 9.17766 3.02751 9.1437 3.12883 9.07257L6.02533 7.04267L6.87712 8.34358C6.96596 8.47945 7.10763 8.57177 7.2676 8.59848C7.42844 8.6249 7.59188 8.58338 7.71962 8.48351L11.7016 5.38176C11.9545 5.18463 11.9998 4.81999 11.8027 4.56684Z" 
      fill={color}/>
    <path 
      d="M17.8296 17.0086L12.459 11.6383C13.5059 10.405 14.1393 8.81056 14.1393 7.07011C14.1393 3.17143 10.9679 0 7.06982 0C3.17143 0 0 3.17143 0 7.07011C0 10.9682 3.17143 14.1396 7.06982 14.1396C8.81027 14.1396 10.4047 13.5062 11.638 12.4593L17.0086 17.8296C17.1221 17.9431 17.2704 17.9997 17.4191 17.9997C17.5677 17.9997 17.7161 17.9431 17.8296 17.8296C18.0563 17.6028 18.0563 17.2353 17.8296 17.0086ZM1.16127 7.07011C1.16127 3.81187 3.81158 1.16127 7.06982 1.16127C10.3275 1.16127 12.9781 3.81187 12.9781 7.07011C12.9781 10.3281 10.3275 12.9784 7.06982 12.9784C3.81158 12.9784 1.16127 10.3278 1.16127 7.07011Z" 
      fill={color}/>
  </svg>
  );
};

export default MonitoringIcon;