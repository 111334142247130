import { Button } from '@mui/material';
import { FC } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from './UploadButton.styled';

const UploadButton: FC<any> = ({ value, onInput, onClick, disabled, accept, text, id }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Button variant="contained" disabled={disabled} component="label" id={id}>
        <AddCircleOutlineIcon sx={{ margin: '0 .5rem 0 0' }} /> {text}
        <input
          value={value}
          accept={accept}
          disabled={disabled}
          style={{ display: 'none' }}
          id="contained-button-file"
          multiple
          type="file"
          onClick={onClick}
          onInput={disabled ? () => {} : onInput}
          hidden
        />
      </Button>
    </MuiThemeProvider>
  );
};

export default UploadButton;
