import { Paper, Breadcrumbs } from 'components';
import { createReport } from 'api/services';
import { initialValues } from './config';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { ReportForm } from '../shared';

const breadcrumbs = [{ label: 'Kreator raportów cyklicznych' }];

const NewReport = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportForm initialValues={initialValues} submitHandler={createReport} />
      </Paper>
    </Box>
  );
};

export default NewReport;
