import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Props } from './Header.types';
import { Pagination, Tooltip } from 'components';

const Header: FC<Props> = ({
  children,
  title,
  tooltipText,
  tooltipLink,
  justifyContent,
  paginationTotalSize,
  paginationPageSize,
  onRowsPerPageChange,
  hidePagination,
  paginationRowsPageSizeOptions,
  paginationRowsPerPageLocaleStorageName
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: justifyContent ? justifyContent : 'flex-start',
        borderBottom: '1px solid #E0E0E0',
        padding: '1rem 0 1rem 1rem',
        flexShrink: '0'
      }}>
      <Typography variant="h5" component="h1" fontWeight="500">
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 1rem 0 0' }}>
          {title}
          {tooltipText && <Tooltip text={tooltipText} link={tooltipLink} />}
        </Box>
      </Typography>
      {children}
      {!hidePagination ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: '1', alignItems: 'center' }}>
          {paginationPageSize && onRowsPerPageChange && (
            <Pagination
              options={paginationRowsPageSizeOptions}
              total={paginationTotalSize || 1}
              pageSize={paginationPageSize}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPageLocaleStorageName={paginationRowsPerPageLocaleStorageName}
            />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default Header;
