import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Breadcrumbs, Loader } from 'components';
import { PATH } from 'router/routes';
import { updateCustomer, getCustomer } from 'api/services';
import { CustomerDetailsDto } from 'api/models';
import { useFetch } from 'lib/hooks';
import { ClientForm, ClientFormTypes } from '../shared';
import { Box } from '@mui/material';
import SideNav from 'components/SideNav';
import classes from '../../../App.module.scss';
import { Helmet } from 'react-helmet-async';

const EditCustomer = () => {
  const { id } = useParams();

  const { result, isLoading } = useFetch<CustomerDetailsDto>(useCallback(() => getCustomer(id || ''), [id]));
  const breadcrumbs = [{ to: PATH.USERS_LIST, label: 'Użytkownicy i uprawnienia' }, { label: result?.name || '' }];

  const valuesForForm: ClientFormTypes.FormikValues = {
    [ClientFormTypes.FIELDS.NAME]: result?.name || '',
    [ClientFormTypes.FIELDS.CITY]: result?.city || '',
    [ClientFormTypes.FIELDS.ID]: result?.id,
    [ClientFormTypes.FIELDS.STREET]: result?.street || '',
    [ClientFormTypes.FIELDS.BUILDING_NUMBER]: result?.buildingNumber || '',
    [ClientFormTypes.FIELDS.APARTAMENT_NUMBER]: result?.apartmentNumber || '',
    [ClientFormTypes.FIELDS.POSTCODE]: result?.postcode || '',
    [ClientFormTypes.FIELDS.TAX_NUMBER]: result?.taxIdentificationNumber || '',
    [ClientFormTypes.FIELDS.DESCRIPTION_1]: result?.description1 || '',
    [ClientFormTypes.FIELDS.DESCRIPTION_2]: result?.description2 || ''
  };

  const handleSubmit = useCallback((values: ClientFormTypes.FormikValues) => updateCustomer(id || '', values), [id]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>RiskRadar - {result?.name ?? ''}</title>
      </Helmet>
      <Box className={classes.sidebar}>
        <SideNav />
      </Box>
      <Paper fullSize>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Loader isLoading={isLoading}>
          <ClientForm inEditMode initialValues={valuesForForm} submitHandler={handleSubmit} />
        </Loader>
      </Paper>
    </Box>
  );
};

export default EditCustomer;
