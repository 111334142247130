import { FC } from "react";
import { TutorialContentSubsectionProps } from "../types/TutorialSection.types";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { HashLink } from 'react-router-hash-link';
import styles from "./tutorial.module.scss";

const TutorialSubsection: FC<TutorialContentSubsectionProps> = ({ id, name, index, content }) => {
    return (
        <div className={styles.subsection} id={id}>
            <div className={styles.subsectionHeader}>
                <span style={{ color: '#078A51', marginRight: "0.5rem" }}>{index}&nbsp;</span> {name}

                <HashLink smooth to={`#${id}`}>
                    <InsertLinkIcon fontSize="medium" sx={{ color: '#6a6a6a', marginLeft: "1rem", cursor: "pointer" }} />
                </HashLink>
            </div>
            {content()}
        </div >);
};

export default TutorialSubsection;

