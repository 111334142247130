import { useState, useCallback } from 'react';
import { AxiosPromise } from 'axios';
import { useDispatch } from 'react-redux';
import { ApiResponse, ValidationError } from 'api/models';
import { setErrors, resetErrors } from 'store/errors';
import useToggle from './useToggle';

const useSendRequest = <T,>() => {
  const [isLoading, toggleLoading] = useToggle();
  const [result, setResult] = useState<T | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const [errorMessage, setErrorMessage] = useState<ValidationError[]>([]);

  const dispatch = useDispatch();

  const sendData = useCallback(
    (runRequest: () => AxiosPromise<ApiResponse<T>>, callback?: () => void, errorCallback?: () => void) => {
      toggleLoading();
      setResult(null);
      dispatch(resetErrors());
      setValidationErrors([]);

      const sendRequest = async () => {
        try {
          const res = await runRequest();
          setResult(res.data.Result);
          if (callback instanceof Function) callback();
        } catch (err: any) {
          if (Array.isArray(err?.data?.Errors))
            dispatch(
              setErrors({
                Errors: err.data?.Errors,
                ErrorId: err.data?.ErrorId
              })
            );
          setErrorMessage(err?.data?.Errors);
          if (Array.isArray(err?.data?.ValidationErrors)) setValidationErrors(err?.data?.ValidationErrors);
          if (errorCallback instanceof Function) errorCallback();
        } finally {
          toggleLoading();
        }
      };

      sendRequest();
    },
    [toggleLoading, dispatch]
  );

  const clearResult = () => {
    setResult(null);
    setValidationErrors([]);
    setErrorMessage([]);
  };

  return {
    isLoading,
    result,
    errorMessage,
    validationErrors,
    sendData,
    setValidationErrors,
    clearResult
  };
};

export default useSendRequest;
