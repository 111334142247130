import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';

const useGoToFirstPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToFirstPage = (path: string, params?: URLSearchParams) => {
    const mergedParams = createSearchParams(location.search);
    mergedParams.set('page', '1');
    const propsPage = params?.get('page');
    if (propsPage !== '1') {
      params?.set('page', '1');
    }
    navigate({ pathname: path, search: `${params ? params : mergedParams}` });
  };

  return {
    goToFirstPage
  };
};

export default useGoToFirstPage;
