import { CsvDto } from 'api/models';
import { FIELDS } from '../csvList.types';

export const createRows = (csv?: CsvDto[]) => {
  if (!csv) return [];

  return csv.map((result) => ({
    [FIELDS.IMPORT_ID]: result.importId,
    [FIELDS.FILENAME]: result.filename,
    [FIELDS.STATUS]: result.status,
    [FIELDS.RECORDS]: result.recordCount || '',
    [FIELDS.USER]: result.user,
    [FIELDS.UPLOAD_TIME]: result.uploadTime,
  }));
};