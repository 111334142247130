import { ExportDataResultDto } from 'api/models';
import { multiCheckboxField } from 'components/MultiCheckbox/MultiCheckbox.types';

export enum FIELDS {
  TYPE = 'type',
  GENERATED_AT = 'generatedAt',
  REQUESTED_BY_USER = 'requestedByUser',
  STATUS = 'status',
  ID = 'id',
  FILENAME = 'filename',
  TYPES = 'types'
}

export enum STATUS {
  COMPLETED = 10,
  IN_PROGRESS = 1
}

export enum EXPORT_TYPES {
  USER_LIST = 1,
  HISTORY_CHANGES = 2
}

export type DataExportResults = {
  results: ExportDataResultDto[];
  totalSize: number;
};

export const exportType: multiCheckboxField<FIELDS.TYPE>[] = [
  {
    setFieldValue: FIELDS.TYPE,
    value: '1',
    label: 'Lista klientów'
  },
  {
    setFieldValue: FIELDS.TYPE,
    value: '2',
    label: 'Historia zmian'
  }
];
