import { FC } from 'react';
import Box from '@mui/material/Box';
import { Props } from './SaleDebtItem.types';
import { Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { tooltipOldData } from 'domains/customer/customerProfile';
import moment from 'moment';
import { numberWithSpace } from 'lib/utils';

const SaleDebtItem: FC<Props> = (data) => {
  const { isImportOutdated, importDate, value } = data;
  const parsedValue = (value as string)?.split('PLN')?.[0];

  let result = null;
  if (isImportOutdated && importDate) {
    result = (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '2.5rem',
          whiteSpace: 'nowrap',
          color: '#000',
          justifyContent: 'flex-end'
        }}>
        <Tooltip title={`${tooltipOldData} ${moment(importDate).format('DD-MM-YYYY')}.`}>
          <Box sx={{ display: 'flex', alignItems: 'end', textDecoration: 'none' }}>
            {numberWithSpace(parsedValue)}
            <WarningAmberIcon sx={{ marginLeft: '0.5rem', color: '#EA9617' }} />
          </Box>
        </Tooltip>
      </Box>
    );
  } else {
    result = (
      <Box
        sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', whiteSpace: 'nowrap', textDecoration: 'none' }}>
        {numberWithSpace(parsedValue)}
      </Box>
    );
  }

  return result;
};

export default SaleDebtItem;
