import { logoBlack } from 'assets/images/logo';
import styles from './deactivatedUser.module.scss';
import { StyledButton } from 'components';
import { useAuth } from 'oidc-react';
import { logout } from 'lib/utils';
import { useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';

const DeactivatedUser = () => {
  const auth = useAuth();
  const connection = useSelector(connectionSelector);
  const logOut = () => logout(auth, connection);

  return (
    <div className={styles.box}>
      <img src={logoBlack} alt="Logo" className={styles.logo} />
      <div className={styles.textBox}>
        <div className={styles.text}>
          Uprzejmie informujemy, że nie masz dostępu do aplikacji RiskRadar lub konto firmy w RiskRadar zostało
          dezaktywowane bądź usunięte.
        </div>
      </div>
      <StyledButton sx={{ background: '#078A51' }} variant="contained" onClick={logOut}>
        Wróć do strony logowania
      </StyledButton>
    </div>
  );
};

export default DeactivatedUser;
