import { FC, ReactNode } from 'react';
import Spinner from '../Spinner';

type LoaderProps = {
  isLoading: boolean;
  children: ReactNode;
};

const Loader: FC<LoaderProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return <Spinner />;
  }

  return <>{children}</>;
};

export default Loader;
