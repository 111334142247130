import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { CompanyStateType } from './reducer';
import { RequestStatus } from '../Store.types';

const selectState = ({ company }: RootState) => company;

export const companySelector = createSelector(selectState, (state: CompanyStateType) => state.entity);

const mapStatus = (company: CompanyStateType): RequestStatus => {
  const { isPending, isNotAsked, isSuccess, isFailure } = company;
  return { isPending, isNotAsked, isSuccess, isFailure };
};

export const companyStatusSelector = createSelector(selectState, (company) => mapStatus(company));
