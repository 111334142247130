import { Box } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import { KrdEventProps } from "./KrdEvent.types"

const KrdEvent: FC<KrdEventProps> = ({ item, index }) => {
    const body =
        <Box sx={{ display: 'flex', width: '100%', maxWidth: '44.7rem', alignItems: 'center' }}>
          <Box sx={{ color: '#00000' }}>
            {item.name}
          </Box>
          <Box sx={{ margin: '0 0 0 1rem', marginLeft: 'auto' }}>
            { moment(item?.date).format('DD-MM-YYYY') }
          </Box>
        </Box>;

    return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '1rem',
          borderRadius: '3px',
          border: '1px solid #D7DDE2',
          margin: '0.5rem 0'
        }}>
          {body}
        </Box >)
}

export default KrdEvent;