export enum PATH {
  ROOT = '/',
  NOT_AUTHORIZED = '/not-authorized',
  ACCESS_DENIED = '/access-denied',
  SESSION_EXPIRED = '/sessionexpired',
  USERS_LIST = '/users',
  ADD_USER = '/user/add',
  EDIT_USER = '/user/edit',
  USER_PROFILE = '/user/profile',
  CUSTOMERS_LIST = '/customers',
  ADD_CUSTOMER = '/customer/add',
  CUSTOMER_PROFILE = '/customer/profile',
  EDIT_CUSTOMER = '/customer/edit',
  CUSTOMER_TAX_IDENTIFIER = '/customer/tax-identifier',
  SWAGGER_AUTHORIZE = '/swaggerauthorize',
  TUTORIAL = '/tutorial',
  CSV_LIST = '/imports',
  CSV_ERRORS = '/imports/errors',
  CSV_PREVIEW = '/imports/preview',
  REPORTS_LIST = '/reports',
  PERIODIC_REPORTS_HISTORY = '/periodic-reports-history',
  NEW_REPORT = '/new-report',
  EDIT_REPORT = '/report/edit',
  SHOW_REPORT = '/reports/report',
  SHOW_PERIODIC_REPORT = '/reports/periodicReport',
  DATA_EXPORT = '/data-export',
  DEACTIVATED_USER = '/deactivated-user',
  HISTORY_CHANGES = '/history-changes',
  REPORTS_HISTORY = '/reports-history',
  HANGFIRE_AUTHORIZE = '/hangfireauthorize'
}
