import { PeriodicReportHistoryItemDto } from 'api/models/PeriodicReportHistoryItemDto';

export enum FIELDS {
  ID = 'id',
  REPORT_NAME = 'name',
  GENERATED_AT = 'generatedAt',
  REPORT_OWNER = 'firstName',
  FREQUENCY = 'frequency',
  FILENAME = 'fileName'
}

export type GetPeriodicReportHistoryQueryResult = {
  results: PeriodicReportHistoryItemDto[];
  totalSize: number;
};
