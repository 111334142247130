import { FC } from 'react';

interface ReportDownloadIconProps {
  color: string;
  style?: { fill: string };
  arrowColor?: string;
}

const ReportDownloadIcon: FC<ReportDownloadIconProps> = ({ color, style, arrowColor }) => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M3.32459 19.6072H12.6748C14.4162 19.6072 15.8322 18.1911 15.8322 16.4505V3.54725C15.8322 1.80669 14.4162 0.390625 12.6748 0.390625H6.62744C5.69454 0.390625 4.81944 0.735669 4.10183 1.43254L1.20167 4.33269C0.492546 5.06122 0.167969 6.00852 0.167969 6.85009V16.4505C0.167969 18.1911 1.58403 19.6072 3.32459 19.6072ZM14.2309 3.54725V16.4505C14.2309 17.3079 13.5326 18.0058 12.6748 18.0058H3.32459C2.46682 18.0058 1.76935 17.3079 1.76935 16.4505V6.85009C1.76935 6.84296 1.77091 6.83607 1.77111 6.82898H4.72658C5.79938 6.82898 6.67201 5.95948 6.67201 4.89059V1.992H12.6748C13.5326 1.992 14.2309 2.68987 14.2309 3.54725ZM2.57082 5.2276L5.07063 2.72853V4.89059C5.07063 5.07943 4.91972 5.2276 4.72658 5.2276H2.57082Z"
        fill={color}
      />
      <path
        d="M7.19809 13.2916L6.2418 12.3351C5.92903 12.0224 5.42235 12.0224 5.10958 12.3351C4.79681 12.6475 4.79681 13.1546 5.10958 13.4674L7.43189 15.7902C7.73967 16.0982 8.24801 16.108 8.56568 15.7902L10.8888 13.4674C11.2015 13.155 11.2015 12.6479 10.8888 12.3351C10.576 12.0224 10.0693 12.0224 9.75655 12.3351L8.79947 13.292V9.76065C8.79947 9.31847 8.44135 8.95996 7.99878 8.95996C7.55622 8.95996 7.19809 9.31847 7.19809 9.76065V13.2916Z"
        fill={arrowColor ? arrowColor : '#078A51'}
      />
    </svg>
  );
};

export default ReportDownloadIcon;
