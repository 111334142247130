import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { FIELDS } from '../csvList.types';
import { TableHeadProps } from 'types';

const TableHead: FC<TableHeadProps<FIELDS>> = ({ order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property: FIELDS) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: 'relative',
              padding: '1rem .5rem 1rem 1.5rem',
              whiteSpace: 'nowrap'
            }}>
            <TableSortLabel
              active={orderBy === headCell.id && headCell.sortable}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
              hideSortIcon={!headCell.sortable}
              id={`csv-list-table-head-${headCell.id}`}
              sx={{
                pointerEvents: headCell.sortable ? 'all' : 'none'
              }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
