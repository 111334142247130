import { FC } from 'react';
import { StatusProps } from './Status.types';
import { STATUS } from '../dataExportList.types';
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import classes from './Status.module.scss';

const Status: FC<StatusProps> = ({ status }) => {
  if (status === STATUS.COMPLETED) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.iconWrapper}>
          <DoneIcon color="success" fontSize="small" />
        </div>
        Zakończony
      </div>
    );
  } else if (status === STATUS.IN_PROGRESS) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.iconWrapper}>
          <MoreHorizIcon sx={{ color: '#ec9514' }} fontSize="small" />
        </div>
        W toku
      </div>
    );
  } else {
    return null;
  }
};

export default Status;
