import { useLocation, matchPath } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListIcon from '@mui/icons-material/List';
import Paper from '../Paper';
import { Link } from './SideNav.styled';
import { navItems } from './config';
import { Props } from './SideNav.types';
import { PATH } from 'router/routes';
import { FC, memo } from 'react';
import { FIELDS } from 'domains/user/shared/types/userForm.types';

const SideNav: FC<Props> = memo(({ flexGrow = false }) => {
  const location = useLocation();
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const hasReportingPermission = auth?.userData?.profile?.HasClientReportPermission;

  return (
    <Paper margin="0 0 0.75rem 0.75rem" flexGrow={flexGrow}>
      <Box sx={{ borderBottom: '1px solid #E6ECF5' }}>
        <a href="/">
          <Button
            variant="text"
            sx={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0 0.75rem',
              height: '72px'
            }}>
            <ListIcon fontSize="large" sx={{ color: '#078A51' }} />
            <Typography
              variant="body1"
              sx={{
                marginLeft: '0.5rem',
                textTransform: 'uppercase',
                fontWeight: '500',
                color: '#078A51'
              }}>
              Menu
            </Typography>
          </Button>
        </a>
      </Box>
      <List>
        {navItems
          .filter((item) => item?.roles?.includes(userRole) || item?.roles === undefined)
          .filter(
            (item) =>
              (item?.hideFor === FIELDS.HAS_REPORT_PERMISSION && hasReportingPermission !== 'False') ||
              item?.hideFor === undefined
          )
          .map((item) => {
            const match = !!matchPath(location.pathname, item.path);
            const isReportsHistory = item?.path === PATH.REPORTS_HISTORY;

            return (
              <ListItem
                key={item.label}
                onClick={item.action}
                sx={{
                  padding: item.separator ? '8px 4px 2px 4px' : '0 4px',
                  cursor: 'copy',
                  borderTop: item.separator ? '1px solid #E0E0E0' : ''
                }}>
                <Link
                  to={item.path}
                  className={item.disableActiveState ? 'disableActiveState' : ''}
                  target={isReportsHistory && item?.action ? '_blank' : '_self'}>
                  {isReportsHistory ? (
                    <Box sx={{ width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <item.Icon
                        arrowColor={match ? '#078A51' : '#000'}
                        style={{ fill: match && !item.disableActiveState ? '#078A51' : '#000', marginLeft: '4px' }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ width: '20px' }}>
                      <item.Icon sx={{ color: match ? '#078A51' : '#000' }} />
                    </Box>
                  )}
                  <Typography
                    variant="body1"
                    sx={{
                      marginLeft: '1rem',
                      transition: 'all 0.2s ease-in-out',
                      cursor: match ? 'default' : 'pointer',
                      fontWeight: item.bold ? '600' : ''
                    }}>
                    {item.label}
                  </Typography>
                </Link>
              </ListItem>
            );
          })}
      </List>
    </Paper>
  );
});

export default SideNav;
