import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit';
import { isPendingAction, isFulfilledAction, isRejectedAction } from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';

export type ReportCounterStateResponse = {
  count: number;
  fromLinkCount: number;
};

export interface ReportCounterState extends RequestStatus {
  value: ReportCounterStateResponse;
};

const initialState: ReportCounterState = {
  ...statusNotAsked,
  value: {
    count: 0,
    fromLinkCount: 0
  }
};

export enum reportCounterActions {
  increment = 'reportCounter/increment',
};

const increment = createAction(reportCounterActions.increment)

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(increment, (state, action) => {
      state.value.count++
    })
    .addMatcher(isPendingAction, (state: ReportCounterState) => ({
      ...state,
      ...statusIsLoading
    }))
    .addMatcher(isFulfilledAction, (state: ReportCounterState, action: PayloadAction<ReportCounterStateResponse>) => ({
      ...state,
      ...statusIsSuccess,
      value: action.payload
    }))
    .addMatcher(isRejectedAction, (state: ReportCounterState) => ({
      ...state,
      ...statusIsFailure
    }));
});

export type ReportCounterStateType = ReturnType<typeof reducer>;
