import { useState, useEffect, useMemo, useCallback } from 'react';
import tableClasses from '../../../styles/table.module.scss';
import { Breadcrumbs, Paper, Header, Scrollbar, Pagination } from 'components';
import { CircularProgress, TableBody, TableCell, TableContainer, TableRow, SelectChangeEvent } from '@mui/material';
import Table from '@mui/material/Table';
import SideNav from 'components/SideNav';
import classes from './DataExportList.module.scss';
import sideNavClasses from '../../../App.module.scss';
import { TableHead, Status, Download } from './components';
import { FIELDS, DataExportResults, exportType } from './dataExportList.types';
import { HeadCell } from 'types';
import { useSendRequest, useRowsPerPage, useGoToFirstPage, useFormikFilters, useDidUpdateEffect } from 'lib/hooks';
import { getExportData as getExportDataService } from 'api/services';
import { createRows, parseType } from './utils';
import moment from 'moment';
import { PATH } from 'router/routes';
import { useAuth } from 'oidc-react';
import { ROLE } from 'config/oidc';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FiltersTop } from 'domains/customer/shared';
import { MultiCheckbox } from 'components';
import { useFormik } from 'formik';
import { initialValues } from './config';
import { isEqual } from 'lodash';
import { connectionSelector } from 'store/connection';
import { PushNotification, NotificationType } from 'App';
import { useSelector } from 'react-redux';

const headerTooltip =
  'Informacje o wykonanych operacjach eksportu danych (w tym pliki z danymi) są dostępne przez 7 dni. Po tym czasie są trwale usuwane z RiskRadar.';

const DataExportList = () => {
  const [sessionFilters, setSessionFilters] = useFormikFilters('dataExportFilterValues');
  const [isDefaultValues, setIsDefaultValues] = useState<boolean>(true);
  const rowsPerPage = useRowsPerPage();
  const { goToFirstPage } = useGoToFirstPage();
  const breadcrumbs = [{ label: 'Historia eksportu danych' }];
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const [searchParams] = useSearchParams();
  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;
  const pageNumber = searchParams.get('page');

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    onSubmit: () => {}
  });

  const { types } = formik.values;

  useEffect(() => {
    sessionFilters && formik.setValues({ ...JSON.parse(sessionFilters) });
  }, []);

  useDidUpdateEffect(() => {
    const values = JSON.stringify(formik.values);

    if (values !== JSON.stringify(sessionFilters)) {
      setSessionFilters(values);
    }

    const formikValues = {
      ...formik.values,
      [FIELDS.TYPES]: formik.values.types.split(',').sort().join(',')
    };

    setIsDefaultValues(isEqual(initialValues, formikValues));
  }, [formik.values]);

  const createBaseQueryString = useCallback(() => {
    const splitTypes = [...types.split(',')];
    const exportTypes = splitTypes.join('&types=');
    const typesParam = exportTypes ? `&types=${exportTypes}` : '';

    return `CompanyId=${companyId}&PageNumber=${pageNumber}&PageSize=${pageSize}&OrderByColumn=generatedAt&OrderByAscending=false${typesParam}`;
  }, [companyId, pageNumber, pageSize, types]);

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const { result: dataExportResults, isLoading: isLoadingExportData, sendData } = useSendRequest();

  const getExportData = useCallback(
    async (query: string) => {
      sendData(() => getExportDataService(query));
    },
    [sendData]
  );

  const prepareQuery = useCallback(() => {
    const queryString = createBaseQueryString();
    setQuery(queryString);
  }, [createBaseQueryString]);

  useEffect(() => {
    prepareQuery();
  }, [pageNumber, pageSize, prepareQuery, types]);

  useEffect(() => {
    if (pageNumber) {
      getExportData(query);
    }
  }, [getExportData, pageNumber, query]);

  const connection = useSelector(connectionSelector);

  useEffect(() => {
    connection?.on('SendNotification', (data: PushNotification) => {
      if (
        (data?.type === NotificationType.UserChangeHistoryExportResultUpdated ||
          data?.type === NotificationType.UserCustomerListExportResultUpdated) &&
        window.location.pathname === PATH.DATA_EXPORT
      ) {
        prepareQuery();
        getExportData(query);
      }
    });
  }, [connection, getExportData, prepareQuery, query]);

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    goToFirstPage(PATH.DATA_EXPORT);
  };

  const multiCheckboxHandler = (event: any, field: FIELDS.TYPES) => {
    const { value, checked } = event.target;
    const currentSelection = formik.values[field] ? formik.values[field].split(',') : [];

    if (checked && value) {
      currentSelection.push(value);
      formik.setFieldValue(field, currentSelection.join());
    } else {
      const newSelection = currentSelection.filter((field) => field !== value);
      formik.setFieldValue(field, newSelection.join());
    }
  };

  const rows = createRows((dataExportResults as DataExportResults)?.results);

  const headCells: HeadCell<FIELDS>[] = [
    {
      id: FIELDS.TYPE,
      numeric: false,
      disablePadding: true,
      label: 'Rodzaj eksportu',
      sortable: false
    },
    {
      id: FIELDS.GENERATED_AT,
      numeric: false,
      disablePadding: true,
      label: 'Data wygenerowania',
      sortable: false
    },
    {
      id: FIELDS.REQUESTED_BY_USER,
      numeric: false,
      disablePadding: true,
      label: 'Użytkownik',
      sortable: false
    },
    {
      id: FIELDS.STATUS,
      numeric: false,
      disablePadding: true,
      label: 'Status',
      sortable: false
    },
    {
      id: FIELDS.ID,
      numeric: false,
      disablePadding: true,
      label: 'Pobierz',
      sortable: false
    }
  ];

  const typesValue = useMemo(() => formik.values[FIELDS.TYPES], [formik.values[FIELDS.TYPES]]);

  const tableCellStyles = { padding: '1.5rem', whiteSpace: 'nowrap' };

  const clearFilters = () => {
    formik.setValues(initialValues);
    setSessionFilters(JSON.stringify(initialValues));

    searchParams.set('page', '1');
    navigate({ pathname: PATH.DATA_EXPORT, search: `${searchParams}` });
  };

  return (
    <div className={classes.wrapper}>
      <div className={sideNavClasses.sidebar}>
        <SideNav />
        <Paper margin="0 0 0.75rem 0.75rem">
          <FiltersTop disabled={isDefaultValues} clearFilters={() => clearFilters()} />
          <MultiCheckbox
            fullWidth={true}
            multiCheckboxHandler={multiCheckboxHandler}
            title="Rodzaj eksportu"
            name={FIELDS.TYPES}
            value={typesValue}
            checkboxesData={exportType}
          />
        </Paper>
      </div>
      <div className={classes.innerWrapper}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div>
            <Header
              title={<span className={tableClasses.header}>Historia eksportu danych</span>}
              tooltipText={headerTooltip}
              justifyContent="space-between"
            />
          </div>
          {isLoadingExportData ? (
            <div className={classes.spinnerWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead headCells={headCells} />
                    <TableBody>
                      {rows.map((row: any) => {
                        return (
                          <TableRow key={row.id} hover tabIndex={-1}>
                            <TableCell sx={tableCellStyles}>{parseType(row.type)}</TableCell>
                            <TableCell sx={tableCellStyles}>
                              {row?.generatedAt ? moment(row.generatedAt).format('DD-MM-YYYY HH:mm:ss') : ''}
                            </TableCell>
                            <TableCell sx={tableCellStyles}>{row.requestedByUser}</TableCell>
                            <TableCell sx={tableCellStyles}>
                              <Status status={row.status} />
                            </TableCell>
                            <TableCell sx={tableCellStyles}>
                              <Download status={row.status} download={row.id} filename={row?.filename} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                extraData={`Liczba rekordów: ${(dataExportResults as DataExportResults)?.totalSize}`}
                total={(dataExportResults as DataExportResults)?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default DataExportList;
