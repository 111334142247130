import { FC, useState } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Props } from './Snackbar.types';

const autoHideDuration = 10000;

const Snackbar: FC<Props> = ({ message, additionalInfo = [], type, closeHandler }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(true);

  const closeSnackBar = () => {
    if (closeHandler instanceof Function) {
      closeHandler();
    }

    setIsSnackbarOpen(false);
  };

  return (
    <MuiSnackbar
      open={isSnackbarOpen}
      autoHideDuration={autoHideDuration}
      onClose={closeSnackBar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ maxWidth: '40rem' }}>
      <Alert onClose={closeSnackBar} severity={type} variant="filled">
        <Typography variant="body1" sx={{ color: 'inherit' }}>
          {message}
        </Typography>
        {additionalInfo.map((info) => (
          <Typography variant="body1" sx={{ color: 'inherit' }}>
            {info}
          </Typography>
        ))}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
