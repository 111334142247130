import { FC, useState, KeyboardEvent } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MuiTooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { Props } from './SearchField.types';
import { StyledTextField } from 'components';

const tooltipText = 'Wprowadź minimum 3 znaki';

const SearchField: FC<Props> = ({
  name,
  value,
  onChange,
  onKeyDown,
  onClearValue,
  hasError,
  helperText,
  onClick,
  marginLeft,
  minWidth,
  containerWidth
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (value?.trim()?.length >= 3) {
      setIsOpen(false);
      onClick();
    } else {
      setIsOpen(true);
    }
  };

  const clearValue = () => {
    onClearValue();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
      if (!value || value?.trim()?.length >= 3) {
        setIsOpen(false);
        onKeyDown();
      } else {
        setIsOpen(true);
      }
    }
  };

  return (
    <td style={{ padding: '0.5rem 0', width: containerWidth ? containerWidth : 'auto' }}>
      <MuiTooltip
        title={tooltipText}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow>
        <div style={{ width: '100%' }}>
          <StyledTextField
            name={name}
            label="Szukaj"
            variant="outlined"
            value={value}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            error={hasError}
            helperText={helperText}
            sx={{
              minWidth: minWidth ? minWidth : '',
              marginLeft: marginLeft || '1.6rem',
              marginRight: '',
              background: '#fff',
              '& .MuiInputLabel-root': {
                fontSize: '14px'
              },
              '& .MuiInputBase-root': {
                flexGrow: '1',
                paddingRight: '10px',
                fontSize: '14px'
              }
            }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {value && (
                    <CloseIcon
                      onClick={clearValue}
                      color="disabled"
                      sx={{
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  )}
                  <SearchIcon
                    onClick={handleClick}
                    color="disabled"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
      </MuiTooltip>
    </td>
  );
};

export default SearchField;
