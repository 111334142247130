import { memo, FC } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import classes from './TableHead.module.scss';
import { HISTORY_FIELDS, FIELDS, datesRadioButtons } from '../historyChangesList.types';
import { TableHeadProps, HeadCell } from './TableHead.types';
import moment from 'moment';
import { useFormikFilters } from 'lib/hooks';

const TableHead: FC<TableHeadProps> = memo(({ headCells, onRequestSort, orderBy, order, isSummaryView, formik }) => {
  const createSortHandler = (property: HISTORY_FIELDS | FIELDS) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const [sessionFilters, setSessionFilters] = useFormikFilters('historyFilterValues');

  const handleChangeFilter = (headCell: HeadCell) => {
    if (isSummaryView) {
      const isCurrentWeek = headCell.id === 'current_week';
      const weekId = isCurrentWeek ? 1 : parseInt(headCell.id?.split('week')[1]);
      const foundWeek = isCurrentWeek ? datesRadioButtons[1] : datesRadioButtons?.[weekId + 1];

      if (foundWeek) {
        const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
        const filters = {
          ...parsedFilters,
          [HISTORY_FIELDS.DATES]: foundWeek.label,
          [HISTORY_FIELDS.SELECTED_DATE]: foundWeek.value
        };
        setSessionFilters(JSON.stringify(filters));
        formik.setFieldValue(HISTORY_FIELDS.DATES, foundWeek.label);
        formik.setFieldValue(HISTORY_FIELDS.SELECTED_DATE, foundWeek.value);
      }
    }
  };

  return (
    <MuiTableHead>
      <TableRow>
        {headCells?.map((headCell: HeadCell, id: number) => (
          <TableCell
            key={headCell.id}
            align={headCells.length === id + 1 ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            onClick={() => handleChangeFilter(headCell)}
            sx={{
              position: 'relative',
              padding: '10px .5rem 10px 1.5rem',
              whiteSpace: 'nowrap',
              width: id > 2 ? '8%' : '12%',
              paddingRight: headCells.length === id + 1 ? '1.5rem' : '.5rem',
              borderLeft:
                id === 3
                  ? '1px solid rgba(224, 224, 224, 1)'
                  : id === (isSummaryView ? 11 : 10)
                  ? '1px solid rgba(224, 224, 224, 1)'
                  : 'none',
              cursor: isSummaryView ? 'pointer' : 'auto'
            }}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              hideSortIcon={!headCell.sortable}
              onClick={headCell.sortable && headCell.id ? createSortHandler(headCell.id) : undefined}
              sx={{
                pointerEvents: headCell.sortable ? 'all' : 'none',
                display: 'flex',
                flexDirection: id > 2 ? 'column' : 'row'
              }}>
              <div
                data-test-id={`history-changes-table-head-label-top-${headCell.id}`}
                className={
                  isSummaryView ? undefined : headCell.id === FIELDS.INFORMATION_TYPE ? undefined : classes.labelTop
                }>
                {headCell.labelTop}
              </div>
              <div data-test-id={`history-changes-table-head-label-bottom-${headCell.id}`}>
                {headCell?.labelBottom
                  ? isSummaryView
                    ? headCell.labelBottom
                    : moment(headCell.labelBottom).format('DD-MM-YYYY')
                  : null}
              </div>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
});

export default TableHead;
