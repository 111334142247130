import { ROLE_MAP } from 'config/oidc';
import { UserFormTypes } from '../../shared';

export const initialValues = {
  [UserFormTypes.FIELDS.FIRST_NAME]: '',
  [UserFormTypes.FIELDS.LAST_NAME]: '',
  [UserFormTypes.FIELDS.EMAIL]: '',
  [UserFormTypes.FIELDS.PHONE]: '',
  [UserFormTypes.FIELDS.COMPANY_ID]: '',
  [UserFormTypes.FIELDS.ROLE]: ROLE_MAP.ClientUser,
  [UserFormTypes.FIELDS.HAS_MONITORING_PERMISSION]: true,
  [UserFormTypes.FIELDS.HAS_REPORT_PERMISSION]: true,
  [UserFormTypes.FIELDS.HAS_CSV_PERMISSION]: true,
  [UserFormTypes.FIELDS.HAS_ROLE_CHANGING_PERMISSIONS]: true
};
