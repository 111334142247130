import axiosInstance from 'api/axiosInstance';
import { baseAPIUrl } from 'api/config';
import { ApiResponse } from 'api/models';
import { PeriodicReportHistoryItemDto } from 'api/models/PeriodicReportHistoryItemDto';
import { AxiosPromise } from 'axios';

export const getPeriodicReportsHistory = (query: string): AxiosPromise<ApiResponse<PeriodicReportHistoryItemDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/PeriodicReportsHistory?${query}`,
    method: 'GET'
  });
};
