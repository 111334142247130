import { FC, useState, useCallback, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from 'components';
import { useSendRequest, useToggle } from 'lib/hooks';
import { PATH } from 'router/routes';
import { removeUser } from 'api/services';
import { Props } from './ListMenu.types';

const ListMenu: FC<Props> = ({ id, fetchUsers, isRemoveDisabled, isEditDisabled, dataTest, rowIndex }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, toggleModal] = useToggle();

  const { isLoading, sendData } = useSendRequest();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitCallback = useCallback(() => {
    toggleModal();
    fetchUsers();
  }, [fetchUsers, toggleModal]);

  const deleteHandler = useCallback(() => removeUser(id), [id]);

  const deleteUser = () => sendData(deleteHandler, submitCallback, toggleModal);

  const editMenuItem = (
    <MenuItem disabled={isEditDisabled}>
      <EditIcon fontSize="small" />
      <Typography variant="body1" sx={{ marginLeft: '0.75rem' }}>
        Modyfikuj
      </Typography>
    </MenuItem>
  );

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{
          ml: 2,
          background: anchorEl ? 'rgba(19, 103, 229, 0.09)' : 'transparent',
          padding: '0.5rem'
        }}
        id={`${dataTest}-menu-button-${id + 1}`}
        aria-controls={anchorEl ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}>
        <SettingsIcon color={anchorEl ? 'primary' : 'inherit'} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {isEditDisabled ? (
          editMenuItem
        ) : (
          <Link
            to={`${PATH.EDIT_USER}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            id={`${dataTest}-edit-button-${rowIndex + 1}`}>
            {editMenuItem}
          </Link>
        )}
        <Divider />
        <MenuItem onClick={toggleModal} disabled={isRemoveDisabled} id={`${dataTest}-remove-button-${rowIndex + 1}`}>
          <CloseIcon fontSize="small" />
          <Typography variant="body1" sx={{ marginLeft: '0.75rem' }}>
            Usuń
          </Typography>
        </MenuItem>
      </Menu>

      <Dialog
        isOpen={isModalOpen}
        isPending={isLoading}
        closeModal={toggleModal}
        accept={deleteUser}
        acceptLabel="Tak"
        closeLabel="Nie">
        Czy chcesz trwale usunąć konto użytkownika z Aplikacji RiskRadar?
      </Dialog>
    </>
  );
};

export default ListMenu;
