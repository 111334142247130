const useFormikFilters = (localeStorageName: string): [string, (value: string) => void] => {
  const sessionFilters = localStorage.getItem(localeStorageName) || '';
  const setSessionFilters = (value: string) => {
    localStorage.setItem(localeStorageName, value);
  };

  return [sessionFilters, setSessionFilters];
};

export default useFormikFilters;
