import { useAuth } from 'oidc-react';
import { Dialog } from 'components';
import { logout } from 'lib/utils';
import { useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';

const SessionExpired = () => {
  const auth = useAuth();
  const connection = useSelector(connectionSelector);

  const logIn = () => logout(auth, connection);

  return (
    <>
      <Dialog
        title="Sesja wygasła"
        isOpen={true}
        closeModal={logIn}
        closeLabel="Przejdź do ekranu logowania"
        disableBackdropClick
        disableEscapeKeyDown>
        Twoja sesja wygasła. Zaloguj się ponownie
      </Dialog>
    </>
  );
};

export default SessionExpired;
