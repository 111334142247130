import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import classes from './ReportsHistoryList.module.scss';
import sideNavClasses from '../../../App.module.scss';
import SideNav from 'components/SideNav';
import { Breadcrumbs, Paper, Header, Pagination, Scrollbar, SearchField, NewReportDialog } from 'components';
import { CircularProgress, TableBody, TableContainer, SelectChangeEvent } from '@mui/material';
import { useSendRequest, useRowsPerPage, useGoToFirstPage, useFormikFilters } from 'lib/hooks';
import { useSearchParams } from 'react-router-dom';
import { PATH } from 'router/routes';
import { FIELDS, HeadCell, DataExportResults } from './reportsHistoryList.types';
import Table from '@mui/material/Table';
import tableClasses from '../../../styles/table.module.scss';
import { mapSortDirection, Order } from 'types';
import { mapOrderBy, createRows, createSearchQuery } from './utils';
import { TableHead, Rows } from './components';
import { useFormik } from 'formik';
import { initialValues } from './config';
import { getReportsHistoryList } from 'api/services';
import { useAuth } from 'oidc-react';
import { ROLE } from 'config/oidc';
import { PushNotification, NotificationType } from '../../../App';
import { STATUS } from './reportsHistoryList.types';
import { ReportHistoryItemDto } from 'api/models';
import { useSelector } from 'react-redux';
import { connectionSelector } from 'store/connection';

const breadcrumbs = [{ label: 'Historia pobranych raportów' }];

const ReportsHistoryList = () => {
  const showNewReportModal = localStorage.getItem('showNewReportModal');
  const rowsPerPage = useRowsPerPage();
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [isNewReportModalOpen, setIsNewReportModalOpen] = useState(showNewReportModal ? true : false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { goToFirstPage } = useGoToFirstPage();
  const didMount = useRef(false);

  const savedOrder = localStorage.getItem('reportsHistoryOrder');
  const savedOrderBy = localStorage.getItem('reportsHistoryOrderBy');
  const [order, setOrder] = useState<Order>((savedOrder as Order) ?? 'desc');
  const [orderBy, setOrderBy] = useState<FIELDS>((savedOrderBy as FIELDS) ?? FIELDS.DOWNLOADED_AT);
  const pageNumber = searchParams.get('page');
  const [sessionFilters, setSessionFilters] = useFormikFilters('reportsHistoryFilterValues');
  const [handleClearValueCounter, setHandleClearValueCounter] = useState('');
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');

  const connection = useSelector(connectionSelector);

  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  const createBaseQueryString = () => {
    return `CompanyId=${companyId}&PageNumber=${pageNumber ?? 1}&PageSize=${pageSize}&OrderByColumn=${mapOrderBy(
      orderBy
    )}&OrderByAscending=${mapSortDirection(order)}`;
  };

  const [query, setQuery] = useState<string>(createBaseQueryString());

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    onSubmit: () => {}
  });

  const handleRequestSort = useCallback(
    (_, property: FIELDS) => {
      const isAsc = orderBy === property && order === 'asc';
      const orderValue = isAsc ? 'desc' : 'asc';
      setOrder(orderValue);
      setOrderBy(property);
      localStorage.setItem('reportsHistoryOrder', orderValue);
      localStorage.setItem('reportsHistoryOrderBy', property);
    },
    [orderBy, order]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: SelectChangeEvent<number>) => {
      setPageSize(Number(event.target.value));
      goToFirstPage(PATH.REPORTS_HISTORY, searchParams);
    },
    [searchParams]
  );

  const headCells: HeadCell<FIELDS>[] = [
    {
      id: FIELDS.CLIENT_NAME,
      numeric: false,
      label: 'Klient',
      sortable: true
    },
    {
      id: FIELDS.TAX_ID,
      numeric: false,
      label: 'NIP',
      sortable: false
    },
    {
      id: FIELDS.USER_NAME,
      numeric: false,
      label: 'Użytkownik',
      sortable: true
    },
    {
      id: FIELDS.DOWNLOADED_AT,
      numeric: false,
      label: 'Data pobrania',
      sortable: true
    },
    {
      id: FIELDS.DOWNLOAD,
      numeric: false,
      label: 'Otwórz',
      sortable: false
    }
  ];

  const handleSearch = (fieldName: FIELDS) => {
    const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
    const filters = { ...parsedFilters, [fieldName]: (formik.values as any)[fieldName] };
    setSessionFilters(JSON.stringify(filters));

    if (pageNumber === '1') {
      prepareQuery(fieldName);
    } else {
      goToFirstPage(PATH.REPORTS_HISTORY);
    }
  };

  useEffect(() => {
    sessionFilters && formik.setValues({ ...JSON.parse(sessionFilters) });
  }, []);

  const closeNewReportModal = () => {
    setIsNewReportModalOpen(false);
  };

  const handleEnterPress = (fieldName: FIELDS) => handleSearch(fieldName);

  const handleClearValue = (field: FIELDS) => {
    const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
    const sessionFiltersWithSearch = { ...parsedSessionFilters, [field]: '' };
    setSessionFilters(JSON.stringify(sessionFiltersWithSearch));
    setHandleClearValueCounter(handleClearValueCounter + 1);
    goToFirstPage(PATH.REPORTS_HISTORY);

    formik.setFieldValue(field, '');
    if (searchParams.has(field)) {
      searchParams.delete(field);
      setSearchParams(searchParams);
      setHandleClearValueCounter(field);
      goToFirstPage(PATH.REPORTS_HISTORY, searchParams);
    }
  };

  useEffect(() => {
    if (handleClearValueCounter) {
      prepareQuery();
    }
  }, [handleClearValueCounter]);

  const { result: reportsHistoryResults, isLoading: isLoadingReportsHistory, sendData } = useSendRequest();
  const [savedReportsHistoryResults, setSavedReportsHistoryResults] = useState<ReportHistoryItemDto[]>(
    (reportsHistoryResults as DataExportResults)?.results
  );

  useEffect(() => {
    setSavedReportsHistoryResults((reportsHistoryResults as DataExportResults)?.results);
  }, [(reportsHistoryResults as DataExportResults)?.results]);

  const getReportsData = async (query: string) => {
    sendData(() => getReportsHistoryList(query));
  };

  useEffect(() => {
    connection?.on('SendNotification', (message: PushNotification) => {
      if (message?.type === NotificationType.CompanyReportGenerated) {
        getReportsData(query);
      }
    });
  }, [query]);

  useEffect(() => {
    if (didMount.current) {
      getReportsData(query);
    } else {
      const parsedFilters = sessionFilters && JSON.parse(sessionFilters);
      const name = parsedFilters?.[FIELDS.CLIENT_NAME];
      const taxId = parsedFilters?.[FIELDS.TAX_ID];

      if (!name && !taxId) {
        getReportsData(query);
      }

      didMount.current = true;
    }
  }, [query]);

  const prepareQuery = (searchInputName?: string) => {
    let queryString = createBaseQueryString();

    const searchQuery = createSearchQuery(formik.values, sessionFilters, searchInputName);

    setSearchParams(searchParams);

    if (searchQuery) {
      queryString = queryString + `&Filters=${searchQuery}`;
    }

    setQuery(queryString);
  };

  useEffect(() => {
    prepareQuery();
  }, [pageNumber, pageSize, order, orderBy]);

  const rows = useMemo(() => createRows(savedReportsHistoryResults), [savedReportsHistoryResults]);

  return (
    <div className={classes.wrapper}>
      <div className={sideNavClasses.sidebar}>
        <SideNav />
      </div>
      <div className={classes.innerWrapper}>
        <Paper overflow fullSize>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {isLoadingReportsHistory ? (
            <div className={classes.spinnerWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Header
                title={
                  <span id="reports-history-page-title" className={classes.header}>
                    Historia pobranych raportów z ostatnich 7 dni
                  </span>
                }
                justifyContent="space-between"
                hidePagination={false}
                onRowsPerPageChange={handleChangeRowsPerPage}
                paginationPageSize={pageSize}
                paginationTotalSize={(reportsHistoryResults as DataExportResults)?.totalSize}></Header>
              <TableContainer>
                <Scrollbar>
                  <Table className={tableClasses.table} aria-labelledby="tableTitle" size="medium">
                    <TableHead
                      headCells={headCells}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      <tr className={classes.trHead}>
                        <SearchField
                          name={FIELDS.CLIENT_NAME}
                          value={formik.values[FIELDS.CLIENT_NAME]}
                          onChange={formik.handleChange}
                          onKeyDown={() => handleEnterPress(FIELDS.CLIENT_NAME)}
                          onClearValue={() => handleClearValue(FIELDS.CLIENT_NAME)}
                          hasError={formik.touched[FIELDS.CLIENT_NAME] && !!formik.errors[FIELDS.CLIENT_NAME]}
                          helperText={formik.touched[FIELDS.CLIENT_NAME] && formik.errors[FIELDS.CLIENT_NAME]}
                          onClick={() => handleSearch(FIELDS.CLIENT_NAME)}
                        />
                        <SearchField
                          name={FIELDS.TAX_ID}
                          value={formik.values[FIELDS.TAX_ID]}
                          onChange={formik.handleChange}
                          onKeyDown={() => handleEnterPress(FIELDS.TAX_ID)}
                          onClearValue={() => handleClearValue(FIELDS.TAX_ID)}
                          hasError={formik.touched[FIELDS.TAX_ID] && !!formik.errors[FIELDS.TAX_ID]}
                          helperText={formik.touched[FIELDS.TAX_ID] && formik.errors[FIELDS.TAX_ID]}
                          onClick={() => handleSearch(FIELDS.TAX_ID)}
                        />
                        <td />
                        <td />
                        <td />
                      </tr>
                      <Rows rows={rows} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <Pagination
                extraData={`Liczba rekordów: ${(reportsHistoryResults as DataExportResults)?.totalSize}`}
                total={(reportsHistoryResults as DataExportResults)?.totalSize || 1}
                pageSize={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </div>

      <NewReportDialog
        getReportsData={getReportsData}
        query={query}
        companyId={companyId}
        isOpen={isNewReportModalOpen}
        closeNewReportModal={closeNewReportModal}
      />
    </div>
  );
};

export default ReportsHistoryList;
