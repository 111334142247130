import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { monthlyUsage } from 'api/services';

export enum Actions {
  getMonthlyUsage = '[REPORTS] get report monthly usage'
};

export const getReportCounterThunk = createAsyncThunk(Actions.getMonthlyUsage, async (companyId: string) => {
  const { data } = await monthlyUsage(companyId);
  return data?.Result;
});

export const isPendingAction = isPending(getReportCounterThunk);
export const isFulfilledAction = isFulfilled(getReportCounterThunk);
export const isRejectedAction = isRejected(getReportCounterThunk);
