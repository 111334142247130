import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const Link = styled(NavLink)`
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  &.active {
    background: rgba(7, 138, 81, 0.1);
    cursor: default;

    & > p {
      color: #078a51;
      cursor: default;
    }
  }

  &.disableActiveState {
    background: none;
    cursor: pointer;

    & > p {
      color: #000;
      cursor: pointer;
    }
  }
`;

export const MenuIcon = styled.img`
  width: 2.25rem;
  height: 2.25rem;
`;
