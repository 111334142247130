export enum VALUE_LABEL {
  AUTO = 'auto'
}

export type Props = {
  title: string;
  name: string;
  value: number[];
  valueLabelDisplay: VALUE_LABEL;
  maxValue: number;
  getAriaValueText?: string;
  getAriaLabel?: () => void;
  onChange: (event: any) => void;
  onChangeCommitted: (event: any) => void;
  isDisabled?: boolean;
};
