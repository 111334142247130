import { FC } from 'react';
import { Props } from './Table.types';
import { UnstyledTable, RowName, RowValue, FlexBox } from './Table.styled';

const Table: FC<Props> = ({ label, rows, boldValues, style }) => {
  return (
    <UnstyledTable style={style} aria-label={label}>
      <tbody>
        {rows.map((row) => (
          <tr key={row.name}>
            <RowName>
              <FlexBox data-test-id={row.dataTest}>
                {row.name}
                {!!row.tooltip && row.tooltip}
              </FlexBox>
            </RowName>

            <RowValue boldValues={boldValues} data-test-id={`${row.dataTest}-value`}>
              {row.value}
            </RowValue>
          </tr>
        ))}
      </tbody>
    </UnstyledTable>
  );
};

export default Table;
