type props = {
  date: Date | string;
  textBefore?: string; 
  textAfter?: string;
  separator?: string;
};

export const dateGenerator = ({...data}: props): string | null => {
  const { date, textBefore, textAfter, separator } = data;
  const createDate = new Date(date);
  let result = createDate ? createDate?.toLocaleDateString("pl-PL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }) : null;

  if (separator) {
    result = result?.replace(/\./g, separator) ?? '';
  }

  if (textBefore) {
    result = `${textBefore}${result}`;
  }

  if (textAfter) {
    result = `${result}${textAfter}`;
  }

  return result;
};