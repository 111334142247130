import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CompanyDto } from 'api/models';
import { isPendingAction, isFulfilledAction, isRejectedAction } from './thunks';
import { RequestStatus, statusIsFailure, statusIsLoading, statusIsSuccess, statusNotAsked } from '../Store.types';

export interface CompanyState extends RequestStatus {
  entity: CompanyDto | null;
}

const initialState: CompanyState = {
  ...statusNotAsked,
  entity: null
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isPendingAction, (state: CompanyState) => ({
      ...state,
      ...statusIsLoading
    }))
    .addMatcher(isFulfilledAction, (state: CompanyState, action: PayloadAction<CompanyDto | null>) => ({
      ...state,
      ...statusIsSuccess,
      entity: action.payload
    }))
    .addMatcher(isRejectedAction, (state: CompanyState) => ({
      ...state,
      ...statusIsFailure
    }));
});

export type CompanyStateType = ReturnType<typeof reducer>;
