import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { resetSuccessMessage } from 'store/success';
import Snackbar from '../Snackbar';
import { Props } from './SuccessToast.types';

const SuccessToast: FC<Props> = ({ message }) => {
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(resetSuccessMessage());
  };

  return <Snackbar type="success" message={message} closeHandler={closeHandler} />;
};

export default SuccessToast;
