import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResponse } from 'api/models';
import { AxiosPromise } from 'axios';
import { setErrors, resetErrors } from 'store/errors';

const useFetch = <T,>(runRequest: () => AxiosPromise<ApiResponse<T>>, deps: any = [], shouldIgnore?: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<T | null>(null);

  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    let shouldCancel = false;

    setResult(null);
    dispatch(resetErrors());

    const sendRequest = async () => {
      if (shouldIgnore) return;

      try {
        setIsLoading(true);
        const res = await runRequest();
        if (!shouldCancel) {
          setResult(res.data.Result);
        }
      } catch (err: any) {
        if (!shouldCancel && Array.isArray(err?.data?.Errors)) {
          dispatch(
            setErrors({
              Errors: err.data?.Errors,
              ErrorId: err.data?.ErrorId
            })
          );
        }
      } finally {
        if (!shouldCancel) {
          setIsLoading(false);
        }
      }
    };

    sendRequest();

    return () => {
      shouldCancel = true;
    };
  }, [runRequest, shouldIgnore, dispatch]);

  useEffect(fetchData, [shouldIgnore, fetchData, ...deps]);

  return { isLoading, result, fetchData };
};

export default useFetch;
