import { createReducer } from '@reduxjs/toolkit';
import { setErrors, resetErrors } from './thunks';
import { ErrorsType } from 'types';

const initialState: ErrorsType = {
  ErrorId: '',
  Errors: []
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setErrors, (state, action) => {
      state.ErrorId = action.payload.ErrorId;
      state.Errors = action.payload.Errors;
    })
    .addCase(resetErrors, (state, action) => {
      state.ErrorId = '';
      state.Errors = [];
    });
});

export default reducer;

export type ReportStateType = ReturnType<typeof reducer>;
