import { FIELDS, ReportsHistoryFormikValues } from '../reportsHistoryList.types';
import { ReportHistoryItemDto } from 'api/models';

export const mapOrderBy = (orderBy: FIELDS) => {
  switch (orderBy) {
    case FIELDS.CLIENT_NAME:
      return FIELDS.CLIENT_NAME;
    case FIELDS.DOWNLOADED_AT:
      return FIELDS.DOWNLOADED_AT;
    case FIELDS.USER_NAME:
      return FIELDS.USER_NAME;
  }
};

export const createRows = (reportsHistoryData?: ReportHistoryItemDto[]) => {
  if (!reportsHistoryData) return [];

  return reportsHistoryData.map((result: ReportHistoryItemDto) => ({
    [FIELDS.CLIENT_NAME]: result.counterpartyName,
    [FIELDS.TAX_ID]: result.taxIdentificationNumber,
    [FIELDS.STATUS]: result.state,
    [FIELDS.DOWNLOAD]: result.reportRequestId,
    [FIELDS.DOWNLOADED_AT]: result.generatedAt,
    [FIELDS.USER_NAME]: result.requesterUserName
  }));
};

export const createSearchQuery = (
  values: ReportsHistoryFormikValues,
  sessionFilters: string,
  searchInputName?: string
) => {
  let searchArray: string[] = [];
  const parsedSessionFilters = JSON.parse(sessionFilters || '{}');
  const keys = Object.keys(parsedSessionFilters);

  keys.forEach((key: any) => {
    if (parsedSessionFilters[key as keyof typeof parsedSessionFilters]) {
      switch (key) {
        case FIELDS.CLIENT_NAME:
          searchArray.push(`${FIELDS.CLIENT_NAME};contains;${parsedSessionFilters[key]}`);
          break;
        case FIELDS.TAX_ID:
          searchArray.push(`${FIELDS.TAX_ID};contains;${parsedSessionFilters[key]}`);
          break;
      }
    }
  });

  if (searchInputName) {
    const searchValueExist = searchArray.some((el: string) => el.includes(`${searchInputName};contains`));
    if (searchValueExist) {
      searchArray = searchArray.filter((el: string) => !el.includes(`${searchInputName};contains`));
    }
    searchArray.push(`${searchInputName};contains;${values[searchInputName as keyof typeof values]}`);
  }

  return searchArray.join('|');
};
