import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';

export const createRowData = (name: string, value?: string, tooltip?: any, dataTest?: string) => {
  return { name, value: value || '', tooltip: tooltip, dataTest };
};

export const nameShortener = (name: string, length: number) => {
  if (name.length > length) return `${name.substring(0, length)}...`;
  return name;
};
