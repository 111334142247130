const ReportFrequency = (frequencyId: number) => {
  let result = '';

  switch (frequencyId) {
    case 1:
      result = 'co 1 dzień';
      break;
    case 7:
      result = 'co 1 tydzień (poniedziałek)';
      break;
    case 14:
      result = 'co 2 tygodnie (poniedziałek)';
      break;
    case 30:
      result = 'co miesiąc (1-szego dnia miesiąca)';
      break;
  }

  return result;
};

export default ReportFrequency;
