import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getCompany } from 'api/services';

export enum Actions {
  getCompanyDetails = '[DICT-COMPANY] get company details'
}

export const getCompanyThunk = createAsyncThunk(Actions.getCompanyDetails, async (id: string) => {
  const { data } = await getCompany(id);

  return data?.Result;
});

export const isPendingAction = isPending(getCompanyThunk);
export const isFulfilledAction = isFulfilled(getCompanyThunk);
export const isRejectedAction = isRejected(getCompanyThunk);
